import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {NgModule} from '@angular/core';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './components/app/app.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {LoginComponent} from './components/login/login.component';
import {RegisterComponent} from './components/register/register.component';
import {HeaderComponent} from './components/header/header.component';
import {FooterComponent} from './components/footer/footer.component';
import {TokenInterceptorService} from './token-interceptor.service';
import {DialogsModule} from '@progress/kendo-angular-dialog';
import { CoreModule } from './core/core.module';
import {
  MsalGuard, MsalBroadcastService, MsalService,
  MSAL_GUARD_CONFIG, MSAL_INSTANCE, MsalGuardConfiguration
} from '@azure/msal-angular';
import { IPublicClientApplication, PublicClientApplication, InteractionType } from '@azure/msal-browser';
import { msalConfig, loginRequest } from './auth-config';
import { StoreModule } from '@ngrx/store';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { JwtModule } from '@auth0/angular-jwt';
import { TokenComponent } from './utilities/token/token.component';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { environment } from '../environments/environment';
import { AuthGuard, MockAuthGuard } from './guards/auth/auth.guard';
import { AzureAuthService } from './modules/azure-auth/services/azure-auth/azure-auth.service';
import { AZURE_AUTH_CONFIG } from './modules/azure-auth/models/config';
import { MatIconModule } from '@angular/material/icon';
import { UniqueOperatorsPipe } from './pipes/uniqueOpertors';

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication(msalConfig);
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
      interactionType: InteractionType.Popup,
      authRequest: loginRequest,
      loginFailedRoute: '/login',
  };
}
@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    RegisterComponent,
    HeaderComponent,
    FooterComponent,
    TokenComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    AppRoutingModule,
    ReactiveFormsModule,
    HttpClientModule,
    DialogsModule,
    CoreModule,
    StoreModule.forRoot({}, {}),
    NgIdleKeepaliveModule.forRoot(),
    JwtModule.forRoot({
      config: {
        tokenGetter: () => {
          return localStorage.getItem('token');
        }
      }
    }),
    SpinnerComponent,
    MatIconModule
  ],
  providers: environment.mock ? [
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory
    },
    {
        provide: MSAL_GUARD_CONFIG,
        useFactory: MSALGuardConfigFactory
    },
    MsalService,
    MsalBroadcastService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptorService,
      multi: true
    },
    {provide: AuthGuard, useClass: MockAuthGuard},
    MsalGuard,
    AzureAuthService,
    {
      provide: AZURE_AUTH_CONFIG,
      useValue: { /* your azure auth config here */ }
    },
  ] : [
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory
    },
    {
        provide: MSAL_GUARD_CONFIG,
        useFactory: MSALGuardConfigFactory
    },
    MsalService,
    MsalBroadcastService,
      {
        provide: HTTP_INTERCEPTORS,
        useClass: TokenInterceptorService,
        multi: true
      },
    MsalGuard,
    AuthGuard,
    AzureAuthService,
    {
      provide: AZURE_AUTH_CONFIG,
      useValue: { /* your azure auth config here */ }
    },
  ],
  bootstrap: [AppComponent]
})

export class AppModule { }
