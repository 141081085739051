import { Injectable } from '@angular/core';
import { OperatorPaymentService } from './operator-payment.service';
import { HttpService } from '../httpService/http.service';
import { Observable } from 'rxjs';
import { MoreRecords, NewPaymentsSummary, Payment } from '../../../model/payment';
import { PaymentSummary, PaymentCount } from '../../../model/payment_summary';
import { PieChartData, GraphAndBarChartData } from '../../../model/chart-data';

@Injectable({
  providedIn: 'root'
})
export class HttpOperatorPaymentService extends OperatorPaymentService {

  constructor(
    private httpService: HttpService
  ) {
    super();
  }

  getAccountManagerPaymentsByPage(pageNo: number, pageSize: number): Observable<Payment[]> {
    const url = `/getAccountManagerPaymentsByPage?pageNo=${pageNo}&pageSize=${pageSize}`;
    return this.httpService.get(url, true);
  }

  getAccountManagerPayments(): Observable<Payment[]> {
    const url = `/getAccountManagerPayments`;
    return this.httpService.get(url, true);
  }

  getPayment(paymentId: number): Observable<Payment> {
    const url = `/getPayment/${paymentId}`;
    return this.httpService.get(url, true);
  }

  getAccountPaymentSummaryByPeriodByProduct(accountId: number, fromDate: string, toDate: string, period: string, propertyId: number, productId: number, breakdown: string): Observable<GraphAndBarChartData[]> {
    const url = `/getAccountPaymentSummaryByPeriodByProduct?accountId=${accountId}&fromDate=${fromDate}&toDate=${toDate}&period=${period}&propertyId=${propertyId}&productId=${productId}&breakdown=${breakdown}`;
    return this.httpService.get(url, true);
  }

  getAccountPaymentsByPage(accountId: number, pageNo: number, pageSize: number): Observable<Payment[]> {
    const url = `/getAccountPaymentsByPage?accountId=${accountId}&pageNo=${pageNo}&pageSize=${pageSize}`;
    return this.httpService.get(url, true);
  }
  getAccountPaymentSummaryByProperty(accountId: number, fromDate: string, toDate: string, propertyId: number, productId: number): Observable<PieChartData[]> {
    const url = `/getAccountPaymentSummaryByProperty?accountId=${accountId}&fromDate=${fromDate}&toDate=${toDate}&propertyId=${propertyId}&productId=${productId}`;
    return this.httpService.get(url, true);
  }
  getAccountPaymentSummaryByProduct(accountId: number, fromDate: string, toDate: string, propertyId: number, productId: number): Observable<PieChartData[]> {
    const url = `/getAccountPaymentSummaryByProduct?accountId=${accountId}&fromDate=${fromDate}&toDate=${toDate}&propertyId=${propertyId}&productId=${productId}`;
    return this.httpService.get(url, true);
  }
  getAccountPaymentSummaryByPeriod(accountId: number, fromDate: string, toDate: string, propertyId: number, productId: number, breakdoen: string): Observable<PaymentSummary> {
    const url = `/getAccountPaymentSummaryByPeriod?accountId=${accountId}&fromDate=${fromDate}&toDate=${toDate}&propertyId=${propertyId}&productId=${productId}&breakdown=${breakdoen}`;
    return this.httpService.get(url, true);
  }
  getAccountPaymnetSummaryByPeriodByProperty(accountId: number, fromDate: string, toDate: string, period: string, propertyId: number, productId: number, breakdown: string): Observable<GraphAndBarChartData[]> {
    const url = `/getAccountPaymentSummaryByPeriodByProperty?accountId=${accountId}&fromDate=${fromDate}&toDate=${toDate}&period=${period}&propertyId=${propertyId}&productId=${productId}&breakdown=${breakdown}`;
    return this.httpService.get(url, true);
  }

  getAccountManagerPaymentSummaryByProperty(fromDate: string, toDate: string, propertyId: number, productId: number): Observable<PieChartData[]> {
    const url = `/getAccountManagerPaymentSummaryByProperty?fromDate=${fromDate}&toDate=${toDate}&propertyId=${propertyId}&productId=${productId}`;
    return this.httpService.get(url, true);
  }

  getAccountManagerPaymentSummaryByProduct(fromDate: string, toDate: string, propertyId: number, productId: number): Observable<PieChartData[]> {
    const url = `/getAccountManagerPaymentSummaryByProduct?fromDate=${fromDate}&toDate=${toDate}&propertyId=${propertyId}&productId=${productId}`;
    return this.httpService.get(url, true);
  }

  getAccountManagerPaymentSummaryByPeriod(fromDate: string, toDate: string, propertyId: number, productId: number, breakdown: string): Observable<PaymentSummary> {
    const url = `/getAccountManagerPaymentSummaryByPeriod?fromDate=${fromDate}&toDate=${toDate}&propertyId=${propertyId}&productId=${productId}&breakdown=${breakdown}`;
    return this.httpService.get(url, true);
  }

  getAccountManagerPaymentSummaryByPeriodByProperty(fromDate: string, toDate: string, period: string, propertyId: number, productId: number, breakdown: string): Observable<GraphAndBarChartData[]> {
    const url = `/getAccountManagerPaymentSummaryByPeriodByProperty?fromDate=${fromDate}&toDate=${toDate}&period=${period}&propertyId=${propertyId}&productId=${productId}&breakdown=${breakdown}`;
    return this.httpService.get(url, true);
  }

  getAccountManagerPaymentSummaryByPeriodByProduct(fromDate: string, toDate: string, period: string, propertyId: number, productId: number, breakdown: string): Observable<GraphAndBarChartData[]> {
    const url = `/getAccountManagerPaymentSummaryByPeriodByProduct?fromDate=${fromDate}&toDate=${toDate}&period=${period}&propertyId=${propertyId}&productId=${productId}&breakdown=${breakdown}`;
    return this.httpService.get(url, true);
  }

  getAccountManagerPaymentsMoreRecords(pageNo: number, pageSize: number): Observable<MoreRecords> {
    return this.httpService.get(`/getAccountManagerPaymentsMoreRecords?pageNo=${pageNo}&pageSize=${pageSize}`, true);
  }

  getAccountManagerPaymentsByOperatorMoreRecords(operatorId: number, pageNo: number, pageSize: number): Observable<MoreRecords> {
    return this.httpService.get(`/getAccountManagerPaymentsByOperatorMoreRecords?operatorId=${operatorId}&pageNo=${pageNo}&pageSize=${pageSize}`, true);
  }

  getAccountManagerPaymentsByOperatorByPage(operatorId: number, pageNo: number, pageSize: number): Observable<Payment[]> {
    return this.httpService.get(`/getAccountManagerPaymentsByOperatorByPage?operatorId=${operatorId}&pageNo=${pageNo}&pageSize=${pageSize}`, true);
  }

  getAccountPaymentsByOperatorMoreRecords(accountId: number, operatorId: number, pageNo: number, pageSize: number): Observable<MoreRecords> {
    return this.httpService.get(`/getAccountPaymentsByOperatorMoreRecords?accountId=${accountId}&operatorId=${operatorId}&pageNo=${pageNo}&pageSize=${pageSize}`, true);
  }

  getAccountPaymentsByOperatorByPage(accountId: number, operatorId: number, pageNo: number, pageSize: number): Observable<Payment[]> {
    return this.httpService.get(`/getAccountPaymentsByOperatorByPage?accountId=${accountId}&operatorId=${operatorId}&pageNo=${pageNo}&pageSize=${pageSize}`, true);
  }

  getAccountPaymentsMoreRecords(accountId: number, pageNo: number, pageSize: number): Observable<MoreRecords> {
    return this.httpService.get(`/getAccountPaymentsMoreRecords?accountId=${accountId}&pageNo=${pageNo}&pageSize=${pageSize}`, true);
  }

  getAccountManagerSummaryPayment(noDays: number): Observable<NewPaymentsSummary> {
    return this.httpService.get(`/getAccountManagerSummaryPayment?noDays=${noDays}`, true);
  }

  getAccountPendingPaymentCount(accountId: number): Observable<PaymentCount> {
    const url = `/getAccountPendingPaymentCount?accountId=${accountId}`;
    return this.httpService.get(url, true);
  }

  getAccountManagerPendingPaymentCount(): Observable<PaymentCount> {
    const url = `/getAccountManagerPendingPaymentCount`;
    return this.httpService.get(url, true);
  }
}
