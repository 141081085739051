import { MineralRightsOwner } from './../../model/account';
import { StorageService } from './../../core/services/storage/storage-service.service';
import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import 'jspdf-autotable';
import { Account } from '../../model/account';
import { FormGroup, FormBuilder, FormControl, ReactiveFormsModule } from '@angular/forms';
import { CommonModule, DatePipe, Location } from '@angular/common';
import { Router, RouterModule } from '@angular/router';
import { dynamicSort, toggleSort } from 'src/app/components/shared/dynamic-sort';
import { AccountSearch } from '../../model/account_search';
import { initialAccountSearch } from '../../helpers/account';
import { ExportFooterComponent } from '../export-footer/export-footer.component';
import { AuthService } from '../../core/services/authService/auth.service';
import { AccountService } from '../../core/services/account/account.service';

const clone = obj => JSON.parse(JSON.stringify(obj));

@Component({
  selector: 'app-accounts',
  standalone: true,
  templateUrl: './accounts.component.html',
  styleUrls: ['./accounts.component.css'],
  providers: [DatePipe],
  imports: [
    ExportFooterComponent,
    CommonModule,
    ReactiveFormsModule,
    RouterModule
  ]
})

export class AccountsComponent implements OnInit {

  @ViewChild('content', { static: false }) content: ElementRef;

  public clientForm: FormGroup = this.fb.group({
    searchStr: [''],
    status: ''
  });

  private readonly blockchainExplorerUrl = null;

  public allAccounts: Account[];
  public accounts: Account[];

  public accountSearch: AccountSearch;

  public multiSelect = false;

  public sortOrderOwner = 0;
  public sortOrderOperator = 0;
  public sortOrderPropertyNo = 0;
  public sortOrderTermStart = 0;
  public sortOrderTermEnd = 0;
  public sortOrderInterestType = 0;
  public sortOrderDecimal = 0;
  public sortOrderAllowableDeductions = 0;
  public sortOrderStatus = 0;
  public sortOrderBlockChainTransId = 0;

  public lastUpdated: Date = new Date();

  public searchControl: FormControl = new FormControl('');

  constructor(
    private fb: FormBuilder,
    private accountService: AccountService,
    private router: Router,
    private location: Location,
    private storageService: StorageService,
    private authService: AuthService
  ) {
    this.lastUpdated.setDate(new Date().getDate() - 2);
  }

  ngOnInit() {
    if (this.accountService.getAccounts().length != 0) {
      this.accounts = this.accountService.getAccounts();
      this.allAccounts = clone(this.accounts);
      this.accountSearch = initialAccountSearch;
      this.accountSearch.status = 'ALL';
      this.accountSearch.searchStr = [];
    } else {
      this.router.navigate(['/dashboard']);
    }

  }

  navigateToAccountDetails(accountId: number) {
    this.storageService.setCurrentAccountId(accountId);
    this.router.navigate(['/accountDetails']);
  }

  private filter() {
    this.accounts = [];

    const tempClients = [];

    this.allAccounts.forEach((clientObject: Account, rowIndex: number) => {
      if (clientObject != null && Object.keys(clientObject).length !== 0) {

        let includeClient = true;

        if (this.accountSearch.searchStr.length > 0) {
          this.accountSearch.searchStr.forEach((searchSubStr) => {
            includeClient = includeClient
          });
        }

        if (this.accountSearch.status === 'ALL' && includeClient) {
          includeClient = true;
        } else if (this.accountSearch.status === 'FOR APPROVAL' && includeClient) {
          includeClient = (clientObject.status === 'FOR APPROVAL');
        } else if (this.accountSearch.status === 'ACTIVE' && includeClient) {
          includeClient = (clientObject.status === 'ACTIVE');
        } else if (this.accountSearch.status === 'INACTIVE' && includeClient) {
          includeClient = (clientObject.status === 'INACTIVE');
        }

        if (includeClient) {
          tempClients.push(clientObject);
        }
      }
    });

    this.accounts = tempClients;
  }

  public btnAllClients() {
    this.accountSearch.status = 'ALL';
    this.filter();
  }

  public btnForApprovalClients() {
    this.accountSearch.status = 'FOR APPROVAL';
    this.filter();
  }

  public btnActiveClients() {
    this.accountSearch.status = 'ACTIVE';
    this.filter();
  }

  public btnInactiveClients() {
    this.accountSearch.status = 'INACTIVE';
    this.filter();
  }

   public doSearch() {
    const searchText = this.searchControl.value.toLowerCase();

    this.accounts = this.allAccounts.filter(account => {
      return (
        account.id.toString().toLowerCase().includes(searchText) ||
        (account.name && account.name.toLowerCase().includes(searchText)) ||
        (account.accountOwnership?.mineralRightsOwner?.ownername && account.accountOwnership.mineralRightsOwner.ownername.toLowerCase().includes(searchText))
      );
    });
  }

  public sortByOwner() {
    this.sortOrderOwner = toggleSort(this.sortOrderOwner);
    this.accounts.sort(dynamicSort('owner', this.sortOrderOwner));
  }

  public sortByOperator() {
    this.sortOrderOperator = toggleSort(this.sortOrderOperator);
    this.accounts.sort(dynamicSort('operator', this.sortOrderOperator));
  }

  public sortByPropertyNo() {
    this.sortOrderPropertyNo = toggleSort(this.sortOrderPropertyNo);
    this.accounts.sort(dynamicSort('propertyNo', this.sortOrderPropertyNo));
  }

  public sortByTermStart() {
    this.sortOrderTermStart = toggleSort(this.sortOrderTermStart);
    this.accounts.sort(dynamicSort('termStartSort', this.sortOrderTermStart));
  }

  public sortByTermEnd() {
    this.sortOrderTermEnd = toggleSort(this.sortOrderTermEnd);
    this.accounts.sort(dynamicSort('termEndSort', this.sortOrderTermEnd));
  }

  public sortByInterestType() {
    this.sortOrderInterestType = toggleSort(this.sortOrderInterestType);
    this.accounts.sort(dynamicSort('interestType', this.sortOrderInterestType));
  }

  public sortByDecimal() {
    this.sortOrderDecimal = toggleSort(this.sortOrderDecimal);
    this.accounts.sort(dynamicSort('decimalInterest', this.sortOrderDecimal));
  }

  public sortByBlockchainTransId() {
    this.sortOrderBlockChainTransId = toggleSort(this.sortOrderBlockChainTransId);
    this.accounts.sort(dynamicSort('bcTransId', this.sortOrderBlockChainTransId));
  }

  public sortByAllowableDeductions() {
    this.sortOrderAllowableDeductions = toggleSort(this.sortOrderAllowableDeductions);
    this.accounts.sort(dynamicSort('allowableDeductions', this.sortOrderAllowableDeductions));
  }

  public sortByStatus() {
    this.sortOrderStatus = toggleSort(this.sortOrderStatus);
    this.accounts.sort(dynamicSort('status', this.sortOrderStatus));
  }

  public onBack() {
    this.location.back();
  }

  public blockchainClick(bcBlockId) {
    window.open(this.blockchainExplorerUrl + bcBlockId);
  }

}
