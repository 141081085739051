import { Component, Input } from '@angular/core';
import * as FileSaver from 'file-saver';
import { AccountService } from '../../core/services/account/account.service';
import { Payment } from '../../model/payment';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-statement-footer',
  standalone: true,
  imports: [
    CommonModule
  ],
  templateUrl: './statement-footer.component.html',
  styleUrl: './statement-footer.component.css'
})
export class StatementFooterComponent {
  @Input() currentTotal: number;
}
