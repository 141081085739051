import { Injectable, Injector, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { MsalService } from '@azure/msal-angular';
import { AuthenticationResult, PublicClientApplication } from '@azure/msal-browser';
import { AzureAuthModule } from '../../azure-auth.module';
import { tap } from 'rxjs/operators';
import { AzureAuthConfig, AZURE_AUTH_CONFIG } from '../../models/config';

@Injectable({
  providedIn: AzureAuthModule
})
export class AzureAuthService implements OnInit {

  private config!: AzureAuthConfig;
  constructor(
    private msalService: MsalService,
    private injector: Injector
  ) {
    this.config = this.injector.get(AZURE_AUTH_CONFIG);
  }

  ngOnInit(): void {

  }

  logInPopUp(): Observable<AuthenticationResult> {
    if (!localStorage.getItem('user')) {
      localStorage.clear();
    }
    return this.msalService.loginPopup().pipe(
      tap((response) => {
        this.msalService.instance.setActiveAccount(response.account)
      })
    );
  }

  logInRedirect(redirectStartPage?: string): Observable<void | AuthenticationResult> {
    return this.msalService.loginRedirect({ scopes: this.config.scopes, redirectStartPage }).pipe(
      tap((response) => {
        if (response) {
          this.msalService.instance.setActiveAccount(response.account);
        }
      })
    );
  }

  getActiveAccount(): Observable<AuthenticationResult | null> {
    localStorage.clear
    const account = this.msalService.instance.getActiveAccount();
    if (!account) {
      const accounts = this.msalService.instance.getAllAccounts();
      if (accounts.length > 0) {
        this.msalService.instance.setActiveAccount(accounts[0]);
      }
    }
    const accountInfo = this.msalService.instance.getActiveAccount();
    if (accountInfo) {
      return this.msalService.acquireTokenSilent({
        scopes: this.config.scopes,
        account: accountInfo
      }).pipe(tap(
        () => { },
        () => this.logOut().subscribe()
      ))
    }
    return this.handleRedirectPromise();
  }

  logOut(): Observable<void> {
    return this.msalService.logout();
  }

  private handleRedirectPromise(): Observable<AuthenticationResult | null> {
    return new Observable((observer) => {
      this.msalService.instance.handleRedirectPromise()
        .then(authResult => {
          observer.next(authResult ?? null);
          observer.complete();
        }).catch(() => {
          observer.next(null);
          observer.complete();
        });
    })
  }

}
