export const dynamicSort = (property, sortOrder) => {
  return (a, b) => {
    /* next line works with strings and numbers,
     * and you may want to customize it to your needs
     * sortOrder = 1 is Ascending and sortOrder = -1 is Descending
     */
    const result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
    return result * sortOrder;
  };
};

export const toggleSort = (sortOrder) => {
  if (sortOrder === 0) {
    return 1;
  } else {
    return sortOrder * -1;
  }
};
