import { StorageService } from './../../core/services/storage/storage-service.service';
import { AzureAuthService } from './../../modules/azure-auth/services/azure-auth/azure-auth.service';
import { MsalService } from '@azure/msal-angular';
import { environment } from './../../../environments/environment';
import { Router } from '@angular/router';
import { User, UserResponse } from './../../model/user';
import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../core/services/authService/auth.service';
import { catchError, finalize, takeUntil } from 'rxjs/operators';
import { Observable, Subject, of } from 'rxjs';
import { AuthenticationResult } from '@azure/msal-browser';
import { HttpService } from '../../core/services/httpService/http.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  private unsubscribe$ = new Subject<void>();
  private isMock = environment.mock;

  public errorMessage = '';
  public loginUserData: User = {
    email: '',
    fname: '',
    lname: '',
  }

  constructor(
    private _auth: AuthService,
    private authService: AuthService,
    private msalService: MsalService,
    private AzureAuthService: AzureAuthService,
    private httpService: HttpService,
    private storageService: StorageService,
    private _router: Router) {
  }

  ngOnInit(): void {
    this.msalService.initialize().toPromise().catch((error: any) => {
      console.error("Error initializing MSAL:", error);
    });
  }

  loginUser() {
    this.loginUserData.roleId = environment.ROLE_BANK;
    const authResult = JSON.parse(localStorage.getItem("userData")) as AuthenticationResult;
    this._auth.loginUser(this.loginUserData.email, authResult.idToken ).pipe(
      takeUntil(this.unsubscribe$),
      finalize(() => {
        this._router.navigate(['/dashboard']);
      }),
      catchError((error: Observable<User>) => {
        this.errorMessage = 'Invalid login credentials';
        return of(null);
      })
    )
      .subscribe(
        (user: User) => {
          this._auth.setUser(user);
          localStorage.setItem('user', JSON.stringify(user));
          localStorage.setItem('token', user.token);
          this.httpService.setUserToken(user.token);
        }
      );
  }

  userAuth(): void {
    if (this.isMock) {
      const mockUser: UserResponse = {
        token: 'token',
        accountManager: {
          name: 'John Doe',
          email: 'johndoe@lan.ie',
          id: 1,
          userId: 1,
          tel: '1234567890',
          cell: '1234567890',
          fax: '1234567890',
          accounts: [],
          multiAccountEntity: {
            id: 1,
            name: 'LAN',
            logo: 'logo',
            address1: '123 Main St',
            address2: 'Apt 1',
            city: 'Anytown',
            zipCode: '12345',
            tel: '1234567890',
            fax: '1234567890',
            email: 'johndoe@lan.ie',
            state: undefined,
            web: ''
          }
        }
      };
      localStorage.setItem('user', JSON.stringify(mockUser) );
      localStorage.setItem('token', mockUser.token);
      this._router.navigate(['/dashboard']);
    } else {
      this.AzureAuthService.logInPopUp().pipe(
        takeUntil(this.unsubscribe$),
        catchError((error: Observable<User>) => {
          this.errorMessage = 'Invalid login credentials';
          console.error('Error logging in:', error);
          return of(null);
        })
      )
        .subscribe(
          (authResult: AuthenticationResult) => {
            if (authResult) {
              this.loginUserData.email = authResult.account.username;
              localStorage.setItem("userData", JSON.stringify(authResult));
              this.loginUser()
             }
          }
        );
      }
  }

}
