import { Deck } from "../model/deck";
import { operators } from "./operators";

export const deck1: Deck = {
  id: 1,
  operator: operators[0],
  code: '15x15',
  changeCode: '15',
  insertBy: 1,
  insertDate: new Date('2020-01-01'),
  modifyBy: 1,
  modifyDate: new Date('2020-01-01')
}

export const deck2: Deck = {
  id: 2,
  operator: operators[1],
  code: '20x20',
  changeCode: '20',
  insertBy: 2,
  insertDate: new Date('2020-02-01'),
  modifyBy: 2,
  modifyDate: new Date('2020-02-01')
}

export const deck3: Deck = {
  id: 3,
  operator: operators[2],
  code: '25x25',
  changeCode: '25',
  insertBy: 3,
  insertDate: new Date('2020-03-01'),
  modifyBy: 3,
  modifyDate: new Date('2020-03-01')
}

export const deck4: Deck = {
  id: 4,
  operator: operators[3],
  code: '30x30',
  changeCode: '30',
  insertBy: 4,
  insertDate: new Date('2020-04-01'),
  modifyBy: 4,
  modifyDate: new Date('2020-04-01')
}

export const deck5: Deck = {
  id: 5,
  operator: operators[4],
  code: '35x35',
  changeCode: '35',
  insertBy: 5,
  insertDate: new Date('2020-05-01'),
  modifyBy: 5,
  modifyDate: new Date('2020-05-01')
}

export const deck6: Deck = {
  id: 6,
  operator: operators[5],
  code: '40x40',
  changeCode: '40',
  insertBy: 6,
  insertDate: new Date('2020-06-01'),
  modifyBy: 6,
  modifyDate: new Date('2020-06-01')
}

export const deck7: Deck = {
  id: 7,
  operator: operators[6],
  code: '45x45',
  changeCode: '45',
  insertBy: 7,
  insertDate: new Date('2020-07-01'),
  modifyBy: 7,
  modifyDate: new Date('2020-07-01')
}

export const deck8: Deck = {
  id: 8,
  operator: operators[7],
  code: '50x50',
  changeCode: '50',
  insertBy: 8,
  insertDate: new Date('2020-08-01'),
  modifyBy: 8,
  modifyDate: new Date('2020-08-01')
}

export const deck9: Deck = {
  id: 9,
  operator: operators[8],
  code: '55x55',
  changeCode: '55',
  insertBy: 9,
  insertDate: new Date('2020-09-01'),
  modifyBy: 9,
  modifyDate: new Date('2020-09-01')
}

export const deck10: Deck = {
  id: 10,
  operator: operators[9],
  code: '60x60',
  changeCode: '60',
  insertBy: 10,
  insertDate: new Date('2020-10-01'),
  modifyBy: 10,
  modifyDate: new Date('2020-10-01')
}

export const deck11: Deck = {
  id: 11,
  operator: operators[10],
  code: '65x65',
  changeCode: '65',
  insertBy: 11,
  insertDate: new Date('2020-11-01'),
  modifyBy: 11,
  modifyDate: new Date('2020-11-01')
}

export const deck12: Deck = {
  id: 12,
  operator: operators[11],
  code: '70x70',
  changeCode: '70',
  insertBy: 12,
  insertDate: new Date('2020-12-01'),
  modifyBy: 12,
  modifyDate: new Date('2020-12-01')
}

export const deck13: Deck = {
  id: 13,
  operator: operators[12],
  code: '75x75',
  changeCode: '75',
  insertBy: 13,
  insertDate: new Date('2020-13-01'),
  modifyBy: 13,
  modifyDate: new Date('2020-13-01')
}

export const deck14: Deck = {
  id: 14,
  operator: operators[13],
  code: '80x80',
  changeCode: '80',
  insertBy: 14,
  insertDate: new Date('2020-14-01'),
  modifyBy: 14,
  modifyDate: new Date('2020-14-01')
}

export const deck15: Deck = {
  id: 15,
  operator: operators[14],
  code: '85x85',
  changeCode: '85',
  insertBy: 15,
  insertDate: new Date('2020-15-01'),
  modifyBy: 15,
  modifyDate: new Date('2020-15-01')
}

export const deck16: Deck = {
  id: 16,
  operator: operators[15],
  code: '90x90',
  changeCode: '90',
  insertBy: 16,
  insertDate: new Date('2020-16-01'),
  modifyBy: 16,
  modifyDate: new Date('2020-16-01')
}

export const deck17: Deck = {
  id: 17,
  operator: operators[16],
  code: '90x90',
  changeCode: '90',
  insertBy: 17,
  insertDate: new Date('2020-17-01'),
  modifyBy: 17,
  modifyDate: new Date('2020-17-01')
}

export const deck18: Deck = {
  id: 18,
  operator: operators[17],
  code: '90x90',
  changeCode: '90',
  insertBy: 18,
  insertDate: new Date('2020-18-01'),
  modifyBy: 18,
  modifyDate: new Date('2020-18-01')
}

export const decks: Deck[] = [
  deck1,
  deck2,
  deck3,
  deck4,
  deck5,
  deck6,
  deck7,
  deck8,
  deck9,
  deck10,
  deck11,
  deck12,
  deck13,
  deck14,
  deck15,
  deck16,
  deck17,
  deck18
];
