import { Account } from "../model/account";
import { MROs } from "./MineralRightsOwners";
import { titles } from "./titles";

export const account1: Account = {
  id: 1,
  visionAccountId: '1',
  name: 'FACTORY POND MINERALS, LLC - HAVERFORD',
  status: 'Active',
  number: '123456',
  accountOwnership: {
    id: 1,
    visionAccountOwnerShipId: '1',
    name: 'John Alders',
    status: 'Active',
    number: '123456',
    ownershipPercentage: 15.000000,
    mineralRightsOwner: MROs[0]
  },
  titles: [
    titles[0],
  ]
}

export const account2: Account = {
  id: 2,
  visionAccountId: '2',
  name: 'FACTORY POND MINERALS, LLC - HAVERFORD',
  status: 'Active',
  number: '123456',
  accountOwnership: {
    id: 2,
    visionAccountOwnerShipId: '2',
    name: 'Jane Peshlakai',
    status: 'Active',
    number: '123456',
    ownershipPercentage: 20.000000,
    mineralRightsOwner: MROs[1]
  },
  titles: [
    titles[1],
  ]
}

export const account3: Account = {
  id: 3,
  visionAccountId: '3',
  name: 'FACTORY POND MINERALS, LLC - HAVERFORD',
  status: 'Active',
  number: '123456',
  accountOwnership: {
    id: 3,
    visionAccountOwnerShipId: '3',
    name: 'Sam Cubrick',
    status: 'Active',
    number: '123456',
    ownershipPercentage: 20.000000,
    mineralRightsOwner: MROs[2]
  },
  titles: [
    titles[2],
  ]
}

export const account4: Account = {
  id: 4,
  visionAccountId: '4',
  name: 'FACTORY POND MINERALS, LLC - HAVERFORD',
  status: 'Active',
  number: '123456',
  accountOwnership: {
    id: 4,
    visionAccountOwnerShipId: '4',
    name: 'Sarah Pickman',
    status: 'Active',
    number: '123456',
    ownershipPercentage: 15.000000,
    mineralRightsOwner: MROs[3]
  },
  titles: [
    titles[3],
  ]
}

export const account5: Account = {
  id: 5,
  visionAccountId: '5',
  name: 'FACTORY POND MINERALS, LLC - HAVERFORD',
  status: 'Active',
  number: '123456',
  accountOwnership: {
    id: 5,
    visionAccountOwnerShipId: '5',
    name: 'Maryam Badger',
    status: 'Active',
    number: '123456',
    ownershipPercentage: 60.000000,
    mineralRightsOwner: MROs[4]
  },
  titles: [
    titles[4],
  ]
}

export const account6: Account = {
  id: 6,
  visionAccountId: '6',
  name: 'FACTORY POND MINERALS, LLC - HAVERFORD',
  status: 'Active',
  number: '123456',
  accountOwnership: {
    id: 6,
    visionAccountOwnerShipId: '6',
    name: 'Tracy Perkins',
    status: 'Active',
    number: '123456',
    ownershipPercentage: 5.000000,
    mineralRightsOwner: MROs[5]
  },
  titles: [
    titles[5],
  ]
}

export const accounts = [account1, account2, account3, account4, account5, account6];

