import { DeductionType } from "../model/deduction_type";

export const deductionType1: DeductionType = {
  id: 1,
  name: 'Transportation'
};

export const deductionType2: DeductionType = {
  id: 2,
  name: 'Processing'
};

export const deductionType3: DeductionType = {
  id: 3,
  name: 'Compression'
};

export const deductionType4: DeductionType = {
  id: 4,
  name: 'Marketing'
};

export const deductionTypes: DeductionType[] = [
  deductionType1,
  deductionType2,
  deductionType3,
  deductionType4
];
