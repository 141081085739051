import { User } from "../model/user";
import { banks } from "./banks";
import { randomDateMinOrMax } from "./helpers/randomDateHelper";
import { getRandomInt } from "./helpers/randomNumbersHelper";
import { operators } from "./operators";

export const user1: User = {
  id: 1,
  fname: "fname1",
  lname: "lname1",
  tel: "tel1",
  email: "email1",
  password: "password1",
  entityId: 1,
  roleId: 1,
  operator: operators[getRandomInt(0, 2)],
  bank: banks[getRandomInt(0, 2)],
  status: 1,
  verificationCode: "verificationCode1",
  insertDate: randomDateMinOrMax(2019, 2020, false),
  insertBy: 1,
  modifiedDate: randomDateMinOrMax(2019, 2020, true),
  modifiedBy: 1
};

export const user2: User = {
  id: 2,
  fname: "fname2",
  lname: "lname2",
  tel: "tel2",
  email: "email2",
  password: "password2",
  entityId: 2,
  roleId: 2,
  operator: operators[getRandomInt(0, 2)],
  bank: banks[getRandomInt(0, 2)],
  status: 2,
  verificationCode: "verificationCode2",
  insertDate: randomDateMinOrMax(2019, 2020, false),
  insertBy: 2,
  modifiedDate: randomDateMinOrMax(2019, 2020, true),
  modifiedBy: 2
};

export const user3: User = {
  id: 3,
  fname: "fname3",
  lname: "lname3",
  tel: "tel3",
  email: "email3",
  password: "password3",
  entityId: 3,
  roleId: 3,
  operator: operators[getRandomInt(0, 2)],
  bank: banks[getRandomInt(0, 2)],
  status: 3,
  verificationCode: "verificationCode3",
  insertDate: randomDateMinOrMax(2019, 2020, false),
  insertBy: 3,
  modifiedDate: randomDateMinOrMax(2019, 2020, true),
  modifiedBy: 3
};

export const users: User[] = [user1, user2, user3];
