import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-spinner',
  standalone: true,
  imports: [
    CommonModule
  ],
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss']
})
export class SpinnerComponent{
  @Input() width?: string = '50px';
  @Input() height?: string = '50px';
  @Input() chartType?: string;

  get spinnerType() {
    switch (this.chartType) {
      case 'card':
        return {
          top: '0%',
        }
      case 'product':
        return {
          top: '50%',
        }
      case 'account-details-payments':
        return {
          top: '30%',
          left: '45%',
        }
      case 'payments':
        return {
          top: '30%',
          left: '45%',
        }
      case 'account-payments':
        return {
          top: '98%',
          left: '45%',
        }
      default:
        return
    }
  }
}
