import { Component, Input, SimpleChanges } from '@angular/core';
import { Payment } from '../../model/payment';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-payment-footer-paid',
  standalone: true,
  imports: [
    CommonModule
  ],
  templateUrl: './payment-footer-paid.component.html',
  styleUrl: './payment-footer-paid.component.css'
})
export class PaymentFooterPaidComponent {
  @Input() payment: Payment;

  get total() {
    let totalAmount = 0;
    for (const payment of this.payment.royaltyPayments) {
      totalAmount += payment.mroNetValue;
    }
    return totalAmount;
  }


}
