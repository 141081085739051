import { Component, Inject, OnInit } from "@angular/core";
import { CommonModule, DatePipe, Location } from "@angular/common";
import { ReactiveFormsModule, UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { Router } from "@angular/router";
import { PropertyService } from "../../core/services/property/property.service";
import { StorageService } from "../../core/services/storage/storage-service.service";
import { PropertySearch } from "../../model/property_search";
import { Subject } from "rxjs";
import { Property } from "../../model/property";
import { saveAs } from "file-saver";
import { ExportFooterComponent } from "../export-footer/export-footer.component";
import { MatTooltipModule } from "@angular/material/tooltip";

@Component({
  selector: "app-account-properties",
  standalone: true,
  providers: [DatePipe],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    ExportFooterComponent,
    MatTooltipModule
  ],
  templateUrl: "./account-properties.component.html",
  styleUrl: "./account-properties.component.css",
})
export class AccountPropertiesComponent implements OnInit {
  public propertyForm: UntypedFormGroup = this.fb.group({
    searchStr: [""],
    state: [""],
  });
  public lastModified: Date;
  public tabStripVisible = false;
  public spreadBackColor = "white";
  public hostStyle = {
    height: "100%",
    width: "100%",
  };
  public data: unknown;
  private unsubscribe = new Subject<void>();
  public propertyDescColumn = 0;
  public propertyNoColumn = 1;
  public propertyNameColumn = 2;
  public stateColumn = 3;
  public countyColumn = 4;
  public operatorColumn = 5;
  public purchaserColumn = 6;
  public blockChainColumn = 7;
  public blockChainTransIdColumn = 8;
  public gridColCount = 9;
  public allProperties: Property[] = [];
  public properties: Property[];
  public selectedStateId: number;

  public propertySearch: PropertySearch = {
    propertyType: 0,
    searchStr: [],
    state: null
  };

  constructor(
    @Inject(UntypedFormBuilder)
    private fb: UntypedFormBuilder,
    private propertyService: PropertyService,
    private datePipe: DatePipe,
    private location: Location,
    private router: Router,
    private storageService: StorageService
  ) { }

  ngOnInit(): void {
    if (this.storageService.getAccountProperties() !== undefined) {
      this.properties = this.storageService.getAccountProperties();
    } else {
      this.router.navigate(["/dashboard"]);
    }
    this.properties = this.properties.filter(property => property.name !== "Unknown");
    this.allProperties = this.properties;
    this.propertySearch;
    this.propertySearch.propertyType = 0;
    this.propertySearch.searchStr = [];
    this.propertyForm.get('state').setValue(-1);
  }

  get states() {
    return this.allProperties.reduce((uniqueStates, property) => {
      // If poending payment - Unknow property will be in teh list with no geographicalData
      const state = property?.geographicalData?.county?.state;
      if (state && !uniqueStates.find(s => s.id === state.id)) {
        uniqueStates.push({ name: state.name, id: state.id, abbr: state.abbr });
      }
      return uniqueStates;
    }, []);
  }

  public trackByStateId(index: number, item: any) {
    return item.id;
  }

  public navigateToPropertyDetail(propertyId: number) {
    this.storageService.setCurrentPropertyId(propertyId);
    this.router.navigate(["/propertyDetail"]);
  }

  public exportToCSV() {
    const csvData = this.convertToCSV(this.properties);
    const blob = new Blob([csvData], { type: "text/csv" });
    saveAs(blob, "properties.csv");
  }

  private convertToCSV(data: Property[]): string {
    const header = [
      "Description",
      "Property",
      "Name",
      "State",
      "County",
      "Operator",
    ].join(",");
    const rows = data.map((property) => {
      const accountPropperty = property;

      return [
        accountPropperty.description || "",
        accountPropperty.propertyNo || "",
        accountPropperty.name || "",
        accountPropperty.geographicalData?.county?.state?.name || "",
        accountPropperty.geographicalData?.county?.name || "",
      ].join(",");
    });
    return `${header}\n${rows.join("\n")}`;
  }
  transformDate(date) {
    return this.datePipe.transform(date, "yyyy-MM-dd");
  }

  setPropertyValues(filteredProperties: Property[]): Property[] {
    if (Array.isArray(filteredProperties) && filteredProperties.length > 0) {
      filteredProperties.forEach((property) => {
        property.geographicalData.county.state.name = property.geographicalData
          .county
          ? property.geographicalData.county.state
            ? property.geographicalData.county.state.name
            : ""
          : "";
        property.geographicalData.county.name = property.geographicalData.county
          ? property.geographicalData.county.name
          : "";
      });
    }
    return filteredProperties;
  }

  getUniqueOperators(wells: any[]): string[] {
    const uniqueOperators = new Set();
    return wells
      .filter(well => {
        const operatorName = well?.operator?.name;
        if (operatorName && !uniqueOperators.has(operatorName)) {
          uniqueOperators.add(operatorName);
          return true;
        }
        return false;
      })
      .map(well => well.operator.name);
  }

  private filter() {
    this.properties = [];
    const tempProperties = [];
    const today = new Date();
    if (Array.isArray(this.allProperties) && this.allProperties.length > 0) {
      this.allProperties.forEach((propertyObject: Property) => {
        if (
          propertyObject != null &&
          Object.keys(propertyObject).length !== 0
        ) {
          let includeProperty = true;
          if (this.propertySearch.state != null) {
            if (this.propertySearch.state !== "-1") {
              includeProperty =
                propertyObject.geographicalData.county.state.id ==
                Number(this.propertySearch.state);
            } else {
              this.properties = this.allProperties;
            }
          }
          if (includeProperty && this.propertySearch.searchStr.length > 0) {
            this.propertySearch.searchStr.forEach((searchSubStr) => {
              includeProperty =
                includeProperty &&
                (propertyObject.propertyNo.indexOf(searchSubStr) !==
                  -1 ||
                  propertyObject.name
                    .toUpperCase()
                    .indexOf(searchSubStr.toUpperCase()) !== -1 ||
                  propertyObject.description
                    .toUpperCase()
                    .indexOf(searchSubStr.toUpperCase()) !== -1 ||
                  propertyObject.geographicalData.county.state.name
                    .toUpperCase()
                    .indexOf(searchSubStr.toUpperCase()) !== -1 ||
                  propertyObject.geographicalData.county.name
                    .toUpperCase()
                    .indexOf(searchSubStr.toUpperCase()) !== -1);
            });
          }

          if (this.propertySearch.propertyType > 0 && includeProperty) {
            if (this.propertySearch.propertyType === 1) {
              if (propertyObject.effectiveFrom !== null) {
                includeProperty =
                  propertyObject.effectiveFrom >= today;
              } else {
                includeProperty = false;
              }
            } else if (this.propertySearch.propertyType === 3) {
              includeProperty = false;
            } else {
              if (propertyObject.effectiveFrom !== null) {
                if (propertyObject.effectiveFrom <= today) {
                  includeProperty = true;
                } else {
                  includeProperty = false;
                }
              } else {
                includeProperty = true;
              }
            }
          }

          if (includeProperty) {
            tempProperties.push(propertyObject);
          }
        }
      });
    }

    this.properties = tempProperties;
  }

  public doSearch() {
    const searchInput = this.propertyForm.get("searchStr").value.toLowerCase();

    this.properties = this.allProperties.filter(
      (property) =>
        property.propertyNo.toLowerCase().includes(searchInput) ||
        property.name.toLowerCase().includes(searchInput)
    );
  }

  public onStateChange() {
    this.selectedStateId = this.propertyForm.get("state").value;
    this.propertySearch.state = this.propertyForm.get("state").value;
    this.filter();
  }

  public btnAllProperties() {
    this.propertySearch.propertyType = 0;
    this.filter();
  }

  public btnInProgressProperties() {
    this.propertySearch.propertyType = 1;
    this.filter();
  }

  public btnActiveProperties() {
    this.propertySearch.propertyType = 2;
    this.filter();
  }

  public btnInactiveProperties() {
    this.propertySearch.propertyType = 3;
    this.filter();
  }

  public onBack() {
    this.location.back();
  }

}
