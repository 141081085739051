<div id="messages"></div>
<div id="content">
  <!-- Pathway -->
  <div id="pathway">
    <div class="page_container">
      <div id="pathway_inner">
        <div class="pathway_left">
          <ul>
            <li>
              <button
                type="button"
                class="btn_normal btn_inverse_color btn_icon_only btn_no_border btn_no_padding"
                id="btn_back"
                title="Back"
                (click)="onBack()"
              >
                <span class="btn_icon"><span class="icon_back"></span></span>
                <span class="btn_text">Back</span>
              </button>
            </li>
            <li>
              <div class="pathway">
                <ul>
                  <!-- <li><a href="#">Mineral Rights Owner</a></li> -->
                  <!-- <li><a href="#">Jane Peshlakai</a></li> -->
                  <li><span>Accounts</span></li>
                </ul>
              </div>
            </li>
          </ul>
        </div>
        <div class="pathway_right">
          <ul>
            <li>
              <div class="form_row no_padding">
                <div class="form_label hidden_label">
                  <label for="page_search">Search</label>
                </div>
                <div class="form_input">
                  <input
                    type="text"
                    class="inputbox searchbox padding_medium no_border"
                    id="page_search"
                    name="page_search"
                    [formControl]="searchControl"
                    placeholder="Search client’s names, property, operator..."
                    style="width: 360px;"
                    (keyup)="doSearch()"
                  />
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>


  <div class="page_container">
    <div id="content_inner">
      <!-- Body Content -->
      <div class="content_body">
        <div id="content" #content>
          <div class="content_body">
            <div class="scroll_container" style="height: 30rem;">
              <table class="table_grid no_wrap" id="html2Pdf">
                <!-- Table header -->
                <thead>
                  <tr>
                    <th>Account </th>
                    <th>Account Name</th>
                    <th>Status</th>
                    <th>Owner Name</th>
                    <th>First Name</th>
                    <th>Last Name</th>
                    <th>Address1</th>
                    <th>Address2</th>
                    <th>City</th>
                    <th>State</th>
                    <th>Zipcode</th>
                  </tr>
                </thead>
                <!-- Table body -->
                <tbody>
                  <tr class="pointer" *ngFor="let account of accounts" (click)="navigateToAccountDetails(account.id)">
                    <td>{{ account?.id }}</td>
                    <td>{{ account?.name }}</td>
                    <td>{{ account?.accountOwnership?.[0]?.mineralRightsOwner?.status }}</td>
                    <td>{{ account?.accountOwnership?.[0]?.mineralRightsOwner?.ownername }}</td>
                    <td>{{ account?.accountOwnership?.[0]?.mineralRightsOwner?.fname }}</td>
                    <td>{{ account?.accountOwnership?.[0]?.mineralRightsOwner?.lname }}</td>
                    <td>{{ account?.accountOwnership?.[0]?.mineralRightsOwner?.address1 }}</td>
                    <td>{{ account?.accountOwnership?.[0]?.mineralRightsOwner?.address2 }}</td>
                    <td>{{ account?.accountOwnership?.[0]?.mineralRightsOwner?.city }}</td>
                    <td>{{ account?.accountOwnership?.[0]?.mineralRightsOwner?.state.name }}</td>
                    <td>{{ account?.accountOwnership?.[0]?.mineralRightsOwner?.zipCode }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="content_footer">
          <app-export-footer [accounts]="accounts"></app-export-footer>
        </div>
      </div>
    </div>
  </div>
</div>
