import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { AccountService } from './account.service';
import { HttpService } from '../httpService/http.service';
import { Account, AccountSummary } from '../../../model/account';
import { PayingOperator } from '../../../model/operator';

@Injectable({
  providedIn: 'root'
})
export class HttpAccountService extends AccountService {

  constructor(private httpService: HttpService) {
    super();
  }

  getAccountManagerAccounts(): Observable<Account[]> {
    return this.httpService.get(`/getAccountManagerAccounts`, false);
  }

  getAccount(accountId: number): Observable<Account> {
    return this.httpService.get(`/getAccount?accountId=${accountId}`, false);
  }

  getAccountManagerSummary(): Observable<AccountSummary> {
    return this.httpService.get(`/getAccountManagerSummary`, true);
  }

  getAccountSummary(accountId: number): Observable<AccountSummary> {
    return this.httpService.get(`/getAccountSummary?accountId=${accountId}`, true);
  }

  getAccountPayingOperators(accountId: number): Observable<PayingOperator[]> {
    return this.httpService.get(`/getAccountPayingOperators?accountId=${accountId}`, true);
  }

  getAccountManagerPayingOperators(): Observable<PayingOperator[]> {
    return this.httpService.get(`/getAccountManagerPayingOperators`, true);
  }

}
