import { Component } from '@angular/core';
import { OperatorPaymentService } from '../../../core/services/operator-payment/operator-payment.service';
import { RouterModule } from '@angular/router';
import { DecimalPipe } from '@angular/common';

@Component({
  selector: 'app-new-payments',
  standalone: true,
  imports: [
    RouterModule,
    DecimalPipe
  ],
  templateUrl: './new-payments.component.html',
  styleUrl: './new-payments.component.css'
})
export class NewPaymentsComponent {
  public aggregateGross: number;
  public aggregateNet: number;
  public aggregateDeductions: number;
  public currentMonth: number;

  constructor(
    private operatorPaymentService: OperatorPaymentService,
  ) { }

  ngOnInit() {
    this.getNewPaymentsSummary();
  }

  private getNewPaymentsSummary() {
    this.operatorPaymentService.getAccountManagerSummaryPayment(500).subscribe((summary) => {
      this.aggregateGross = summary.totalGross;
      this.aggregateNet = summary.totalNet;
      this.aggregateDeductions = summary.totalDeductions
    });
  }

}
