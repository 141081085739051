import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../core/services/authService/auth.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  copyrightYear: number;
  
// tslint:disable-next-line: variable-name
  constructor(private _authService: AuthService) { }

  ngOnInit() {
    this.setCopyright();
  }

  loggedIn() {
    return this._authService.loggedIn();
  }

  getYear() {
    return new Date().getFullYear();
  }

  private setCopyright() {
    this.copyrightYear = this.getYear();
  }
}
