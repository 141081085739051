import { Operator } from '../../model/operator';
import { Component, Inject } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { OperatorPaymentService } from '../../core/services/operator-payment/operator-payment.service';
import { CommonModule, DatePipe, Location } from '@angular/common';
import { Payment } from '../../model/payment';
import { PaymentSearch } from '../../model/payment_search';
import { PaymentViewComponent } from '../payment-view/payment-view.component';
import { PaymentGridComponent } from '../payment-grid/payment-grid.component';
import { PaymentFooterPaidComponent } from '../payment-footer-paid/payment-footer-paid.component';
import { ExportFooterComponent } from '../export-footer/export-footer.component';
import { StorageService } from '../../core/services/storage/storage-service.service';

@Component({
  selector: 'app-payment-details',
  standalone: true,
  imports: [
    CommonModule,
    PaymentViewComponent,
    PaymentGridComponent,
    PaymentFooterPaidComponent,
    ExportFooterComponent
  ],
  providers: [
    DatePipe
  ],
  templateUrl: './payment-details.component.html',
  styleUrl: './payment-details.component.css'
})
export class PaymentDetailsComponent {
  public paymentForm: UntypedFormGroup;
  public paymentSearch: PaymentSearch;
  public payments: Payment[];
  public payment: Payment;
  public operators: Operator[];
  public allStatement: Payment[];
  public monthStr: string;
  public paymentDate: Date;
  public currSubStatementIndex = 0;
  public showGridView = false;
  public showStatementView = true;
  public maxMonth: number;
  public minMonth: number;

  private paymentId: number;

  constructor(
    @Inject(UntypedFormBuilder) private fb: UntypedFormBuilder,
    private router: Router,
    private paymentService: OperatorPaymentService,
    private datePipe: DatePipe,
    private storageService: StorageService,
    private location: Location
  ) {
    this.paymentForm = this.fb.group({
      operatorId: [''],
      month: ['']
    });
    this.paymentSearch = {
      date: null,
      operatorId: 0,
      month: 1
    };
  }

  ngOnInit() {
    if (this.storageService.getCurrentPayment()) {
      this.payment = this.storageService.getCurrentPayment();
    } else {
      this.router.navigate(['/dashboard']);
    }
  }

  findMinMaxMonths() {
    if (this.payments && this.payments.length != 0) {
      const months = this.payments.map(statement => {
        const date = new Date(statement.paymentDate);
        return +this.datePipe.transform(date, 'yyyyMM');
      });
      this.minMonth = Math.min(...months);
      this.maxMonth = Math.max(...months);
    }
  }

  public showGrid() {
    this.showStatementView = false;
    this.showGridView = true;

    const viewGridElement = document.getElementById('view_grid');
    if (viewGridElement) {
      viewGridElement.style.display = 'block';
    }

    const viewStatementLinkElement = document.getElementById('view_statement_link');
    if (viewStatementLinkElement) {
      viewStatementLinkElement.style.display = 'block';
    }

    this.filterPayments();
  }

  public showStatement() {
    this.showGridView = false;
    this.showStatementView = true;

    const viewGridElement = document.getElementById('view_grid');
    if (viewGridElement) {
      viewGridElement.style.display = 'none';
    }

    const viewStatementLinkElement = document.getElementById('view_statement_link');
    if (viewStatementLinkElement) {
      viewStatementLinkElement.style.display = 'none';
    }
  }

  public gotoDashboard() {
    this.router.navigate(['/dashboard']);
  }

  public toggleContainer(toggleElement) {
    const toggleElements = document.getElementsByClassName(toggleElement);
    for (let i = 0; i < toggleElements.length; i++) {
      const element = toggleElements[i] as HTMLElement;
      element.style.display = element.style.display === 'none' ? 'block' : 'none';
    }

    const toggleElementShow = document.getElementById(toggleElement + '_show');
    if (toggleElementShow) {
      toggleElementShow.style.display = toggleElementShow.style.display === 'none' ? 'block' : 'none';
    }

    const toggleElementHide = document.getElementById(toggleElement + '_hide');
    if (toggleElementHide) {
      toggleElementHide.style.display = toggleElementHide.style.display === 'none' ? 'block' : 'none';
    }
  }

  getPayments() {
    this.payments = this.paymentService.getPayments();
  }

  getMonthStr(dateInt) {
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    return months[dateInt % 100 - 1];
  }

  getYearPart(dateInt) {
    return Math.floor(dateInt / 100);
  }

  public onBack() {
    this.location.back();
  }

  public onDownMonth() {
    this.paymentSearch.date.setMonth((this.paymentSearch.date.getMonth() - 1));
    this.monthStr = this.datePipe.transform(this.paymentSearch.date, 'MMM yyyy');
    this.paymentSearch.month = Number(this.datePipe.transform(this.paymentSearch.date, 'yyyyMM'));

    this.filterPayments();
  }

  public onUpMonth() {
    this.paymentSearch.date.setMonth((this.paymentSearch.date.getMonth() + 1));
    this.monthStr = this.datePipe.transform(this.paymentSearch.date, 'MMM yyyy');
    this.paymentSearch.month = Number(this.datePipe.transform(this.paymentSearch.date, 'yyyyMM'));

    this.filterPayments();
  }

  public filterPayments() {
    if (this.paymentId !== 0) {
      this.payments = this.payments.filter(p => p.id == this.paymentId);
    }
    this.payment = this.payments[0];
  }
}
