import { ChangeDetectorRef, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { Subject, finalize } from 'rxjs';
import { AuthService } from  '../authService/auth.service';

@Injectable({
  providedIn: 'root'
})
export class IdleService {
  public countdown?: number = null;

  private idleStateSubject = new Subject<string>();
  idleState$ = this.idleStateSubject.asObservable();
  private cd: ChangeDetectorRef;
  private idleStartTime?: number;
  private isWatching: boolean = false;

  constructor(
    private idle: Idle,
    private _auth: AuthService,
    private _router: Router
  ) {
    this.idle.setIdle(10);
    this.idle.setTimeout(540);
    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    this.idle.onIdleStart.subscribe(() => {
      this.idleStateSubject.next('IDLE');
      console.info('User is IDLE');
      this.idleStartTime = Date.now();
    });

    this.idle.onIdleEnd.subscribe(() => {
      this.idleStateSubject.next('NOT_IDLE');
      console.info('User is NOT_IDLE');
      this.countdown = null;
    });

    this.idle.onTimeout.subscribe(() => {
      this.idleStateSubject.next('TIMED_OUT');
      console.info('User TIMED OUT');
      this.removeMsalInteraction();
      this._auth
        .logOut()
        .pipe(
          finalize(() => {
            this._router.navigate(["/login"]);
          })
        )
        .subscribe(() => {
          console.info("Logged out");
        });
        localStorage.clear();
        this.cd.detectChanges();

    });
  }

  startWatching() {
    this.idle.watch();
  }

  isRunning() {
    this.idle.isRunning()
  }

  getRemainingIdleTime() {
    if (this.idleStartTime) {
      const elapsedTime = Date.now() - this.idleStartTime;
      const remainingTime =  600000 - elapsedTime;
      return remainingTime > 0 ? remainingTime : 0;
    }
    return 0;
  }


  private removeMsalInteraction() {
    const ending = "active-account";

    for (let i = 0; i < localStorage.length; i++) {
      const key = localStorage.key(i);
      if (key.endsWith(ending)) {
        localStorage.removeItem(key);
        break;
      }
    }
  }
}
