<div id="widgets_bank">
  <div class="page_container">
    <div id="widgets_inner">
      <div class="col_group offset_margin">
        <div class="col_item width_3_4">
          <app-new-payments></app-new-payments>
        </div>

        <div class="col_item width_3_4">
          <h3 class="module_title">At a Glance</h3>

          <div class="widget_group fixed_height">
            <!-- Clients -->
            <div class="widget_container" id="widget_clients">
              <div class="widget_inner background_grey">
                <div class="widget_header small">
                  <div class="widget_title">
                    <h3>Accounts</h3>
                  </div>
                  <div class="widget_actions"></div>
                </div>
                <div class="widget_content">
                  <div class="widget_content_icon wide">
                    <div class="widget_content_icon_main">
                      <span class="icon_client_shake"></span>
                    </div>
                    <div class="widget_content_icon_shadow">
                      <span class="icon_client_shake_shadow"></span>
                    </div>
                  </div>
                  <div class="widget_data_row">
                    <div class="widget_data_value">{{ accounts.length }}</div>
                    <div class="active-status">
                      <div class="widget_data_label">
                        <a routerLink="/accounts">{{ activeAccountCount }} Active</a>
                      </div>
                      <div class="widget_data_label">
                        <a routerLink="/accounts">{{ inactiveAccountCount }} Inactive</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- Operators -->
            <div class="widget_container" id="widget_operators">
              <div class="widget_inner background_grey">
                <div class="widget_header small">
                  <div class="widget_title">
                    <h3>Operators</h3>
                  </div>
                  <div class="widget_actions"></div>
                </div>
                <div class="widget_content">
                  <div class="widget_content_icon">
                    <div class="widget_content_icon_main">
                      <span class="icon_operators"></span>
                    </div>
                    <div class="widget_content_icon_shadow">
                      <span class="icon_operators_shadow"></span>
                    </div>
                  </div>
                  <div class="widget_data_row">
                    <div class="widget_data_value">{{ operatorCount }}</div>
                  </div>
                </div>
              </div>
            </div>

            <!-- Wells -->
            <div class="widget_container" id="widget_wells">
              <div class="widget_inner background_grey">
                <div class="widget_header small">
                  <div class="widget_title">
                    <h3>Wells</h3>
                  </div>
                  <div class="widget_actions"></div>
                </div>
                <div class="widget_content">
                  <div class="widget_content_icon">
                    <div class="widget_content_icon_main" style="margin-left: 1px;">
                      <span class="icon_wells"></span>
                    </div>
                    <div class="widget_content_icon_shadow" style="margin-left: 10px;">
                      <span class="icon_wells_shadow"></span>
                    </div>
                  </div>
                  <div class="widget_data_row no_padding">
                    <div class="widget_data_value">{{ wellCount }}</div>
                    <!-- <div class="widget_data_label">
                      <a href="#">3 Operator Changes</a>
                    </div>
                    <div class="widget_data_helper">Last 30 days</div> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="mro_markets" *ngIf="crudeOilPrice || naturalGasPrice">
        <div class="mobile_container">
          <div id="mro_markets_inner">
            <h1 class="mro_title">Markets</h1>

            <div class="mro_content_inner">
              <div class="col_group centered">
                <div class="col_item">
                  <div class="market_object" id="market_oil">
                    <div class="market_icon">
                      <div class="market_icon_main">
                        <span class="icon_production"></span>
                      </div>
                      <div class="market_icon_shadow">
                        <span class="icon_production_shadow"></span>
                      </div>
                    </div>
                    <div class="market_price">${{crudeOilPrice}}</div>
                    <div class="market_change">
                      <span class="change_value {{crudeOilPriceChanged < 0 ? 'down' : 'up'}}">
                        {{ ( crudeOilPriceChanged < 0 ? crudeOilPriceChanged*(-1) : crudeOilPriceChanged )
                          | number: "1.2-2":"en-US" }}c
                      </span>
                    </div>
                    <div class="market_title">Crude Oil</div>
                    <span class="font-italic small">({{crudeOilPriceDate | date: 'dd MMM yyyy'}})</span>
                  </div>
                </div>
                <div class="col_item">
                  <div class="market_object" id="market_gas">
                    <div class="market_icon">
                      <div class="market_icon_main">
                        <span class="icon_gas"></span>
                      </div>
                      <div class="market_icon_shadow">
                        <span class="icon_gas_shadow"></span>
                      </div>
                    </div>
                    <div class="market_price">${{naturalGasPrice | number: "1.2-2":"en-US"}}</div>
                    <div class="market_change">
                      <span class="change_value {{naturalGasPriceChanged < 0 ? 'down' : 'up'}}">
                        {{ ( naturalGasPriceChanged < 0 ? naturalGasPriceChanged*(-1) : naturalGasPriceChanged )
                          | number: "1.2-2":"en-US" }}c
                      </span>
                    </div>
                    <div class="market_title">Gas</div>
                    <span class="font-italic small">({{naturalGasPriceDate | date: 'dd MMM yyyy'}})</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>




