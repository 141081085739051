<div id="card-container">
  <div class="card-section">
    <div class="card-logo">
      <img alt="logo" class="logo_image" src="../assets/images/b4e_logo_type.png">
    </div>
    <div class="card-title">
      You have been logged out of the Account Manager Portal
    </div>
    <!-- <div class="card-content">
      <button class="adb2c-loginbutton" (click)="userAuth()">Login</button>
    </div> -->
  </div>
</div>
