import { LovingTx, KarnesTx, counties } from './counties';
import { Property } from "../model/property";
import { randomDateMinOrMax } from './helpers/randomDateHelper';
import { getRandomInt } from './helpers/randomNumbersHelper';
import { wells } from './wells';

const firstProperty: Property = {
  id: 826,
  propertyNo: 'HAVERFORD-001',
  name: 'SEC 4, BLOCK C-27, PSLS, A-771, LOVING COUNTY, TX',
  description: 'SECTION 4, BLOCK C-27, PUBLIC SCHOOL LANDS SURVEY, A-771, LOVING COUNTY, TEXAS',
  geographicalData: {
    county: LovingTx,
    section: '4',
    block: 'C-27',
    surveyName: 'PUBLIC SCHOOL LANDS SURVEY',
    abstractData: 'A-771',
    grossAcreage: 21,
    netAcreage: 21,
    insertBy: 1,
    insertDate: randomDateMinOrMax(2022, 1999, true),
    modifyBy: 1,
    modifyDate: randomDateMinOrMax(2022, new Date().getFullYear(), true),
    sectionNo: '',
    township: '',
    range: ''
  },
  effectiveFrom: randomDateMinOrMax(2022, 1999, true),
  wells: [
    wells[0]
  ],
  operatorList: 'Operator 1, Operator 2'
};

const secondProperty: Property = {
  id: 827,
  propertyNo: 'HAVERFORD-214',
  name: 'KARNES, TX - DOC UNIT (FPM - HAVERFORD)',
  description: '',
  geographicalData: {
    county: KarnesTx,
    section: null,
    block: null,
    surveyName: null,
    abstractData: null,
    grossAcreage: null,
    netAcreage: null,
    insertBy: 1,
    insertDate: randomDateMinOrMax(2022, 1999, true),
    modifyBy: 1,
    modifyDate: randomDateMinOrMax(2022, new Date().getFullYear(), true),
    sectionNo: '',
    township: '',
    range: ''
  },
  effectiveFrom: randomDateMinOrMax(2022, 1999, true),
  wells: [
    wells[1]
  ],
  operatorList: 'Operator 1'
};

const thirdProperty: Property = {
  id: 829,
  propertyNo: 'HEARTLAND-001',
  name: 'SEC 37, BLOCK 38, T-1-S, T&P RR CO, MIDLAND, TX (FPM - HEARTLAND)',
  description: 'S/2 SE/4 SECTION 37, BLOCK 38, T-1-S, T&P RR CO SURVEY, MIDLAND COUNTY, TEXAS, CONTAINING 80 ACRES, MORE OR LESS',
  geographicalData: {
    county: counties.find(county => county.name === 'Midland County'),
    section: '37',
    block: '38',
    surveyName: 'T&P RR CO SURVEY',
    abstractData: 'T-1-S',
    grossAcreage: 80,
    netAcreage: 80,
    insertBy: 1,
    insertDate: randomDateMinOrMax(2022, 1999, true),
    modifyBy: 1,
    modifyDate: randomDateMinOrMax(2022, new Date().getFullYear(), true),
    sectionNo: '',
    township: '',
    range: ''
  },
  effectiveFrom: randomDateMinOrMax(2022, 1999, true),
  wells: [
    wells[2]
  ],
  operatorList: 'Operator 2'
};

const fourthProperty: Property = {
  id: 830,
  propertyNo: 'HEARTLAND-002',
  name: 'UPTON COUNTY, TX - 640 ACRES, A-118 & A-232 (FPM - HEARTLAND)',
  description: '',
  geographicalData: {
    county: counties[getRandomInt(0, 49)],
    block: null,
    surveyName: null,
    abstractData: null,
    grossAcreage: null,
    netAcreage: null,
    insertBy: 1,
    insertDate: randomDateMinOrMax(2022, 1999, true),
    modifyBy: 1,
    modifyDate: randomDateMinOrMax(2022, new Date().getFullYear(), true),
    sectionNo: '',
    township: '',
    range: '',
    section: ''
  },
  effectiveFrom: randomDateMinOrMax(2022, 1999, true),
  wells: [
    wells[3]
  ],
  operatorList: 'Operator 1, Operator 2, Operator 3'
};

const fifthProperty: Property = {
  id: 831,
  propertyNo: 'HEARTLAND-004',
  name: '13-21S-35W NW/4, KEARNY, KS (FPM - HEARTLAND)',
  description: 'NW/4 SECTION 13, TOWNSHIP 21 SOUTH, RANGE 35 WEST, KEARNY COUNTY, KANSAS',
  geographicalData: {
    county: counties.find(county => county.name === 'Kearny County'),
    section: '13',
    block: null,
    surveyName: null,
    abstractData: null,
    grossAcreage: null,
    netAcreage: null,
    insertBy: 1,
    insertDate: randomDateMinOrMax(2022, 1999, true),
    modifyBy: 1,
    modifyDate: randomDateMinOrMax(2022, new Date().getFullYear(), true),
    sectionNo: '',
    township: '21 SOUTH',
    range: '35 WEST'
  },
  effectiveFrom: randomDateMinOrMax(2022, 1999, true),
  wells: [
    wells[4]
  ],
  operatorList: 'Operator 1, Operator 2'
};

const sixthProperty: Property = {
  id: 832,
  propertyNo: 'HEARTLAND-006',
  name: '656.0995 ACRES, ADDAX HUNTER UNIT, A-52 , W STRODE SVY, LAVACA, TX (FPM - HEARTLAND)',
  description: `656.0995 ACRES, MORE OR LESS, MORE SPECIFICALLY DESCRIBED IN THAT CERTAIN UNIT DESIGNATION, DATED JAN 2, 2013,
                RECORDED IN VOL 610, PG 740 OF THE OFFICIAL RECORDS OF LAVACA COUNTY, TEXAS, TOGETHER WITH ANY AMENDMENTS AND REVISIONS THERETO.

                67.5 acres of land, more or less, out of the Wm. Strode Survey, A-52, Lavaca County, Texas and being the same land described
                in that certain Deed dated March 7, 1946 from V. H. Houston and wife, Edith Houston to Oscar Marburger, recorded in Volume 153,
                Page 602, Deeed Records, Lavaca County, Texas`,
  geographicalData: {
    county: counties.find(county => county.name === 'Lavaca County'),
    section: '',
    block: '',
    surveyName: '',
    abstractData: 'A-52',
    grossAcreage: 656.0995,
    netAcreage: 67.5,
    insertBy: 1,
    insertDate: randomDateMinOrMax(2022, 1999, true),
    modifyBy: 1,
    modifyDate: randomDateMinOrMax(2022, new Date().getFullYear(), true),
    sectionNo: '',
    township: '',
    range: ''
  },
  effectiveFrom: randomDateMinOrMax(2022, 1999, true),
  wells: [
    wells[5]
  ],
  operatorList: 'Operator 3'
};

const seventhProperty: Property = {
  id: 833,
  propertyNo: 'HEARTLAND-007',
  name: '26-10N-12W S/2 SW/4, CLEBURNE, AR',
  description: 'S/2 SW/4 SECTION 26, TOWNSHIP 10 NORTH, RANGE 12 WEST, CLEBURNE COUNTY, ARKANSAS',
  geographicalData: {
    county: counties.find(county => county.name === 'Cleburne County'),
    section: '26',
    block: null,
    surveyName: null,
    abstractData: null,
    grossAcreage: null,
    netAcreage: null,
    insertBy: 1,
    insertDate: randomDateMinOrMax(2022, 1999, true),
    modifyBy: 1,
    modifyDate: randomDateMinOrMax(2022, new Date().getFullYear(), true),
    sectionNo: '',
    township: '10 NORTH',
    range: '12 WEST'
  },
  effectiveFrom: randomDateMinOrMax(2022, 1999, true),
  wells: [
    wells[6]
  ],
  operatorList: 'Operator 3, Operator 4'
};

const eighthProperty: Property = {
  id: 834,
  propertyNo: 'HEARTLAND-009',
  name: '7-8N-13W, FAULKNER, AR',
  description: 'ALL OF SECTION 7, TOWNSHIP 8 NORTH, RANGE 13 WEST, FAULKNER COUNTY, ARKANSAS',
  geographicalData: {
    county: counties.find(county => county.name === 'Faulkner County'),
    section: '7',
    block: null,
    surveyName: null,
    abstractData: null,
    grossAcreage: null,
    netAcreage: null,
    insertBy: 1,
    insertDate: randomDateMinOrMax(2022, 1999, true),
    modifyBy: 1,
    modifyDate: randomDateMinOrMax(2022, new Date().getFullYear(), true),
    sectionNo: '',
    township: '8 NORTH',
    range: '13 WEST'
  },
  effectiveFrom: randomDateMinOrMax(2022, 1999, true),
  wells: [
    wells[7]
  ],
  operatorList: 'Operator 1, Operator 4'
};

const ninthProperty: Property = {
  id: 835,
  propertyNo: 'HEARTLAND-010',
  name: '6-8N-13W SW/4 NW/4, NW/4 SW/4, FAULKNER, AR',
  description: 'SW/4 NW/4 AND NW/4 SW/4 SECTION 6, TOWNSHIP 8 NORTH, RANGE 13 WEST, FAULKNER COUNTY, ARKANSAS',
  geographicalData: {
    county: counties.find(county => county.name === 'Faulkner County'),
    section: '6',
    block: null,
    surveyName: null,
    abstractData: null,
    grossAcreage: null,
    netAcreage: null,
    insertBy: 1,
    insertDate: randomDateMinOrMax(2022, 1999, true),
    modifyBy: 1,
    modifyDate: randomDateMinOrMax(2022, new Date().getFullYear(), true),
    sectionNo: '',
    township: '8 NORTH',
    range: '13 WEST'
  },
  effectiveFrom: randomDateMinOrMax(2022, 1999, true),
  wells: [
    wells[8]
  ],
  operatorList: 'Operator 3, Operator 4'
};

const tenthProperty: Property = {
  id: 836,
  propertyNo: 'HEARTLAND-011',
  name: '5-8N-13W S/2 NW/4, NW/4 SE/4, FAULKNER, AR',
  description: 'S/2 NW/4 AND NW/4 SE/4, SECTION 5, TOWNSHIP 8 NORTH, RANGE 13 WEST, FAULKNER COUNTY, ARKANSAS',
  geographicalData: {
    county: counties.find(county => county.name === 'Faulkner County'),
    section: '5',
    block: null,
    surveyName: null,
    abstractData: null,
    grossAcreage: null,
    netAcreage: null,
    insertBy: 1,
    insertDate: randomDateMinOrMax(2022, 1999, true),
    modifyBy: 1,
    modifyDate: randomDateMinOrMax(2022, new Date().getFullYear(), true),
    sectionNo: '',
    township: '8 NORTH',
    range: '13 WEST'
  },
  effectiveFrom: randomDateMinOrMax(2022, 1999, true),
  wells: [
    wells[9]
  ],
  operatorList: 'Operator 2, Operator 4'
};


const eleventhProperty: Property = {
  id: 837,
  propertyNo: 'HEARTLAND-012',
  name: '4-8N-13W E/2 NW/4, E/2 SE/4 SW/4, FAULKNER, AR',
  description: 'E/2 NW/4 AND E/2 SE/4 SW/4, SECTION 4, TOWNSHIP 8 NORTH, RANGE 13 WEST, FAULKNER COUNTY, ARKANSAS',
  geographicalData: {
    county: counties.find(county => county.name === 'Faulkner County'),
    section: '4',
    block: null,
    surveyName: null,
    abstractData: null,
    grossAcreage: null,
    netAcreage: null,
    insertBy: 1,
    insertDate: randomDateMinOrMax(2022, 1999, true),
    modifyBy: 1,
    modifyDate: randomDateMinOrMax(2022, new Date().getFullYear(), true),
    sectionNo: '',
    township: '8 NORTH',
    range: '13 WEST'
  },
  effectiveFrom: randomDateMinOrMax(2022, 1999, true),
  wells: [
    wells[10]
  ],
  operatorList: 'Operator 1, Operator 2'
};

const twelfthProperty: Property = {
  id: 838,
  propertyNo: 'HEARTLAND-013',
  name: '14-8N-12W N/2 SE/4, FAULKNER, AR',
  description: 'N/2 SE/4 LESS AND EXCEPT THE SOUTH 115 YARDS OF THE WEST 213 YARDS OF THE NW/4 SE/4, SECTION 14, TOWNSHIP 8 NORTH, RANGE 12 WEST, FAULKNER COUNTY, ARKANSAS',
  geographicalData: {
    county: counties.find(county => county.name === 'Faulkner County'),
    section: '14',
    block: null,
    surveyName: null,
    abstractData: null,
    grossAcreage: null,
    netAcreage: null,
    insertBy: 1,
    insertDate: randomDateMinOrMax(2022, 1999, true),
    modifyBy: 1,
    modifyDate: randomDateMinOrMax(2022, new Date().getFullYear(), true),
    sectionNo: '',
    township: '8 NORTH',
    range: '12 WEST'
  },
  effectiveFrom: randomDateMinOrMax(2022, 1999, true),
  wells: [
    wells[11]
  ],
  operatorList: 'Operator 1, Operator 2'
};

const thirteenthProperty: Property = {
  id: 839,
  propertyNo: 'HEARTLAND-014',
  name: '17-8N-11W SW/4 NW/4, NE/4 SW/4, FAULKNER, AR',
  description: 'SW/4 NW/4 AND NE/4 SW/4, SECTION 17, TOWNSHIP 8 NORTH, RANGE 11 WEST, FAULKNER COUNTY, ARKANSAS',
  geographicalData: {
    county: counties.find(county => county.name === 'Faulkner County'),
    section: '17',
    block: null,
    surveyName: null,
    abstractData: null,
    grossAcreage: null,
    netAcreage: null,
    insertBy: 1,
    insertDate: randomDateMinOrMax(2022, 1999, true),
    modifyBy: 1,
    modifyDate: randomDateMinOrMax(2022, new Date().getFullYear(), true),
    sectionNo: '',
    township: '8 NORTH',
    range: '11 WEST'
  },
  effectiveFrom: randomDateMinOrMax(2022, 1999, true),
  wells: [
    wells[12]
  ],
  operatorList: 'Operator 1, Operator 2'
};

const fourteenthProperty: Property = {
  id: 840,
  propertyNo: 'HEARTLAND-016',
  name: '24-9N-15W E/2 SE/4, CONWAY, AR',
  description: 'E/2 SE/4 SECTION 24, TOWNSHIP 9 NORTH, RANGE 15 WEST, CONWAY COUNTY, ARKANSAS EXCEPT 2 ACRES IN THE NE CORNER THEREOF CONVEYED FOR A SCHOOL DESCRIBED AS FOLLOWS:  BEGIN AT THE NE CORNER OF THE NE/4 SE/4 AND RUN WEST 70 YARDS; THENCE SOUTH 140 YARDS; THENCE EAST 70 YARDS, THENCE NORTH 140 YARDS TO THE POINT OF BEGINNING.',
  geographicalData: {
    county: counties.find(county => county.name === 'Conway County'),
    section: '24',
    block: null,
    surveyName: null,
    abstractData: null,
    grossAcreage: null,
    netAcreage: null,
    insertBy: 1,
    insertDate: randomDateMinOrMax(2022, 1999, true),
    modifyBy: 1,
    modifyDate: randomDateMinOrMax(2022, new Date().getFullYear(), true),
    sectionNo: '',
    township: '9 NORTH',
    range: '15 WEST'
  },
  effectiveFrom: randomDateMinOrMax(2022, 1999, true),
  wells: [
    wells[13]
  ],
  operatorList: 'Operator 1, Operator 2'
};

const fifteenthProperty: Property = {
  id: 841,
  propertyNo: 'HEARTLAND-017',
  name: '30-9N-14W N/2 NE/4, CONWAY, AR',
  description: 'N/2 NE/4 SECTION 30, TOWNSHIP 9 NORTH, RANGE 14 WEST, CONWAY COUNTY, ARKANSAS',
  geographicalData: {
    county: counties.find(county => county.name === 'Conway County'),
    section: '30',
    block: null,
    surveyName: null,
    abstractData: null,
    grossAcreage: null,
    netAcreage: null,
    insertBy: 1,
    insertDate: randomDateMinOrMax(2022, 1999, true),
    modifyBy: 1,
    modifyDate: randomDateMinOrMax(2022, new Date().getFullYear(), true),
    sectionNo: '',
    township: '9 NORTH',
    range: '14 WEST'
  },
  effectiveFrom: randomDateMinOrMax(2022, 1999, true),
  wells: [
    wells[14]
  ],
  operatorList: 'Operator 1, Operator 2'
};

const sixteenthProperty: Property = {
  id: 842,
  propertyNo: 'HEARTLAND-018',
  name: '19-9N-14W S/2 SW/4 SE/4, CONWAY, AR',
  description: 'S/2 SW/4 SE/4, SECTION 19, TOWNSHIP 9 NORTH, RANGE 14 WEST, CONWAY COUNTY, ARKANSAS',
  geographicalData: {
    county: counties.find(county => county.name === 'Conway County'),
    section: '19',
    block: null,
    surveyName: null,
    abstractData: null,
    grossAcreage: null,
    netAcreage: null,
    insertBy: 1,
    insertDate: randomDateMinOrMax(2022, 1999, true),
    modifyBy: 1,
    modifyDate: randomDateMinOrMax(2022, new Date().getFullYear(), true),
    sectionNo: '',
    township: '9 NORTH',
    range: '14 WEST'
  },
  effectiveFrom: randomDateMinOrMax(2022, 1999, true),
  wells: [
    wells[15]
  ],
  operatorList: 'Operator 1, Operator 2'
};

const seventeenthProperty: Property = {
  id: 843,
  propertyNo: 'HEARTLAND-008',
  name: '15-7N-16W, CONWAY, AR',
  description: 'ALL OF SECTION 15, TOWNSHIP 7 NORTH, RANGE 16 WEST, CONWAY COUNTY, ARKANSAS',
  geographicalData: {
    county: counties.find(county => county.name === 'Conway County'),
    section: '15',
    block: null,
    surveyName: null,
    abstractData: null,
    grossAcreage: null,
    netAcreage: null,
    insertBy: 1,
    insertDate: randomDateMinOrMax(2022, 1999, true),
    modifyBy: 1,
    modifyDate: randomDateMinOrMax(2022, new Date().getFullYear(), true),
    sectionNo: '',
    township: '7 NORTH',
    range: '16 WEST'
  },
  effectiveFrom: randomDateMinOrMax(2022, 1999, true),
  wells: [
    wells[16]
  ],
  operatorList: 'Operator 1, Operator 2'
};

const eighteenthProperty: Property = {
  id: 844,
  propertyNo: 'HEARTLAND-020',
  name: 'KARNES, TX - MAURER-MCFARLAND UNIT (FPM - HEARTLAND)',
  description: '',
  geographicalData: {
    county: KarnesTx,
    section: null,
    block: null,
    surveyName: null,
    abstractData: null,
    grossAcreage: null,
    netAcreage: null,
    insertBy: 1,
    insertDate: randomDateMinOrMax(2022, 1999, true),
    modifyBy: 1,
    modifyDate: randomDateMinOrMax(2022, new Date().getFullYear(), true),
    sectionNo: '',
    township: '',
    range: ''
  },
  effectiveFrom: randomDateMinOrMax(2022, 1999, true),
  wells: [
    wells[17]
  ],
  operatorList: 'Operator 1, Operator 2'
};

export const properties: Property[] = [
  firstProperty,
  secondProperty,
  thirdProperty,
  fourthProperty,
  fifthProperty,
  sixthProperty,
  seventhProperty,
  eighthProperty,
  ninthProperty,
  tenthProperty,
  eleventhProperty,
  twelfthProperty,
  thirteenthProperty,
  fourteenthProperty,
  fifteenthProperty,
  sixteenthProperty,
  seventeenthProperty,
  eighteenthProperty
];
