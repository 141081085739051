import { Injectable } from '@angular/core';
import { PropertyService } from './property.service';
import { Observable } from 'rxjs';
import { Property } from '../../../model/property';
import { HttpService } from '../httpService/http.service';

@Injectable({
  providedIn: 'root'
})
export class HttpPropertyService extends PropertyService {

  constructor(
    private httpService: HttpService
  ) {
    super();
  }

  getAccountManagerProperties(): Observable<Property[]> {
    return this.httpService.get(`/getAccountManagerProperties`, true);
  }

  getAccountProperties(accountId: number): Observable<Property[]> {
    return this.httpService.get(`/getAccountProperties?accountId=${accountId}`, true);
  }
}
