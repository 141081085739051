import { Injectable } from '@angular/core';
import { ProductService } from './product.service';
import { Observable } from 'rxjs';
import { Product } from '../../../model/product';
import { HttpService } from '../httpService/http.service';

@Injectable({
  providedIn: 'root'
})
export class HttpProductService extends ProductService {


  constructor(
    private httpService: HttpService
  ) {
    super();
  }

  getAccountProducts(accountId: number): Observable<Product[]> {
    const url = `/getAccountProducts?accountId=${accountId}`;
    return this.httpService.get(url, true);
  }
  getAccountManagerProducts(): Observable<Product[]> {
    const url = `/getAccountManagerProducts`;
    return this.httpService.get(url, true);
  }


}
