<div id="content">
  <div id="pathway">
    <div class="page_container">
      <div id="pathway_inner">
        <div class="pathway_left">
          <ul>
            <li>
              <button
                type="button"
                class="btn_normal btn_inverse_color btn_icon_only btn_no_border btn_no_padding"
                id="btn_back"
                title="Back"
                (click)="onBack()"
              >
                <span class="btn_icon"><span class="icon_back"></span></span>
                <span class="btn_text">Back</span>
              </button>
            </li>
            <li>
              <div class="pathway">
                <ul>
                  <li><span>Properties</span></li>
                </ul>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>

  </div>

  <div class="page_container">
    <div id="content_inner">
      <!-- Action Bar -->
      <div class="content_action_bar">
        <div class="action_bar_left">
          <ul>
            <li>
              <fieldset class="form_row no_padding clearfix">
                <legend class="hidden_legend">Filter Rows</legend>
              </fieldset>
            </li>
          </ul>
        </div>
        <form [formGroup]="propertyForm">
          <div class="action_bar_right">
            <ul>
              <li>
                <div class="form_row no_padding">
                  <div class="form_label hidden_label">
                    <label for="filter_search_table">Search Table</label>
                  </div>
                  <div class="form_input">
                    <input
                      #filter_search_table
                      class="inputbox searchbox"
                      id="filter_search_table"
                      name="filter_search_table"
                      placeholder="Search Property No. or Name…"
                      (keyup)="doSearch()"
                      formControlName="searchStr"
                    />
                  </div>
                </div>
              </li>
              <li>
                <div class="form_row no_padding">
                  <div class="form_label hidden_label">
                    <label for="filter_property_states">Property State</label>
                  </div>
                  <div class="form_input">
                    <select
                      title="Property State"
                      name="state"
                      id="state"
                      class="inputbox"
                      formControlName="state"
                      (change)="onStateChange()"
                    >
                      <option value="-1">All States</option>
                      <option *ngFor="let state of states; trackBy: trackByStateId" value="{{ state.id }}" >
                        {{ state.name }}
                        <p *ngIf="state">({{ state.abbr }})</p>
                      </option>
                    </select>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </form>
      </div>

      <!-- Body Content -->
      <div class="table-container">
        <table class="properties-table">
          <thead>
            <tr>
              <th class="property-name">Name</th>
              <th class="property-description">Description</th>
              <th class="property-state">State</th>
              <th class="property-county">County</th>
              <th class="property-operator">Operator</th>
            </tr>
          </thead>
          <tbody>
            <tr class="pointer" *ngFor="let property of properties" (click)="navigateToPropertyDetail(property.id)">
              <td matTooltip="{{ property.name }}" matTooltipPosition="above">{{ property?.name }}</td>
              <td matTooltip="{{ property.description }}" matTooltipPosition="above">{{ property?.description }}</td>
              <td matTooltip="{{ property.geographicalData?.county?.state?.name }}" matTooltipPosition="above">{{ property?.geographicalData?.county?.state?.name }}</td>
              <td matTooltip="{{ property.geographicalData?.county?.name }}" matTooltipPosition="above">{{ property?.geographicalData?.county?.name }}</td>
              <td matTooltipPosition="above" matTooltip="{{ getUniqueOperators(property.wells).join(', ') }}">
                <ng-container *ngIf="getUniqueOperators(property.wells).length > 0">
                  {{ getUniqueOperators(property.wells).join(', ') }}
                  <div matTooltip="let operator of getUniqueOperators(property.wells)">
                    <span *ngFor="let operator of getUniqueOperators(property.wells)">

                    </span>
                  </div>
                </ng-container>
              </td>
              <!-- <td>{{ property.purchaserName }}</td> -->
            </tr>
          </tbody>
        </table>
      </div>


      <!-- Body Footer -->
      <div class="content_footer">
        <app-export-footer [properties]="properties"></app-export-footer>
      </div>
    </div>
  </div>
</div>
