import { UniqueOperatorsPipe } from './../../pipes/uniqueOpertors';
import { Component, Inject, OnInit } from '@angular/core';
import * as GC from '@grapecity/spread-sheets';
import { CommonModule, DatePipe, Location } from '@angular/common';
import { ReactiveFormsModule, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Property } from '../../model/property';
import { PropertySearch } from '../../model/property_search';
import { Router } from '@angular/router';
import { PropertyService } from '../../core/services/property/property.service';
import { saveAs } from 'file-saver';
import { Subject } from 'rxjs';
import { ExportFooterComponent } from '../export-footer/export-footer.component';
import { StorageService } from '../../core/services/storage/storage-service.service';
import { MatTooltipModule } from '@angular/material/tooltip';


@Component({
  selector: 'app-properties',
  standalone: true,
  templateUrl: './properties.component.html',
  styleUrls: ['./properties.component.css'],
  providers: [DatePipe],

  imports: [
    CommonModule,
    ReactiveFormsModule,
    ExportFooterComponent,
    UniqueOperatorsPipe,
    MatTooltipModule
  ]
})
export class PropertiesComponent implements OnInit {

  public sheet: GC.Spread.Sheets.Worksheet = null;
  public spread: GC.Spread.Sheets.Workbook;
  private sheetWidth: number;
  public propertyForm: UntypedFormGroup = this.fb.group({
    searchStr: [''],
    state: ['']
  });
  public lastModified: Date;
  public tabStripVisible = false;
  public spreadBackColor = 'white';
  public hostStyle = {
    height: '100%',
    width: '100%'
  };
  public data: unknown;
  private unsubscribe = new Subject<void>();
  public propertyDescColumn = 0;
  public propertyNoColumn = 1;
  public propertyNameColumn = 2;
  public stateColumn = 3;
  public countyColumn = 4;
  public operatorColumn = 5;
  public purchaserColumn = 6;
  public blockChainColumn = 7;
  public blockChainTransIdColumn = 8;
  public gridColCount = 9;
  public allProperties: Property[] = [];
  public properties: Property[];
  public filteredProperties: Property[];

  public propertySearch: PropertySearch = {
    propertyType: 0,
    searchStr: [],
    state: null
  };

  constructor(
    @Inject(UntypedFormBuilder)
    private fb: UntypedFormBuilder,
    private propertyService: PropertyService,
    private datePipe: DatePipe,
    private location: Location,
    private router: Router,
    private storageService: StorageService,
  ) { }

  ngOnInit() {
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.has('list')) {
      this.properties = this.storageService.getCurrentAccountProperties() as unknown as Property[];
      this.allProperties = this.properties;
    } else {
      if (this.propertyService.getProperties().length != 0) {
        this.getProperties();
      } else {
        this.router.navigate(['/dashboard']);
      }
    }

    this.propertySearch;
    this.propertySearch.propertyType = 0;
    this.propertySearch.searchStr = [];
    this.propertyForm.get('state').setValue(-1);
  }

  get states() {
    return this.allProperties.reduce((uniqueStates, property) => {
      const state = property.geographicalData.county.state;
      if (state && !uniqueStates.find(s => s.id === state.id)) {
        uniqueStates.push({ name: state.name, id: state.id, abbr: state.abbr });
      }
      return uniqueStates;
    }, []);
  }
  trackByStateId(index: number, item: any) {
    return item.id;
  }

  public navigateToPropertyDetail(propertyId: number) {
    this.storageService.setCurrentPropertyId(propertyId);
    this.router.navigate(['/propertyDetail']);
  }


  public exportToCSV() {
    const csvData = this.convertToCSV(this.properties);
    const blob = new Blob([csvData], { type: 'text/csv' });
    saveAs(blob, 'properties.csv');
  }

  private convertToCSV(data: Property[]): string {
    const header = ['Description', 'Property', 'Name', 'State', 'County', 'Operator'].join(',');
    const rows = data.map(property => {

      return [
        property.description || '',
        property.propertyNo || '',
        property.name || '',
        property.geographicalData?.county?.state?.name || '',
        property.geographicalData?.county?.name || ''
      ].join(',');
    });
    return `${header}\n${rows.join('\n')}`;
  }



  getProperties(): void {
    if (!this.properties) {
      this.properties = this.propertyService.getProperties();
      this.allProperties = this.properties;
    }
  }

  transformDate(date) {
    return this.datePipe.transform(date, 'yyyy-MM-dd');
  }


  setPropertyValues(filteredProperties: Property[]): Property[] {
    if (Array.isArray(filteredProperties) && filteredProperties.length > 0) {
      filteredProperties.forEach(property => {
        property.geographicalData.county.state.name = property.geographicalData.county ? property.geographicalData.county.state ? property.geographicalData.county.state.name : '' : '';
        property.geographicalData.county.name = property.geographicalData.county ? property.geographicalData.county.name : '';
      });
    }
    return filteredProperties;
  }

  getUniqueOperators(wells: any[]): string[] {
    const uniqueOperators = new Set();
    return wells
      .filter(well => {
        const operatorName = well?.operator?.name;
        if (operatorName && !uniqueOperators.has(operatorName)) {
          uniqueOperators.add(operatorName);
          return true;
        }
        return false;
      })
      .map(well => well.operator.name);
  }

  private filter() {
    this.properties = [];
    const tempProperties = [];
    const today = new Date();
    if (Array.isArray(this.allProperties) && this.allProperties.length > 0) {
      this.allProperties.forEach((property: Property) => {
        if (property != null && Object.keys(property).length !== 0) {
          let includeProperty = true;
          if (this.propertySearch.state !== '') {
            if (this.propertySearch.state !== '-1') {
              includeProperty = (property.geographicalData.county.state.id === Number(this.propertySearch.state));
            } else {
              this.properties = this.allProperties;
            }
          }
          if (includeProperty && this.propertySearch.searchStr.length > 0) {
            this.propertySearch.searchStr.forEach((searchSubStr) => {
              includeProperty = includeProperty
                && ((property.propertyNo.indexOf(searchSubStr) !== -1)
                  || (property.name.toUpperCase().indexOf(searchSubStr.toUpperCase()) !== -1)
                  || (property.description.toUpperCase().indexOf(searchSubStr.toUpperCase()) !== -1)
                  || (property.geographicalData.county.state.name.toUpperCase().indexOf(searchSubStr.toUpperCase()) !== -1)
                  || (property.geographicalData.county.name.toUpperCase().indexOf(searchSubStr.toUpperCase()) !== -1));
            });
          }

          if (this.propertySearch.propertyType > 0 && includeProperty) {
            if (this.propertySearch.propertyType === 1) {
              if (property.effectiveFrom !== null) {
                includeProperty = property.effectiveFrom >= today;
              } else {
                includeProperty = false;
              }
            } else if (this.propertySearch.propertyType === 3) {
              includeProperty = false;
            } else {
              if (property.effectiveFrom !== null) {
                if (property.effectiveFrom <= today) {
                  includeProperty = true;
                } else {
                  includeProperty = false;
                }
              } else {
                includeProperty = true;
              }
            }
          }

          if (includeProperty) {
            tempProperties.push(property);
          }
        }
      });
    }
    this.properties = tempProperties;
    this.filteredProperties = this.properties;
  }

  public doSearch() {
    const searchInput = this.propertyForm.get('searchStr').value.toLowerCase();
    if (this.filteredProperties === null) {
      this.filteredProperties = this.allProperties;
    }
    this.properties = this.filteredProperties.filter(property =>
      property.propertyNo.toLowerCase().includes(searchInput) ||
      property.name.toLowerCase().includes(searchInput) ||
      property.description.toLowerCase().includes(searchInput) ||
      property.geographicalData.county.state.name.toLowerCase().includes(searchInput) ||
      property.geographicalData.county.name.toLowerCase().includes(searchInput)
    );

  }

  public onStateChange() {
    this.propertySearch.state = this.propertyForm.get('state').value;
    this.filter();
  }

  public btnAllProperties() {
    this.propertySearch.propertyType = 0;
    this.filter();
  }

  public btnInProgressProperties() {
    this.propertySearch.propertyType = 1;
    this.filter();
  }

  public btnActiveProperties() {
    this.propertySearch.propertyType = 2;
    this.filter();
  }

  public btnInactiveProperties() {
    this.propertySearch.propertyType = 3;
    this.filter();
  }

  public onBack() {
    this.location.back();
  }

  public workbookInit(args) {
    this.spread = args.spread as GC.Spread.Sheets.Workbook;
    this.sheet = this.spread.getActiveSheet();

    this.sheetWidth = this.sheet.getViewportWidth(1);
    console.info('SheetWidth: ', this.sheetWidth);
  }
}
