import { County } from "../model/county";
import { alabama, alaska, arizona, arkansas, california, colorado, connecticut, delaware, florida, georgia, hawaii, idaho, illinois, indiana, iowa, kansas, kentucky, louisiana, maine, maryland, massachusetts, michigan, minnesota, mississippi, missouri, montana, nebraska, nevada, newHampshire, newJersey, newMexico, newYork, northCarolina, northDakota, ohio, oklahoma, oregon, pennsylvania, rhodeIsland, southCarolina, southDakota, tennessee, texas, utah, vermont, virginia, washington, westVirginia, wisconsin, wyoming } from "./states";

export  const jeffersonAl: County = {
  id: 1,
  countyId: 1,
  name: 'Jefferson',
  state: alabama
};

export const anchorageAk: County = {
  id: 2,
  countyId: 2,
  name: 'Anchorage',
  state: alaska
};

export const maricopaAz: County = {
  id: 3,
  countyId: 3,
  name: 'Maricopa',
  state: arizona
};

export const losAngelesCa: County = {
  id: 4,
  countyId: 4,
  name: 'Los Angeles',
  state: california
};

export const denverCo: County = {
  id: 5,
  countyId: 5,
  name: 'Denver',
  state: colorado
};

export const fairfieldCt: County = {
  id: 6,
  countyId: 6,
  name: 'Fairfield',
  state: connecticut
};

export const newCastleDe: County = {
  id: 7,
  countyId: 7,
  name: 'New Castle',
  state: delaware
};

export const miamiDadeFl: County = {
  id: 8,
  countyId: 8,
  name: 'Miami-Dade',
  state: florida
};

export const adaId: County = {
  id: 9,
  countyId: 9,
  name: 'Ada',
  state: idaho
};

export const cookIl: County = {
  id: 10,
  countyId: 10,
  name: 'Cook',
  state: illinois
};

export const marionIn: County = {
  id: 11,
  countyId: 11,
  name: 'Marion',
  state: indiana
};

export const polkIa: County = {
  id: 12,
  countyId: 12,
  name: 'Polk',
  state: iowa
};

export const jeffersonKy: County = {
  id: 13,
  countyId: 13,
  name: 'Jefferson',
  state: kentucky
};

export const orleansLa: County = {
  id: 14,
  countyId: 14,
  name: 'Orleans',
  state: louisiana
};

export const hindsMs: County = {
  id: 15,
  countyId: 15,
  name: 'Hinds',
  state: mississippi
};

export const oklahomaOk: County = {
  id: 16,
  countyId: 16,
  name: 'Oklahoma',
  state: oklahoma
};

export const greenvilleSc: County = {
  id: 17,
  countyId: 17,
  name: 'Greenville',
  state: southCarolina
};

export const kingWa: County = {
  id: 18,
  countyId: 18,
  name: 'King',
  state: washington
};

export const pulaskiAr: County = {
  id: 19,
  countyId: 19,
  name: 'Pulaski',
  state: arkansas
};

export const fultonGa: County = {
  id: 20,
  countyId: 20,
  name: 'Fulton',
  state: georgia
};

export const honoluluHI: County = {
  id: 21,
  countyId: 21,
  name: 'Honolulu',
  state: hawaii
};

export const johnsonKs: County = {
  id: 22,
  countyId: 22,
  name: 'Johnson',
  state: kansas
};

export const middlesexMa: County = {
  id: 23,
  countyId: 23,
  name: 'Middlesex',
  state: massachusetts
};

export const baltimoreMd: County = {
  id: 24,
  countyId: 24,
  name: 'Baltimore',
  state: maryland
};

export const wayneMi: County = {
  id: 25,
  countyId: 25,
  name: 'Wayne',
  state: michigan
};

export const hennepinMn: County = {
  id: 26,
  countyId: 26,
  name: 'Hennepin',
  state: minnesota
};

export const stLouisMo: County = {
  id: 27,
  countyId: 27,
  name: 'St. Louis',
  state: missouri
};

export const yellowstoneMt: County = {
  id: 28,
  countyId: 28,
  name: 'Yellowstone',
  state: montana
};

export const wakeNc: County = {
  id: 29,
  countyId: 29,
  name: 'Wake',
  state: northCarolina
};

export const douglasNe: County = {
  id: 30,
  countyId: 30,
  name: 'Douglas',
  state: nebraska
};

export const cassNd: County = {
  id: 31,
  countyId: 31,
  name: 'Cass',
  state: northDakota
};

export const rockinghamNh: County = {
  id: 32,
  countyId: 32,
  name: 'Rockingham',
  state: newHampshire
};

export const bergenNj: County = {
  id: 33,
  countyId: 33,
  name: 'Bergen',
  state: newJersey
};

export const bernalilloNm: County = {
  id: 34,
  countyId: 34,
  name: 'Bernalillo',
  state: newMexico
};

export const clarkNv: County = {
  id: 35,
  countyId: 35,
  name: 'Clark',
  state: nevada
};

export const bronxNy: County = {
  id: 36,
  countyId: 36,
  name: 'Bronx',
  state: newYork
};

export const cuyahogaOh: County = {
  id: 37,
  countyId: 37,
  name: 'Cuyahoga',
  state: ohio
};

export const multnomahOr: County = {
  id: 38,
  countyId: 38,
  name: 'Multnomah',
  state: oregon
};

export const philadelphiaPa: County = {
  id: 39,
  countyId: 39,
  name: 'Philadelphia',
  state: pennsylvania
};

export const providenceRi: County = {
  id: 40,
  countyId: 40,
  name: 'Providence',
  state: rhodeIsland
};

export const charlestonWv: County = {
  id: 41,
  countyId: 41,
  name: 'Charleston',
  state: westVirginia
};

export const milwaukeeWi: County = {
  id: 42,
  countyId: 42,
  name: 'Milwaukee',
  state: wisconsin
};

export const laramieWy: County = {
  id: 43,
  countyId: 43,
  name: 'Laramie',
  state: wyoming
};

export const cumberlandMe: County = {
  id: 44,
  countyId: 44,
  name: 'Cumberland',
  state: maine
};

export const minnehahaSd: County = {
  id: 45,
  countyId: 45,
  name: 'Minnehaha',
  state: southDakota
};

export const saltLakeUt: County = {
  id: 46,
  countyId: 46,
  name: 'Salt Lake',
  state: utah
};

export const fairfaxVa: County = {
  id: 47,
  countyId: 47,
  name: 'Fairfax',
  state: virginia
};

export const chittendenVt: County = {
  id: 48,
  countyId: 48,
  name: 'Chittenden',
  state: vermont
};

export const shelbyTn: County = {
  id: 49,
  countyId: 49,
  name: 'Shelby',
  state: tennessee
};

export const harrisTx: County = {
  id: 50,
  countyId: 50,
  name: 'Harris',
  state: texas
};

export const LovingTx: County = {
  id: 51,
  countyId: 51,
  name: 'Loving County',
  state: texas
};

export const KarnesTx: County = {
  id: 52,
  countyId: 52,
  name: 'Karnes County',
  state: texas
};

export const conwayAr: County = {
  id: 53,
  countyId: 53,
  name: 'Conway County',
  state: arkansas 
};

export const faulknerAr: County = {
  id: 54,
  countyId: 54,
  name: 'Faulkner County',
  state: arkansas 
};

export const cleburneAr: County = {
  id: 55,
  countyId: 55,
  name: 'Cleburne County',
  state: arkansas 
};

export const kearnyKs: County = {
  id: 56,
  countyId: 56,
  name: 'Kearny County',
  state: kansas 
};

export const lavacaTx: County = {
  id: 57,
  countyId: 57,
  name: 'Lavaca County',
  state: texas 
};


export const midlandTx: County = {
  id: 60,
  countyId: 60,
  name: 'Midland County',
  state: texas 
};



export const counties: County[] = [
  jeffersonAl,
  anchorageAk,
  maricopaAz,
  losAngelesCa,
  denverCo,
  fairfieldCt,
  newCastleDe,
  miamiDadeFl,
  adaId,
  cookIl,
  marionIn,
  polkIa,
  jeffersonKy,
  orleansLa,
  hindsMs,
  oklahomaOk,
  greenvilleSc,
  kingWa,
  pulaskiAr,
  fultonGa,
  honoluluHI,
  johnsonKs,
  middlesexMa,
  baltimoreMd,
  wayneMi,
  hennepinMn,
  stLouisMo,
  yellowstoneMt,
  wakeNc,
  douglasNe,
  cassNd,
  rockinghamNh,
  bergenNj,
  bernalilloNm,
  clarkNv,
  bronxNy,
  cuyahogaOh,
  multnomahOr,
  philadelphiaPa,
  providenceRi,
  charlestonWv,
  milwaukeeWi,
  laramieWy,
  cumberlandMe,
  minnehahaSd,
  saltLakeUt,
  fairfaxVa,
  chittendenVt,
  shelbyTn,
  harrisTx,
  LovingTx,
  KarnesTx,
  conwayAr,
  faulknerAr,
  midlandTx,
  lavacaTx,
  kearnyKs,
  cleburneAr
];
