import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Product } from '../../../model/product';

@Injectable({
  providedIn: 'root'
})
export abstract class ProductService {

  constructor() { }

  abstract getAccountProducts(accountId: number): Observable<Product[]>;

  abstract getAccountManagerProducts(): Observable<Product[]>;
}
