import { Injectable } from '@angular/core';
import { MarketService } from './market.service';
import { Observable } from 'rxjs';
import { ResourcePriceData } from '../../../model/resourcePriceData';

@Injectable({
  providedIn: 'root'
})
export class MockMarketService extends MarketService{

  constructor() {
    super();
   }

   getMarketPrice(resource: string): Observable<ResourcePriceData> {
    throw new Error('Method not implemented.');
  }


}
