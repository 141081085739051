import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PropertyPerformance } from '../../../model/performance';

@Injectable({
  providedIn: 'root'
})
export abstract class PerformanceService {
  private performances: PropertyPerformance[] = [];

  constructor() { }

  abstract getPropertyPerformance(propertyId: number): Observable<PropertyPerformance[]>;

  getPerformances(): PropertyPerformance[] {
    return this.performances;
  }

  setPerformances(performances: PropertyPerformance[]): void {
    this.performances = performances;
  }

}
