import { Account } from "../model/account";
import { AccountSearch, AccountSearchStatus } from "../model/account_search";

export const initialAccount: Account = {
  id: 0,
  visionAccountId: "",
  name: "",
  status: "",
  number: "",
  accountOwnership: undefined,
  titles: []
};

export const initialAccountSearch: AccountSearch = {
  status: 'ALL',
  searchStr: []
};
