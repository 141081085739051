/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { EventEmitter, Injectable, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment'
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HttpService {
  @Output() httpError = new EventEmitter<HttpErrorResponse>();
  private _token = new BehaviorSubject(localStorage.getItem('token'));
  token$ = this._token.asObservable();

  private userToken = '';
  constructor(private http: HttpClient) { }

  setUserToken(token: string): void {
    localStorage.setItem('token', token);
    this.userToken = token;
    this.updateToken();
  }

  updateToken() {
    this._token.next(localStorage.getItem('token'));
  }

  get(url: string, authorized: boolean, params?: HttpParams | (Record<string, unknown>), headers?:Record<string, string | string[]>): Observable<any> {
    return this.http.get(`${environment.apiUrl}${url}`, { params: this.generateHttpParams(params), headers: this.generateHeaders(authorized, headers) });
  }

  post(url: string, data: Record<string, any>, authorized: boolean, headers?: Record<string, string | string[]> ): Observable<any> {
    return this.http.post(`${environment.apiUrl}${url}`, data, { headers: this.generateHeaders(authorized, headers) });
  }

  patch(url: string, data: Record<string, unknown>, authorized: boolean, headers?: Record<string, string | string[]>): Observable<any> {
    return this.http.patch(`${environment.apiUrl}${url}`, data, { headers: this.generateHeaders(authorized, headers) });
  }

  put(url: string, data: Record<string, unknown>, authorized: boolean, headers?: Record<string, string | string[]>): Observable<any> {
    return this.http.put(`${environment.apiUrl}${url}`, data, { headers: this.generateHeaders(authorized, headers) });
  }

  delete(url: string, authorized: boolean, headers?: Record<string, string | string[]>): Observable<any> {
    return this.http.delete(`${environment.apiUrl}${url}`, { headers: this.generateHeaders(authorized, headers) });
  }

  handleHttpError(error: HttpErrorResponse): void {
    this.httpError.emit(error);
  }

  private generateHeaders(authorized: boolean, headers?: Record<string, string | string[]>): HttpHeaders {
    const httpHeaders: HttpHeaders = headers ? new HttpHeaders(headers) : new HttpHeaders();
    return authorized ? new HttpHeaders({ 'Authorization': `Bearer ${localStorage.getItem('userToken')}` }) : httpHeaders;
  }

  private generateHttpParams(params?: HttpParams | (Record<string, unknown>)): HttpParams {
    if (params) {
      if (params instanceof HttpParams) {
        return params
      }
      return new HttpParams({ fromObject: params as { [param: string]: string | number | boolean | readonly (string | number | boolean)[] } });
    }
    return new HttpParams();
  }
}
