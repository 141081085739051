import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { NgxChartsModule } from "@swimlane/ngx-charts";
import { Component, Input, ViewEncapsulation } from "@angular/core";
import { SpinnerComponent } from "../../../spinner/spinner.component";
import { StorageService } from "../../../../core/services/storage/storage-service.service";
import { NoDataComponent } from "../../../no-data/no-data.component";

@Component({
  selector: "app-pie-chart",
  standalone: true,
  templateUrl: "./pie-chart.component.html",
  styleUrls: ["./pie-chart.component.css"],
  imports: [
    CommonModule,
    FormsModule,
    NgxChartsModule,
    SpinnerComponent,
    NoDataComponent,
  ],
  encapsulation: ViewEncapsulation.None,
})
export class PieChartComponent {
  @Input() period?: string;
  @Input() chartType: string;
  @Input() isDateRange: boolean;
  @Input() isForHomePage: boolean;

  public animations: boolean = true;
  public useGradient: boolean = true;
  public legend: boolean = true;

  constructor(private storageService: StorageService) {}

  get pieChartData() {
    switch (this.chartType) {
      case "payment":
        if (this.isForHomePage) {
          return this.storageService.getAccountManagerPaymentSummaryByPeriodDataObjects();
        } else {
          return this.storageService.getAccountPaymentSummaryByPeriodDataObjects();
        }
      case "property":
        if (this.isForHomePage) {
          return this.storageService.getAccountManagerPaymentSummaryByProperty();
        } else {
          return this.storageService.getAccountPaymentSummaryByProperty();
        }
      case "product":
        if (this.isForHomePage) {
          return this.storageService.getAccountManagerPaymentSummaryByProduct();
        } else {
          return this.storageService.getAccountPaymentSummaryByProduct();
        }
    }
  }

  get dataValid() {
    switch (this.chartType) {
      case "payment":
        if (this.isForHomePage) {
          return this.storageService.getAccountManagerPaymentSummaryByPeriodValid();
        } else {
          return this.storageService.getAccountPaymentSummaryByPeriodValid();
        }
      case "property":
        if (this.isForHomePage) {
          return this.storageService.getAccountManagerPaymentSummaryByPropertyValid();
        } else {
          return this.storageService.getAccountPaymentSummaryByPropertyValid();
        }
      case "product":
        if (this.isForHomePage) {
          return this.storageService.getAccountManagerPaymentSummaryByProductValid();
        } else {
          return this.storageService.getAccountPaymentSummaryByProductValid();
        }
    }
  }

  get loading() {
    if (this.pieChartData) {
      return this.pieChartData.length <= 0;
    } else {
      return true;
    }
  }

  get centerValue() {
    switch (this.chartType) {
      case "payment":
        return this.pieChartData.reduce((sum, data) => sum + data.value, 0);
      case "property":
        return this.pieChartData.reduce((sum, data) => sum + data.value, 0);
      case "product":
        return this.pieChartData.reduce((sum, data) => sum + data.value, 0);
    }
  }

  get centerLabel() {
    switch (this.chartType) {
      case "payment":
        return "Total Payments";
      case "property":
        return "Net Revenue";
      case "product":
        return "Net Revenue";
    }
  }
}
