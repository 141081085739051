import { DivisionOrder } from "../model/divisionOrder";
import { decks } from "./decks";
import { deductionTypes } from "./deduction_types";
import { interestTypes } from "./interestTypes";
import { products } from "./products";
import { titles } from "./titles";

export const divisionOrder1: DivisionOrder = {
  id: 1,
  operatorDivisionOrderCode: '1234',
  deck: decks[0],
  title: titles[0],
  product: products[0],
  interestType: interestTypes[0],
  decimalInterest: 0.5,
  status: 'Active',
  doDate: new Date('2021-01-01'),
  termStart: new Date('2021-01-01'),
  termEnd: new Date('2021-01-01'),
  deductionsExclusions: [
    deductionTypes[0],
  ],
  insertBy: 1,
  insertDate: new Date('2021-01-01'),
  modifyBy: 1,
  modifyDate: new Date('2021-01-01'),
};

export const divisionOrder2: DivisionOrder = {
  id: 2,
  operatorDivisionOrderCode: '5678',
  deck: decks[1],
  title: titles[1],
  product: products[1],
  interestType: interestTypes[1],
  decimalInterest: 0.5,
  status: 'Active',
  doDate: new Date('2021-02-01'),
  termStart: new Date('2021-02-01'),
  termEnd: new Date('2021-02-01'),
  deductionsExclusions: [
    deductionTypes[1],
  ],
  insertBy: 2,
  insertDate: new Date('2021-02-01'),
  modifyBy: 2,
  modifyDate: new Date('2021-02-01'),
};

export const divisionOrder3: DivisionOrder = {
  id: 3,
  operatorDivisionOrderCode: '91011',
  deck: decks[2],
  title: titles[2],
  product: products[2],
  interestType: interestTypes[2],
  decimalInterest: 0.5,
  status: 'Active',
  doDate: new Date('2021-03-01'),
  termStart: new Date('2021-03-01'),
  termEnd: new Date('2021-03-01'),
  deductionsExclusions: [
    deductionTypes[2],
  ],
  insertBy: 3,
  insertDate: new Date('2021-03-01'),
  modifyBy: 3,
  modifyDate: new Date('2021-03-01'),
};

export const divisionOrder4: DivisionOrder = {
  id: 4,
  operatorDivisionOrderCode: '121314',
  deck: decks[3],
  title: titles[3],
  product: products[3],
  interestType: interestTypes[3],
  decimalInterest: 0.5,
  status: 'Active',
  doDate: new Date('2021-04-01'),
  termStart: new Date('2021-04-01'),
  termEnd: new Date('2021-04-01'),
  deductionsExclusions: [
    deductionTypes[3],
  ],
  insertBy: 4,
  insertDate: new Date('2021-04-01'),
  modifyBy: 4,
  modifyDate: new Date('2021-04-01'),
};

export const divisionOrder5: DivisionOrder = {
  id: 5,
  operatorDivisionOrderCode: '151617',
  deck: decks[4],
  title: titles[4],
  product: products[4],
  interestType: interestTypes[4],
  decimalInterest: 0.5,
  status: 'Active',
  doDate: new Date('2021-05-01'),
  termStart: new Date('2021-05-01'),
  termEnd: new Date('2021-05-01'),
  deductionsExclusions: [
    deductionTypes[4],
  ],
  insertBy: 5,
  insertDate: new Date('2021-05-01'),
  modifyBy: 5,
  modifyDate: new Date('2021-05-01'),
};

export const divisionOrder6: DivisionOrder = {
  id: 6,
  operatorDivisionOrderCode: '181920',
  deck: decks[5],
  title: titles[5],
  product: products[5],
  interestType: interestTypes[5],
  decimalInterest: 0.5,
  status: 'Active',
  doDate: new Date('2021-06-01'),
  termStart: new Date('2021-06-01'),
  termEnd: new Date('2021-06-01'),
  deductionsExclusions: [
    deductionTypes[5],
  ],
  insertBy: 6,
  insertDate: new Date('2021-06-01'),
  modifyBy: 6,
  modifyDate: new Date('2021-06-01'),
};

export const divisionOrder7: DivisionOrder = {
  id: 7,
  operatorDivisionOrderCode: '212223',
  deck: decks[6],
  title: titles[6],
  product: products[6],
  interestType: interestTypes[0],
  decimalInterest: 0.5,
  status: 'Active',
  doDate: new Date('2021-07-01'),
  termStart: new Date('2021-07-01'),
  termEnd: new Date('2021-07-01'),
  deductionsExclusions: [
    deductionTypes[6],
  ],
  insertBy: 7,
  insertDate: new Date('2021-07-01'),
  modifyBy: 7,
  modifyDate: new Date('2021-07-01'),
};

export const divisionOrder8: DivisionOrder = {
  id: 8,
  operatorDivisionOrderCode: '242526',
  deck: decks[7],
  title: titles[7],
  product: products[7],
  interestType: interestTypes[1],
  decimalInterest: 0.5,
  status: 'Active',
  doDate: new Date('2021-08-01'),
  termStart: new Date('2021-08-01'),
  termEnd: new Date('2021-08-01'),
  deductionsExclusions: [
    deductionTypes[7],
  ],
  insertBy: 8,
  insertDate: new Date('2021-08-01'),
  modifyBy: 8,
  modifyDate: new Date('2021-08-01'),
};

export const divisionOrder9: DivisionOrder = {
  id: 9,
  operatorDivisionOrderCode: '272829',
  deck: decks[8],
  title: titles[8],
  product: products[8],
  interestType: interestTypes[2],
  decimalInterest: 0.5,
  status: 'Active',
  doDate: new Date('2021-09-01'),
  termStart: new Date('2021-09-01'),
  termEnd: new Date('2021-09-01'),
  deductionsExclusions: [
    deductionTypes[8],
  ],
  insertBy: 9,
  insertDate: new Date('2021-09-01'),
  modifyBy: 9,
  modifyDate: new Date('2021-09-01'),
};

export const divisionOrder10: DivisionOrder = {
  id: 10,
  operatorDivisionOrderCode: '303132',
  deck: decks[9],
  title: titles[9],
  product: products[9],
  interestType: interestTypes[3],
  decimalInterest: 0.5,
  status: 'Active',
  doDate: new Date('2021-10-01'),
  termStart: new Date('2021-10-01'),
  termEnd: new Date('2021-10-01'),
  deductionsExclusions: [
    deductionTypes[9],
  ],
  insertBy: 10,
  insertDate: new Date('2021-10-01'),
  modifyBy: 10,
  modifyDate: new Date('2021-10-01'),
};

export const divisionOrder11: DivisionOrder = {
  id: 11,
  operatorDivisionOrderCode: '333435',
  deck: decks[10],
  title: titles[10],
  product: products[10],
  interestType: interestTypes[4],
  decimalInterest: 0.5,
  status: 'Active',
  doDate: new Date('2021-11-01'),
  termStart: new Date('2021-11-01'),
  termEnd: new Date('2021-11-01'),
  deductionsExclusions: [
    deductionTypes[10],
  ],
  insertBy: 11,
  insertDate: new Date('2021-11-01'),
  modifyBy: 11,
  modifyDate: new Date('2021-11-01'),
};

export const divisionOrder12: DivisionOrder = {
  id: 12,
  operatorDivisionOrderCode: '363738',
  deck: decks[11],
  title: titles[11],
  product: products[11],
  interestType: interestTypes[5],
  decimalInterest: 0.5,
  status: 'Active',
  doDate: new Date('2021-12-01'),
  termStart: new Date('2021-12-01'),
  termEnd: new Date('2021-12-01'),
  deductionsExclusions: [
    deductionTypes[11],
  ],
  insertBy: 12,
  insertDate: new Date('2021-12-01'),
  modifyBy: 12,
  modifyDate: new Date('2021-12-01'),
};

export const divisionOrder13: DivisionOrder = {
  id: 13,
  operatorDivisionOrderCode: '394041',
  deck: decks[12],
  title: titles[12],
  product: products[12],
  interestType: interestTypes[0],
  decimalInterest: 0.5,
  status: 'Active',
  doDate: new Date('2022-01-01'),
  termStart: new Date('2022-01-01'),
  termEnd: new Date('2022-01-01'),
  deductionsExclusions: [
    deductionTypes[12],
  ],
  insertBy: 13,
  insertDate: new Date('2022-01-01'),
  modifyBy: 13,
  modifyDate: new Date('2022-01-01'),
};

export const divisionOrder14: DivisionOrder = {
  id: 14,
  operatorDivisionOrderCode: '424344',
  deck: decks[13],
  title: titles[13],
  product: products[13],
  interestType: interestTypes[1],
  decimalInterest: 0.5,
  status: 'Active',
  doDate: new Date('2022-02-01'),
  termStart: new Date('2022-02-01'),
  termEnd: new Date('2022-02-01'),
  deductionsExclusions: [
    deductionTypes[13],
  ],
  insertBy: 14,
  insertDate: new Date('2022-02-01'),
  modifyBy: 14,
  modifyDate: new Date('2022-02-01'),
};

export const divisionOrder15: DivisionOrder = {
  id: 15,
  operatorDivisionOrderCode: '454647',
  deck: decks[14],
  title: titles[14],
  product: products[14],
  interestType: interestTypes[2],
  decimalInterest: 0.5,
  status: 'Active',
  doDate: new Date('2022-03-01'),
  termStart: new Date('2022-03-01'),
  termEnd: new Date('2022-03-01'),
  deductionsExclusions: [
    deductionTypes[14],
  ],
  insertBy: 15,
  insertDate: new Date('2022-03-01'),
  modifyBy: 15,
  modifyDate: new Date('2022-03-01'),
};

export const divisionOrder16: DivisionOrder = {
  id: 16,
  operatorDivisionOrderCode: '484950',
  deck: decks[15],
  title: titles[15],
  product: products[15],
  interestType: interestTypes[3],
  decimalInterest: 0.5,
  status: 'Active',
  doDate: new Date('2022-04-01'),
  termStart: new Date('2022-04-01'),
  termEnd: new Date('2022-04-01'),
  deductionsExclusions: [
    deductionTypes[15],
  ],
  insertBy: 16,
  insertDate: new Date('2022-04-01'),
  modifyBy: 16,
  modifyDate: new Date('2022-04-01'),
};

export const divisionOrder17: DivisionOrder = {
  id: 17,
  operatorDivisionOrderCode: '515253',
  deck: decks[16],
  title: titles[16],
  product: products[16],
  interestType: interestTypes[4],
  decimalInterest: 0.5,
  status: 'Active',
  doDate: new Date('2022-05-01'),
  termStart: new Date('2022-05-01'),
  termEnd: new Date('2022-05-01'),
  deductionsExclusions: [
    deductionTypes[16],
  ],
  insertBy: 17,
  insertDate: new Date('2022-05-01'),
  modifyBy: 17,
  modifyDate: new Date('2022-05-01'),
};

export const divisionOrder18: DivisionOrder = {
  id: 18,
  operatorDivisionOrderCode: '545556',
  deck: decks[17],
  title: titles[17],
  product: products[17],
  interestType: interestTypes[5],
  decimalInterest: 0.5,
  status: 'Active',
  doDate: new Date('2022-06-01'),
  termStart: new Date('2022-06-01'),
  termEnd: new Date('2022-06-01'),
  deductionsExclusions: [
    deductionTypes[17],
  ],
  insertBy: 18,
  insertDate: new Date('2022-06-01'),
  modifyBy: 18,
  modifyDate: new Date('2022-06-01'),
};

export const divisionOrder19: DivisionOrder = {
  id: 19,
  operatorDivisionOrderCode: '575859',
  deck: decks[18],
  title: titles[18],
  product: products[18],
  interestType: interestTypes[0],
  decimalInterest: 0.5,
  status: 'Active',
  doDate: new Date('2022-07-01'),
  termStart: new Date('2022-07-01'),
  termEnd: new Date('2022-07-01'),
  deductionsExclusions: [
    deductionTypes[18],
  ],
  insertBy: 19,
  insertDate: new Date('2022-07-01'),
  modifyBy: 19,
  modifyDate: new Date('2022-07-01'),
};

export const divisionOrders: DivisionOrder[] = [
  divisionOrder1,
  divisionOrder2,
  divisionOrder3,
  divisionOrder4,
  divisionOrder5,
  divisionOrder6,
  divisionOrder7,
  divisionOrder8,
  divisionOrder9,
  divisionOrder10,
  divisionOrder11,
  divisionOrder12,
  divisionOrder13,
  divisionOrder14,
  divisionOrder15,
  divisionOrder16,
  divisionOrder17,
  divisionOrder18,
  divisionOrder19
];
