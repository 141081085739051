import { DataVerificationService } from './../data-verification/data-verification.service';
import { ChartDataProcessingServiceService } from './../chart-data-processing/chart-data-processing-service.service';
import { Injectable } from '@angular/core';
import { GraphAndBarChartData, PieChartData } from '../../../model/chart-data';
import { PaymentSummary } from '../../../model/payment_summary';
import { Property } from '../../../model/property';
import { Payment } from '../../../model/payment';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  private AccountPaymentSummaryByProperty: PieChartData[] = [];
  private AccountPaymentSummaryByPropertyValid: boolean;
  private AccountPaymentSummaryByProduct: PieChartData[] = [];
  private AccountPaymentSummaryByProductValid: boolean;
  private AccountPaymentSummaryByPeriod: PaymentSummary;
  private AccountPaymentSummaryByPeriodDataObjects: PieChartData[] = [];
  private AccountPaymentSummaryByPeriodValid: boolean;
  private AccountPaymentSummaryByPeriodByProperty: GraphAndBarChartData[] = [];
  private AccountPaymentSummaryByPeriodByPropertyValid: boolean;
  private AccountPaymentSummaryByPeriodByProduct: GraphAndBarChartData[] = [];
  private AccountPaymentSummaryByPeriodByProductValid: boolean;

  private accountManagerPaymentSummaryByProperty: PieChartData[] = [];
  private accountManagerPaymentSummaryByPropertyValid: boolean;
  private accountManagerPaymentSummaryByProduct: PieChartData[] = [];
  private accountManagerPaymentSummaryByProductValid: boolean;
  private accountManagerPaymentSummaryByPeriod: PaymentSummary;
  private accountManagerPaymentSummaryByPeriodDataObjects: PieChartData[] = [];
  private accountManagerPaymentSummaryByPeriodValid: boolean;
  private accountManagerPaymentSummaryByPeriodByProperty: GraphAndBarChartData[] = [];
  private accountManagerPaymentSummaryByPeriodByPropertyValid: boolean;
  private accountManagerPaymentSummaryByPeriodByProduct: GraphAndBarChartData[] = [];
  private accountManagerPaymentSummaryByPeriodByProductValid: boolean;

  public accountManagerPaymentsByPage: BehaviorSubject<Payment[][]> = new BehaviorSubject([]);
  private accountManagerPaymentsByPageNoRecords: number;

  private AccountPaymentsByPage: Payment[][] = [];
  private AccountPaymentsByPageNoRecords: number;

  public _accountManagerPaymentsByOperatorByPage = new BehaviorSubject<Payment[][]>([]);
  public accountManagerPaymentsByOperatorByPage: BehaviorSubject<Payment[][]> = new BehaviorSubject<Payment[][]>([]);
  private accountManagerPaymentsByOperatorByPageNoRecords: number;

  private accountPaymentsByOperatorByPage: Payment[][] = [];
  private accountPaymentsByOperatorByPageNoRecords: number;

  private accountManagerId: number;

  private currentAccountId: number;
  private currentAcoountProperties: Property[];
  private currentPayment: Payment;
  private currentPropertyId: number;

  private accountProperties: Property[];
  private accountManagerProperties: Property[];

  constructor(
    private chartDataProcessingServiceService: ChartDataProcessingServiceService,
    private dataVerificationService: DataVerificationService
  ) {
    this.accountManagerPaymentsByOperatorByPage = new BehaviorSubject<Payment[][]>([]);
  }

  getAccountManagerProperties(): Property[] {
    return this.accountManagerProperties;
  }

  setAccountManagerProperties(accountManagerProperties: Property[]): void {
    this.accountManagerProperties = accountManagerProperties;
  }

  getAccountProperties(): Property[] {
    return this.accountProperties;
  }

  setAccountProperties(accountProperties: Property[]): void {
    this.accountProperties = accountProperties;
  }

  getAccountManagerPaymentsByPage(index: number): Payment[] {
    return this.accountManagerPaymentsByPage[index];
  }

  getAccountManagerPaymentsByPageNoRecords(): number {
    return this.accountManagerPaymentsByPageNoRecords;
  }

  getAccountPaymentsByPageArraySize(): number {
    return this.AccountPaymentsByPage.length;
  }

  setAccountManagerPaymentsByPage(index: number, payments: Payment[]): void {
    const currentPayments = this.accountManagerPaymentsByPage.getValue();
    currentPayments[index] = payments;
    this.accountManagerPaymentsByPage.next(currentPayments);
  }

  setAccountManagerPaymentsByPageNoRecords(noRecords: number): void {
    this.accountManagerPaymentsByPageNoRecords = noRecords;
  }

  getAccountPaymentsByPage(index: number): Payment[] {
    return this.AccountPaymentsByPage[index];
  }

  getAccountPaymentsByPageNoRecords(): number {
    return this.AccountPaymentsByPageNoRecords;
  }

  setAccountPaymentsByPage(index: number, payments: Payment[]): void {
    this.AccountPaymentsByPage[index] = payments;
  }

  setAccountPaymentsByPageNoRecords(noRecords: number): void {
    this.AccountPaymentsByPageNoRecords = noRecords;
  }

  getAccountManagerPaymentsByOperatorByPage(index: number): Payment[] {
    return this._accountManagerPaymentsByOperatorByPage[index];
  }

  getAccountManagerPaymentsByOperatorByPageNoRecords(): number {
    return this.accountManagerPaymentsByOperatorByPageNoRecords;
  }

  setAccountManagerPaymentsByOperatorByPage(index: number, payments: Payment[]): void {
    if (this._accountManagerPaymentsByOperatorByPage.value.length > 0) {
      const currentPayments = this._accountManagerPaymentsByOperatorByPage.getValue();
      currentPayments[index] = payments;
      this._accountManagerPaymentsByOperatorByPage.next(currentPayments);
    } else {
      const currentPayments = [];
      currentPayments[index] = payments;
      this._accountManagerPaymentsByOperatorByPage.next(currentPayments);
    }
  }

  setAccountManagerPaymentsByOperatorByPageNoRecords(noRecords: number): void {
    this.accountManagerPaymentsByOperatorByPageNoRecords = noRecords;
  }

  getAccountPaymentsByOperatorByPage(index: number): Payment[] {
    return this.accountPaymentsByOperatorByPage[index];
  }

  getAccountPaymentsByOperatorByPageNoRecords(): number {
    return this.accountPaymentsByOperatorByPageNoRecords;
  }

  setAccountPaymentsByOperatorByPage(index: number, payments: Payment[]): void {
    this.accountPaymentsByOperatorByPage[index] = payments;
  }

  setAccountPaymentsByOperatorByPageNoRecords(noRecords: number): void {
    this.accountPaymentsByOperatorByPageNoRecords = noRecords;
  }

  resetAccountManagerPayments(): void {
    this.accountManagerPaymentsByPage = new BehaviorSubject<Payment[][]>([]);
    this.accountManagerPaymentsByPageNoRecords = 0;
  }

  public resetOperatorPayments(): void {
    this.accountManagerPaymentsByOperatorByPage = new BehaviorSubject<Payment[][]>([]);
    this.accountManagerPaymentsByOperatorByPageNoRecords = 0;
  }

  resetAccountOperatorPayments(): void {
    this.accountPaymentsByOperatorByPage = [];
    this.accountPaymentsByOperatorByPageNoRecords = 0;
  }

  getCurrentAccountProperties(): Property[] {
    return this.currentAcoountProperties;
  }

  setCurrentAccountProperties(currentAcoountProperties: Property[]): void {
    this.currentAcoountProperties = currentAcoountProperties;
  }

  getCurrentPropertyId(): number {
    return this.currentPropertyId;
  }

  setCurrentPropertyId(currentPropertyId: number): void {
    this.currentPropertyId = currentPropertyId;
  }

  getCurrentPayment(): Payment {
    return this.currentPayment;
  }

  setCurrentPayment(currentPayment: Payment ): void {
    this.currentPayment = currentPayment;
  }

  getCurrentAccountId(): number {
    return this.currentAccountId;
  }

  setCurrentAccountId(currentAccountId: number): void {
    this.currentAccountId = currentAccountId;
  }

  getAccountManagerPaymentSummaryByProperty(): PieChartData[] {
    return this.accountManagerPaymentSummaryByProperty;
  }

  getAccountManagerPaymentSummaryByPropertyValid(): boolean {
    return this.accountManagerPaymentSummaryByPropertyValid;
  }

  setAccountManagerPaymentSummaryByProperty(accountManagerPaymentSummaryByProperty: PieChartData[]): void {
    this.accountManagerPaymentSummaryByPropertyValid = this.dataVerificationService.validatePieChartData(accountManagerPaymentSummaryByProperty);
    this.accountManagerPaymentSummaryByProperty = accountManagerPaymentSummaryByProperty;
  }

  getAccountManagerPaymentSummaryByProduct(): PieChartData[] {
    return this.accountManagerPaymentSummaryByProduct;
  }

  getAccountManagerPaymentSummaryByProductValid(): boolean {
    return this.accountManagerPaymentSummaryByProductValid;
  }

  setAccountManagerPaymentSummaryByProduct(accountManagerPaymentSummaryByProduct: PieChartData[]): void {
    this.accountManagerPaymentSummaryByProductValid = this.dataVerificationService.validatePieChartData(accountManagerPaymentSummaryByProduct);
    this.accountManagerPaymentSummaryByProduct = accountManagerPaymentSummaryByProduct;
  }

  getAccountManagerPaymentSummaryByPeriod(): PaymentSummary {
    return this.accountManagerPaymentSummaryByPeriod;
  }

  getAccountManagerPaymentSummaryByPeriodDataObjects(): PieChartData[] {
    return this.accountManagerPaymentSummaryByPeriodDataObjects;
  }

  getAccountManagerPaymentSummaryByPeriodValid(): boolean {
    return this.accountManagerPaymentSummaryByPeriodValid;
  }

  setAccountManagerPaymentSummaryByPeriod(accountManagerPaymentSummaryByPeriod: PaymentSummary): void {
    this.accountManagerPaymentSummaryByPeriodDataObjects = this.chartDataProcessingServiceService.getPaymentSummaryDataObjects(accountManagerPaymentSummaryByPeriod);
    this.accountManagerPaymentSummaryByPeriodValid = this.dataVerificationService.validatePieChartData(this.accountManagerPaymentSummaryByPeriodDataObjects);
    this.accountManagerPaymentSummaryByPeriod = accountManagerPaymentSummaryByPeriod;
  }

  getAccountManagerPaymentSummaryByPeriodByProperty(): GraphAndBarChartData[] {
    return this.accountManagerPaymentSummaryByPeriodByProperty;
  }

  getAccountManagerPaymentSummaryByPeriodByPropertyValid(): boolean {
    return this.accountManagerPaymentSummaryByPeriodByPropertyValid;
  }

  setAccountManagerPaymentSummaryByPeriodByProperty(accountManagerPaymentSummaryByPeriodByProperty: GraphAndBarChartData[]): void {
    this.accountManagerPaymentSummaryByPeriodByPropertyValid = this.dataVerificationService.validateSeriesData(accountManagerPaymentSummaryByPeriodByProperty);
    this.accountManagerPaymentSummaryByPeriodByProperty = accountManagerPaymentSummaryByPeriodByProperty;
  }

  getAccountManagerPaymentSummaryByPeriodByProduct(): GraphAndBarChartData[] {
    return this.accountManagerPaymentSummaryByPeriodByProduct;
  }

  getAccountManagerPaymentSummaryByPeriodByProductValid(): boolean {
    return this.accountManagerPaymentSummaryByPeriodByProductValid;
  }

  setAccountManagerPaymentSummaryByPeriodByProduct(accountManagerPaymentSummaryByPeriodByProduct: GraphAndBarChartData[]): void {
    this.accountManagerPaymentSummaryByPeriodByProductValid = this.dataVerificationService.validateSeriesData(accountManagerPaymentSummaryByPeriodByProduct);
    this.accountManagerPaymentSummaryByPeriodByProduct = accountManagerPaymentSummaryByPeriodByProduct;
  }

  getAccountManagerId(): number {
    return this.accountManagerId;
  }

  setAccountManagerId(accountManagerId: number): void {
    this.accountManagerId = accountManagerId;
  }

  getAccountPaymentSummaryByProperty(): PieChartData[] {
    return this.AccountPaymentSummaryByProperty;
  }

  getAccountPaymentSummaryByPropertyValid(): boolean {
    return this.AccountPaymentSummaryByPropertyValid;
  }

  setAccountPaymentSummaryByProperty(AccountPaymentSummaryByProperty: PieChartData[]): void {
    this.AccountPaymentSummaryByPropertyValid = this.dataVerificationService.validatePieChartData(AccountPaymentSummaryByProperty);
    this.AccountPaymentSummaryByProperty = AccountPaymentSummaryByProperty;
  }

  getAccountPaymentSummaryByProduct(): PieChartData[] {
    return this.AccountPaymentSummaryByProduct;
  }

  getAccountPaymentSummaryByProductValid(): boolean {
    return this.AccountPaymentSummaryByProductValid;
  }

  setAccountPaymentSummaryByProduct(AccountPaymentSummaryByProduct: PieChartData[]): void {
    this.AccountPaymentSummaryByProductValid = this.dataVerificationService.validatePieChartData(AccountPaymentSummaryByProduct);
    this.AccountPaymentSummaryByProduct = AccountPaymentSummaryByProduct;
  }

  getAccountPaymentSummaryByPeriod(): PaymentSummary {
    return this.AccountPaymentSummaryByPeriod;
  }

  getAccountPaymentSummaryByPeriodDataObjects(): PieChartData[] {
    return this.AccountPaymentSummaryByPeriodDataObjects;
  }

  getAccountPaymentSummaryByPeriodValid(): boolean {
    return this.AccountPaymentSummaryByPeriodValid;
  }

  setAccountPaymentSummaryByPeriod(AccountPaymentSummaryByPeriod: PaymentSummary): void {
    this.AccountPaymentSummaryByPeriodDataObjects = this.chartDataProcessingServiceService.getPaymentSummaryDataObjects(AccountPaymentSummaryByPeriod);
    this.AccountPaymentSummaryByPeriodValid = this.dataVerificationService.validatePieChartData(this.AccountPaymentSummaryByPeriodDataObjects);
    this.AccountPaymentSummaryByPeriod = AccountPaymentSummaryByPeriod;
  }

  getAccountPaymentSummaryByPeriodByProperty(): GraphAndBarChartData[] {
    return this.AccountPaymentSummaryByPeriodByProperty;
  }

  getAccountPaymentSummaryByPeriodByPropertyValid(): boolean {
    return this.AccountPaymentSummaryByPeriodByPropertyValid;
  }

  setAccountPaymentSummaryByPeriodByProperty(AccountPaymentSummaryByPeriodByProperty: GraphAndBarChartData[]): void {
    this.AccountPaymentSummaryByPeriodByPropertyValid = this.dataVerificationService.validateSeriesData(AccountPaymentSummaryByPeriodByProperty);
    this.AccountPaymentSummaryByPeriodByProperty = AccountPaymentSummaryByPeriodByProperty;
  }

  getAccountPaymentSummaryByPeriodByProduct(): GraphAndBarChartData[] {
    return this.AccountPaymentSummaryByPeriodByProduct;
  }

  getAccountPaymentSummaryByPeriodByProductValid(): boolean {
    return this.AccountPaymentSummaryByPeriodByProductValid;
  }

  setAccountPaymentSummaryByPeriodByProduct(AccountPaymentSummaryByPeriodByProduct: GraphAndBarChartData[]): void {
    this.AccountPaymentSummaryByPeriodByProductValid = this.dataVerificationService.validateSeriesData(AccountPaymentSummaryByPeriodByProduct);
    this.AccountPaymentSummaryByPeriodByProduct = AccountPaymentSummaryByPeriodByProduct;
  }
}
