import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { StatementFooterComponent } from '../statement-footer/statement-footer.component';
import { Payment } from '../../model/payment';
import { PaymentSummary } from '../../model/payment_summary';
import { Statement } from '@angular/compiler';
import { ExportFooterComponent } from '../export-footer/export-footer.component';
import { Router } from '@angular/router';
import { Account } from '../../model/account';
import { StorageService } from '../../core/services/storage/storage-service.service';
import { SpinnerComponent } from '../spinner/spinner.component';

@Component({
  selector: 'app-account-payment-grid',
  standalone: true,
  imports: [
    CommonModule,
    StatementFooterComponent,
    ExportFooterComponent,
    SpinnerComponent
  ],
  templateUrl: './account-payment-grid.component.html',
  styleUrls: ['./account-payment-grid.component.css']
})
export class AccountPaymentGridComponent {
  @Input() paymentsSummary: PaymentSummary[];
  @Input() payments: Payment[] = [];
  @Input() summaryTotal: number;
  @Input() statements: Statement[];
  @Input() account: Account;
  @Input() loadingMore: boolean;
  @Input() lastPage: boolean;
  @Input() currentTotal: number;
  @Input() pageLoading: boolean;

  public gridTotal

  constructor(
    private router: Router,
    private storageService: StorageService
  ) { }

  navigateToPaymentDetails(payment: Payment) {
    this.storageService.setCurrentPayment(payment);
    this.router.navigate(['/paymentDetails']);
  }

}
