import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Property } from '../../../model/property';

@Injectable({
  providedIn: 'root'
})
export abstract class PropertyService {

  private properties: Property[] = [];

  abstract getAccountManagerProperties(): Observable<Property[]>;

  abstract getAccountProperties(accountId: number): Observable<Property[]>;

  getProperties(): Property[] {
    return this.properties;
  }

  setProperties(properties: Property[]): void {
    this.properties = properties;
  }

}
