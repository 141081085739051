import { InterestType } from "../model/interest";

export const interestType1: InterestType = {
  id: 1,
  name: "Royalty Interest",
  abbr: "RI",
  operatorId: 1,
  primaryId: 1,
  cdex: 1
};

export const interestType2: InterestType = {
  id: 2,
  name: "Working Interest",
  abbr: "WI",
  operatorId: 2,
  primaryId: 2,
  cdex: 2
};

export const interestType3: InterestType = {
  id: 3,
  name: "Overriding Royalty Interest",
  abbr: "ORI",
  operatorId: 3,
  primaryId: 3,
  cdex: 3
};

export const interestType4: InterestType = {
  id: 4,
  name: "Net Profits Interest",
  abbr: "NPI",
  operatorId: 4,
  primaryId: 4,
  cdex: 4
};

export const interestType5: InterestType = {
  id: 5,
  name: "Non-Participating Royalty Interest",
  abbr: "NPRI",
  operatorId: 5,
  primaryId: 5,
  cdex: 5
};

export const interestType6: InterestType = {
  id: 6,
  name: "Production Payment Interest",
  abbr: "PPI",
  operatorId: 6,
  primaryId: 6,
  cdex: 6
};

export const interestTypes: InterestType[] = [
  interestType1,
  interestType2,
  interestType3,
  interestType4,
  interestType5
];