import { DeductionObject } from "../model/deduction";

export const deductionObject1: DeductionObject = {
    id: 1,
    name: 'Transportation',
    amount: 100
}

export const deductionObject2: DeductionObject = {
    id: 2,
    name: 'Processing',
    amount: 100
}

export const deductionObject3: DeductionObject = {
    id: 3,
    name: 'Compression',
    amount: 100
}

export const deductionObject4: DeductionObject = {
    id: 4,
    name: 'Marketing',
    amount: 100
}

export const deductionObject5: DeductionObject = {
    id: 5,
    name: 'Insurance',
    amount: 100
}

export const deductionObject6: DeductionObject = {
    id: 6,
    name: 'Other',
    amount: 100
}

export const deductionObject7: DeductionObject = {
    id: 7,
    name: 'Other',
    amount: 100
}

export const deductionObject8: DeductionObject = {
    id: 8,
    name: 'Other',
    amount: 100
}

export const deductionObject9: DeductionObject = {
    id: 9,
    name: 'Other',
    amount: 100
}

export const deductionObject10: DeductionObject = {
    id: 10,
    name: 'Other',
    amount: 100
}

export const deductionObject11: DeductionObject = {
    id: 11,
    name: 'Other',
    amount: 100
}

export const deductionObject12: DeductionObject = {
    id: 12,
    name: 'Other',
    amount: 100
}

export const deductionObject13: DeductionObject = {
    id: 13,
    name: 'Other',
    amount: 100
}

export const deductionObject14: DeductionObject = {
    id: 14,
    name: 'Other',
    amount: 100
}

export const deductionObject15: DeductionObject = {
    id: 15,
    name: 'Other',
    amount: 100
}

export const deductionObject16: DeductionObject = {
    id: 16,
    name: 'Other',
    amount: 100
}

export const deductionObject17: DeductionObject = {
    id: 17,
    name: 'Other',
    amount: 100
}

export const deductionObject18: DeductionObject = {
    id: 18,
    name: 'Other',
    amount: 100
}

export const deductionObject19: DeductionObject = {
    id: 19,
    name: 'Other',
    amount: 100
}

export const deductionObject20: DeductionObject = {
    id: 20,
    name: 'Other',
    amount: 100
}

export const deductionObject21: DeductionObject = {
    id: 21,
    name: 'Other',
    amount: 100
}

export const deductionObject22: DeductionObject = {
    id: 22,
    name: 'Other',
    amount: 100
}

export const deductionObject23: DeductionObject = {
    id: 23,
    name: 'Other',
    amount: 100
}

export const deductionObject24: DeductionObject = {
    id: 24,
    name: 'Other',
    amount: 100
}

export const deductionObject25: DeductionObject = {
    id: 25,
    name: 'Other',
    amount: 100
}

export const deductionObject26: DeductionObject = {
    id: 26,
    name: 'Other',
    amount: 100
}

export const deductionObjects: DeductionObject[] = [
    deductionObject1,
    deductionObject2,
    deductionObject3,
    deductionObject4,
    deductionObject5,
    deductionObject6,
    deductionObject7,
    deductionObject8,
    deductionObject9,
    deductionObject10,
    deductionObject11,
    deductionObject12,
    deductionObject13,
    deductionObject14,
    deductionObject15,
    deductionObject16,
    deductionObject17,
    deductionObject18,
    deductionObject19,
    deductionObject20,
    deductionObject21,
    deductionObject22,
    deductionObject23,
    deductionObject24,
    deductionObject25,
    deductionObject26
];
