import { Injectable } from '@angular/core';
import { MarketService } from './market.service';
import { Observable } from 'rxjs';
import { ResourcePriceData } from '../../../model/resourcePriceData';
import { HttpService } from '../httpService/http.service';

@Injectable({
  providedIn: 'root'
})
export class HttpMarketService extends MarketService{

  constructor(
    private httpService: HttpService,
  ) { super(); }

  getMarketPrice(resource: string): Observable<ResourcePriceData> {
    const encodedResource = encodeURIComponent(resource);
    return this.httpService.get(`/getMarketPrice?commodity=${encodedResource}`, true);
  }

}
