import { HttpService } from './../../core/services/httpService/http.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { catchError } from 'rxjs/operators';
import { OperatorPaymentService } from '../../core/services/operator-payment/operator-payment.service';
import { PropertyService } from '../../core/services/property/property.service';
import { AuthService } from '../../core/services/authService/auth.service';
import { DashboardWidgetsComponent } from './dashboard-widgets/dashboard-widgets.component';
import { DashboardNotificationsComponent } from './dashboard-notifications/dashboard-notifications.component';
import { DashboardSearchComponent } from './dashboard-search/dashboard-search.component';
import { AccountService } from '../../core/services/account/account.service';
import { DashboardSummaryComponent } from './dashboard-summary/dashboard-summary';
@Component({
  selector: 'app-dashboard',
  standalone: true,
  imports: [
    DashboardWidgetsComponent,
    DashboardNotificationsComponent,
    DashboardSearchComponent,
    DashboardSummaryComponent
  ],
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  public title = 'Oil and Gas - Account Management Portal';
  public displayName = '';

  constructor(
    private propertyService: PropertyService,
    private paymentService: OperatorPaymentService,
    private authService: AuthService,
    private httpService: HttpService,
    private _router: Router,
    private accountService: AccountService
  ) {}


  ngOnInit(): void {
    // this.httpService.token$.subscribe((token) => {
    //   if (localStorage.getItem('token')) {
    //     this.initializeApp();
    //   }
    // });
  }

  initializeApp() {
    this.getProperties();
    this.getPayments();
    this.getAccountManagerAccounts();
  }

  private getProperties() {
    if (localStorage.getItem('token')) {
      this.propertyService.getAccountManagerProperties()
        .pipe(
          catchError((error) => {
            if (error instanceof HttpErrorResponse) {
              if (error.status === 401) {
                console.info('error', error);
                this._router.navigate(['/login']);
              }
            }
            return [];
          })
        )
        .subscribe((data) => {
          this.propertyService.setProperties(data);
      });
    }
  }

  private getPayments() {
    if (localStorage.getItem('token')) {
      this.paymentService.getAccountManagerPayments()
        .pipe(
          catchError((error) => {
            if (error instanceof HttpErrorResponse) {
              if (error.status === 401) {
                console.info('error', error);
                this._router.navigate(['/login']);
              }
            }
            return [];
          })
        )
        .subscribe((data) => {
          this.paymentService.setPayments(data);
      });
    }
  }

  private getAccountManagerAccounts() {
    this.accountService.getAccountManagerAccounts()
      .pipe(
        catchError((error) => {
          if (error instanceof HttpErrorResponse) {
            if (error.status === 401) {
              console.error('error', error);
              this._router.navigate(['/login']);
            }
          }
          return [];
        })
      )
      .subscribe((data) => {
        this.accountService.setAccounts(data);
    });
  }

}
