import { Injectable } from '@angular/core';
import { PerformanceService } from './performance.service';
import { Observable } from 'rxjs';
import { PropertyPerformance } from '../../../model/performance';
import { HttpService } from '../httpService/http.service';

@Injectable({
  providedIn: 'root'
})
export class HttpPerformanceService extends PerformanceService {

  constructor(
    private httpService: HttpService
  ) {
    super();
   }

  getPropertyPerformance(propertyId: number): Observable<PropertyPerformance[]>{
    const url = `/getPropertyPerformance?propertyId=${propertyId}`;
    return this.httpService.get(url, true);
  }
}
