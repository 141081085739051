import { operators } from './operators';
import { Well } from './../model/well';
import { products } from './products';
import { counties } from './counties';

export const well1: Well = {
  apiNo: '1',
  name: 'Well 1',
  status: {
    id: 1,
    name: 'Active'
  },
  operator: operators[0],
  spudDate: new Date('2023-01-01'),
  completionDate: new Date('2023-01-01'),
  firstProductionDate: new Date('2023-01-01'),
  totalVerticalDepth: 1000,
  latitude: 35.0,
  longitude: -100.0,
  gisUrl: 'https://www.google.com/maps',
  products: [products[0]],
  geographicalData: {
    county: counties[0],
    section: '1',
    sectionNo: '1',
    township: '1',
    range: '1',
    surveyName: 'Survey Name',
    abstractData: 'Abstract',
    grossAcreage: 1000,
    netAcreage: 800,
    insertBy: 1,
    insertDate: new Date(),
    modifyBy: 1,
    modifyDate: new Date(),
    block: 'Block',
  },
  insertBy: '1',
  insertDate: new Date(),
  modifyBy: '1',
  modifyDate: new Date(),

};

export const well2: Well = {
  apiNo: '2',
  name: 'Well 2',
  status: {
    id: 1,
    name: 'Active'
  },
  operator: operators[1],
  spudDate: new Date('2023-01-01'),
  completionDate: new Date('2023-01-01'),
  firstProductionDate: new Date('2023-01-01'),
  totalVerticalDepth: 1000,
  latitude: 35.0,
  longitude: -100.0,
  gisUrl: 'https://www.google.com/maps',
  products: [products[1]],
  geographicalData: {
    county: counties[1],
    section: '1',
    sectionNo: '1',
    township: '1',
    range: '1',
    surveyName: 'Survey Name',
    abstractData: 'Abstract',
    grossAcreage: 1000,
    netAcreage: 800,
    insertBy: 1,
    insertDate: new Date(),
    modifyBy: 1,
    modifyDate: new Date(),
    block: 'Block',
  },
  insertBy: '1',
  insertDate: new Date(),
  modifyBy: '1',
  modifyDate: new Date(),
};

export const well3: Well = {
  apiNo: '3',
  name: 'Well 3',
  status: {
    id: 1,
    name: 'Active'
  },
  operator: operators[2],
  spudDate: new Date('2023-01-01'),
  completionDate: new Date('2023-01-01'),
  firstProductionDate: new Date('2023-01-01'),
  totalVerticalDepth: 1000,
  latitude: 35.0,
  longitude: -100.0,
  gisUrl: 'https://www.google.com/maps',
  products: [products[2]],
  geographicalData: {
    county: counties[2],
    section: '1',
    sectionNo: '1',
    township: '1',
    range: '1',
    surveyName: 'Survey Name',
    abstractData: 'Abstract',
    grossAcreage: 1000,
    netAcreage: 800,
    insertBy: 1,
    insertDate: new Date(),
    modifyBy: 1,
    modifyDate: new Date(),
    block: 'Block',
  },
  insertBy: '1',
  insertDate: new Date(),
  modifyBy: '1',
  modifyDate: new Date(),
};

export const well4: Well = {
  apiNo: '4',
  name: 'Well 4',
  status: {
    id: 1,
    name: 'Active'
  },
  operator: operators[3],
  spudDate: new Date('2023-01-01'),
  completionDate: new Date('2023-01-01'),
  firstProductionDate: new Date('2023-01-01'),
  totalVerticalDepth: 1000,
  latitude: 35.0,
  longitude: -100.0,
  gisUrl: 'https://www.google.com/maps',
  products: [products[3]],
  geographicalData: {
    county: counties[3],
    section: '1',
    sectionNo: '1',
    township: '1',
    range: '1',
    surveyName: 'Survey Name',
    abstractData: 'Abstract',
    grossAcreage: 1000,
    netAcreage: 800,
    insertBy: 1,
    insertDate: new Date(),
    modifyBy: 1,
    modifyDate: new Date(),
    block: 'Block',
  },
  insertBy: '1',
  insertDate: new Date(),
  modifyBy: '1',
  modifyDate: new Date(),
};

export const well5: Well = {
  apiNo: '5',
  name: 'Well 5',
  status: {
    id: 1,
    name: 'Active'
  },
  operator: operators[4],
  spudDate: new Date('2023-01-01'),
  completionDate: new Date('2023-01-01'),
  firstProductionDate: new Date('2023-01-01'),
  totalVerticalDepth: 1000,
  latitude: 35.0,
  longitude: -100.0,
  gisUrl: 'https://www.google.com/maps',
  products: [products[4]],
  geographicalData: {
    county: counties[4],
    section: '1',
    sectionNo: '1',
    township: '1',
    range: '1',
    surveyName: 'Survey Name',
    abstractData: 'Abstract',
    grossAcreage: 1000,
    netAcreage: 800,
    insertBy: 1,
    insertDate: new Date(),
    modifyBy: 1,
    modifyDate: new Date(),
    block: 'Block',
  },
  insertBy: '1',
  insertDate: new Date(),
  modifyBy: '1',
  modifyDate: new Date(),
};

export const well6: Well = {
  apiNo: '6',
  name: 'Well 6',
  status: {
    id: 1,
    name: 'Active'
  },
  operator: operators[5],
  spudDate: new Date('2023-01-01'),
  completionDate: new Date('2023-01-01'),
  firstProductionDate: new Date('2023-01-01'),
  totalVerticalDepth: 1000,
  latitude: 35.0,
  longitude: -100.0,
  gisUrl: 'https://www.google.com/maps',
  products: [products[5]],
  geographicalData: {
    county: counties[5],
    section: '1',
    sectionNo: '1',
    township: '1',
    range: '1',
    surveyName: 'Survey Name',
    abstractData: 'Abstract',
    grossAcreage: 1000,
    netAcreage: 800,
    insertBy: 1,
    insertDate: new Date(),
    modifyBy: 1,
    modifyDate: new Date(),
    block: 'Block',
  },
  insertBy: '1',
  insertDate: new Date(),
  modifyBy: '1',
  modifyDate: new Date(),
};

export const well7: Well = {
  apiNo: '7',
  name: 'Well 7',
  status: {
    id: 1,
    name: 'Active'
  },
  operator: operators[6],
  spudDate: new Date('2023-01-01'),
  completionDate: new Date('2023-01-01'),
  firstProductionDate: new Date('2023-01-01'),
  totalVerticalDepth: 1000,
  latitude: 35.0,
  longitude: -100.0,
  gisUrl: 'https://www.google.com/maps',
  products: [products[6]],
  geographicalData: {
    county: counties[6],
    section: '1',
    sectionNo: '1',
    township: '1',
    range: '1',
    surveyName: 'Survey Name',
    abstractData: 'Abstract',
    grossAcreage: 1000,
    netAcreage: 800,
    insertBy: 1,
    insertDate: new Date(),
    modifyBy: 1,
    modifyDate: new Date(),
    block: 'Block',
  },
  insertBy: '1',
  insertDate: new Date(),
  modifyBy: '1',
  modifyDate: new Date(),
};

export const well8: Well = {
  apiNo: '8',
  name: 'Well 8',
  status: {
    id: 1,
    name: 'Active'
  },
  operator: operators[7],
  spudDate: new Date('2023-01-01'),
  completionDate: new Date('2023-01-01'),
  firstProductionDate: new Date('2023-01-01'),
  totalVerticalDepth: 1000,
  latitude: 35.0,
  longitude: -100.0,
  gisUrl: 'https://www.google.com/maps',
  products: [products[7]],
  geographicalData: {
    county: counties[7],
    section: '1',
    sectionNo: '1',
    township: '1',
    range: '1',
    surveyName: 'Survey Name',
    abstractData: 'Abstract',
    grossAcreage: 1000,
    netAcreage: 800,
    insertBy: 1,
    insertDate: new Date(),
    modifyBy: 1,
    modifyDate: new Date(),
    block: 'Block',
  },
  insertBy: '1',
  insertDate: new Date(),
  modifyBy: '1',
  modifyDate: new Date(),
};

export const well9: Well = {
  apiNo: '9',
  name: 'Well 9',
  status: {
    id: 1,
    name: 'Active'
  },
  operator: operators[8],
  spudDate: new Date('2023-01-01'),
  completionDate: new Date('2023-01-01'),
  firstProductionDate: new Date('2023-01-01'),
  totalVerticalDepth: 1000,
  latitude: 35.0,
  longitude: -100.0,
  gisUrl: 'https://www.google.com/maps',
  products: [products[8]],
  geographicalData: {
    county: counties[8],
    section: '1',
    sectionNo: '1',
    township: '1',
    range: '1',
    surveyName: 'Survey Name',
    abstractData: 'Abstract',
    grossAcreage: 1000,
    netAcreage: 800,
    insertBy: 1,
    insertDate: new Date(),
    modifyBy: 1,
    modifyDate: new Date(),
    block: 'Block',
  },
  insertBy: '1',
  insertDate: new Date(),
  modifyBy: '1',
  modifyDate: new Date(),
};

export const well10: Well = {
  apiNo: '10',
  name: 'Well 10',
  status: {
    id: 1,
    name: 'Active'
  },
  operator: operators[9],
  spudDate: new Date('2023-01-01'),
  completionDate: new Date('2023-01-01'),
  firstProductionDate: new Date('2023-01-01'),
  totalVerticalDepth: 1000,
  latitude: 35.0,
  longitude: -100.0,
  gisUrl: 'https://www.google.com/maps',
  products: [products[9]],
  geographicalData: {
    county: counties[9],
    section: '1',
    sectionNo: '1',
    township: '1',
    range: '1',
    surveyName: 'Survey Name',
    abstractData: 'Abstract',
    grossAcreage: 1000,
    netAcreage: 800,
    insertBy: 1,
    insertDate: new Date(),
    modifyBy: 1,
    modifyDate: new Date(),
    block: 'Block',
  },
  insertBy: '1',
  insertDate: new Date(),
  modifyBy: '1',
  modifyDate: new Date(),
};

export const well11: Well = {
  apiNo: '11',
  name: 'Well 11',
  status: {
    id: 1,
    name: 'Active'
  },
  operator: operators[10],
  spudDate: new Date('2023-01-01'),
  completionDate: new Date('2023-01-01'),
  firstProductionDate: new Date('2023-01-01'),
  totalVerticalDepth: 1000,
  latitude: 35.0,
  longitude: -100.0,
  gisUrl: 'https://www.google.com/maps',
  products: [products[10]],
  geographicalData: {
    county: counties[10],
    section: '1',
    sectionNo: '1',
    township: '1',
    range: '1',
    surveyName: 'Survey Name',
    abstractData: 'Abstract',
    grossAcreage: 1000,
    netAcreage: 800,
    insertBy: 1,
    insertDate: new Date(),
    modifyBy: 1,
    modifyDate: new Date(),
    block: 'Block',
  },
  insertBy: '1',
  insertDate: new Date(),
  modifyBy: '1',
  modifyDate: new Date(),
};

const well12: Well = {
  apiNo: '12',
  name: 'Well 12',
  status: {
    id: 1,
    name: 'Active'
  },
  operator: operators[11],
  spudDate: new Date('2023-01-01'),
  completionDate: new Date('2023-01-01'),
  firstProductionDate: new Date('2023-01-01'),
  totalVerticalDepth: 1000,
  latitude: 35.0,
  longitude: -100.0,
  gisUrl: 'https://www.google.com/maps',
  products: [products[11]],
  geographicalData: {
    county: counties[11],
    section: '1',
    sectionNo: '1',
    township: '1',
    range: '1',
    surveyName: 'Survey Name',
    abstractData: 'Abstract',
    grossAcreage: 1000,
    netAcreage: 800,
    insertBy: 1,
    insertDate: new Date(),
    modifyBy: 1,
    modifyDate: new Date(),
    block: 'Block',
  },
  insertBy: '1',
  insertDate: new Date(),
  modifyBy: '1',
  modifyDate: new Date(),
};

const well13: Well = {
  apiNo: '13',
  name: 'Well 13',
  status: {
    id: 1,
    name: 'Active'
  },
  operator: operators[12],
  spudDate: new Date('2023-01-01'),
  completionDate: new Date('2023-01-01'),
  firstProductionDate: new Date('2023-01-01'),
  totalVerticalDepth: 1000,
  latitude: 35.0,
  longitude: -100.0,
  gisUrl: 'https://www.google.com/maps',
  products: [products[12]],
  geographicalData: {
    county: counties[12],
    section: '1',
    sectionNo: '1',
    township: '1',
    range: '1',
    surveyName: 'Survey Name',
    abstractData: 'Abstract',
    grossAcreage: 1000,
    netAcreage: 800,
    insertBy: 1,
    insertDate: new Date(),
    modifyBy: 1,
    modifyDate: new Date(),
    block: 'Block',
  },
  insertBy: '1',
  insertDate: new Date(),
  modifyBy: '1',
  modifyDate: new Date(),
};

export const well14: Well = {
  apiNo: '14',
  name: 'Well 14',
  status: {
    id: 1,
    name: 'Active'
  },
  operator: operators[13],
  spudDate: new Date('2023-01-01'),
  completionDate: new Date('2023-01-01'),
  firstProductionDate: new Date('2023-01-01'),
  totalVerticalDepth: 1000,
  latitude: 35.0,
  longitude: -100.0,
  gisUrl: 'https://www.google.com/maps',
  products: [products[13]],
  geographicalData: {
    county: counties[13],
    section: '1',
    sectionNo: '1',
    township: '1',
    range: '1',
    surveyName: 'Survey Name',
    abstractData: 'Abstract',
    grossAcreage: 1000,
    netAcreage: 800,
    insertBy: 1,
    insertDate: new Date(),
    modifyBy: 1,
    modifyDate: new Date(),
    block: 'Block',
  },
  insertBy: '1',
  insertDate: new Date(),
  modifyBy: '1',
  modifyDate: new Date(),
};

export const well15: Well = {
  apiNo: '15',
  name: 'Well 15',
  status: {
    id: 1,
    name: 'Active'
  },
  operator: operators[14],
  spudDate: new Date('2023-01-01'),
  completionDate: new Date('2023-01-01'),
  firstProductionDate: new Date('2023-01-01'),
  totalVerticalDepth: 1000,
  latitude: 35.0,
  longitude: -100.0,
  gisUrl: 'https://www.google.com/maps',
  products: [products[14]],
  geographicalData: {
    county: counties[14],
    section: '1',
    sectionNo: '1',
    township: '1',
    range: '1',
    surveyName: 'Survey Name',
    abstractData: 'Abstract',
    grossAcreage: 1000,
    netAcreage: 800,
    insertBy: 1,
    insertDate: new Date(),
    modifyBy: 1,
    modifyDate: new Date(),
    block: 'Block',
  },
  insertBy: '1',
  insertDate: new Date(),
  modifyBy: '1',
  modifyDate: new Date(),
};

export const well16: Well = {
  apiNo: '16',
  name: 'Well 16',
  status: {
    id: 1,
    name: 'Active'
  },
  operator: operators[15],
  spudDate: new Date('2023-01-01'),
  completionDate: new Date('2023-01-01'),
  firstProductionDate: new Date('2023-01-01'),
  totalVerticalDepth: 1000,
  latitude: 35.0,
  longitude: -100.0,
  gisUrl: 'https://www.google.com/maps',
  products: [products[15]],
  geographicalData: {
    county: counties[15],
    section: '1',
    sectionNo: '1',
    township: '1',
    range: '1',
    surveyName: 'Survey Name',
    abstractData: 'Abstract',
    grossAcreage: 1000,
    netAcreage: 800,
    insertBy: 1,
    insertDate: new Date(),
    modifyBy: 1,
    modifyDate: new Date(),
    block: 'Block',
  },
  insertBy: '1',
  insertDate: new Date(),
  modifyBy: '1',
  modifyDate: new Date(),
};

export const well17: Well = {
  apiNo: '17',
  name: 'Well 17',
  status: {
    id: 1,
    name: 'Active'
  },
  operator: operators[16],
  spudDate: new Date('2023-01-01'),
  completionDate: new Date('2023-01-01'),
  firstProductionDate: new Date('2023-01-01'),
  totalVerticalDepth: 1000,
  latitude: 35.0,
  longitude: -100.0,
  gisUrl: 'https://www.google.com/maps',
  products: [products[16]],
  geographicalData: {
    county: counties[16],
    section: '1',
    sectionNo: '1',
    township: '1',
    range: '1',
    surveyName: 'Survey Name',
    abstractData: 'Abstract',
    grossAcreage: 1000,
    netAcreage: 800,
    insertBy: 1,
    insertDate: new Date(),
    modifyBy: 1,
    modifyDate: new Date(),
    block: 'Block',
  },
  insertBy: '1',
  insertDate: new Date(),
  modifyBy: '1',
  modifyDate: new Date(),
};

export const well18: Well = {
  apiNo: '18',
  name: 'Well 18',
  status: {
    id: 1,
    name: 'Active'
  },
  operator: operators[17],
  spudDate: new Date('2023-01-01'),
  completionDate: new Date('2023-01-01'),
  firstProductionDate: new Date('2023-01-01'),
  totalVerticalDepth: 1000,
  latitude: 35.0,
  longitude: -100.0,
  gisUrl: 'https://www.google.com/maps',
  products: [products[17]],
  geographicalData: {
    county: counties[17],
    section: '1',
    sectionNo: '1',
    township: '1',
    range: '1',
    surveyName: 'Survey Name',
    abstractData: 'Abstract',
    grossAcreage: 1000,
    netAcreage: 800,
    insertBy: 1,
    insertDate: new Date(),
    modifyBy: 1,
    modifyDate: new Date(),
    block: 'Block',
  },
  insertBy: '1',
  insertDate: new Date(),
  modifyBy: '1',
  modifyDate: new Date(),
};

export const wells: Well[] = [
  well1,
  well2,
  well3,
  well4,
  well5,
  well6,
  well7,
  well8,
  well9,
  well10,
  well11,
  well12,
  well13,
  well14,
  well15,
  well16,
  well17,
  well18
];
