import { operators } from './operators';
import { Payment } from "../model/payment";
import { royaltyPayments } from './royaltyPayments';
import { accounts } from './accounts';

export const payment1: Payment = {
  id: 15112,
  operator: operators[0],
  accountId: accounts[0].id,
  paymentDate: new Date('2019-01-01'),
  paymentType: {
    id: 1,
    name: "Electronic Transfer",
    operatorId: operators[0].id,
    primaryId: 1
  },
  status: {
    id: 1,
    name: "Payable",
    operatorId: operators[0].id,
    primaryId: 1
  },
  checkNo: "chk2554663",
  checkAmount: 1000,
  royaltyPayments: [
    royaltyPayments[0],
    royaltyPayments[1],
    royaltyPayments[2]
  ],
  accountName: accounts[0].name,
  accountNumber: accounts[0].number,
  accountStatus: accounts[0].status
};

export const payment2: Payment = {
  id: 2,
  operator: operators[1],
  accountId: accounts[1].id,
  paymentDate: new Date('2019-02-01'),
  paymentType: {
    id: 2,
    name: "Check",
    operatorId: operators[1].id,
    primaryId: 2
  },
  status: {
    id: 2,
    name: "Owner Suspended",
    operatorId: operators[1].id,
    primaryId: 2
  },
  checkNo: "chk2554664",
  checkAmount: 2000,
  royaltyPayments: [
    royaltyPayments[3],
    royaltyPayments[4],
    royaltyPayments[5]
  ],
  accountName: accounts[1].name,
  accountNumber: accounts[1].number,
  accountStatus: accounts[1].status
};

export const payment3: Payment = {
  id: 3,
  operator: operators[2],
  accountId: accounts[2].id,
  paymentDate: new Date('2019-03-01'),
  paymentType: {
    id: 3,
    name: "Pending",
    operatorId: operators[2].id,
    primaryId: 3
  },
  status: {
    id: 3,
    name: "Issued",
    operatorId: operators[2].id,
    primaryId: 3
  },
  checkNo: "chk2554665",
  checkAmount: 3000,
  royaltyPayments: [
    royaltyPayments[6],
    royaltyPayments[7],
    royaltyPayments[8]
  ],
  accountName: accounts[2].name,
  accountNumber: accounts[2].number,
  accountStatus: accounts[2].status
};

export const payment4: Payment = {
  id: 4,
  operator: operators[3],
  accountId: accounts[3].id,
  paymentDate: new Date('2019-04-01'),
  paymentType: {
    id: 4,
    name: "Check",
    operatorId: operators[3].id,
    primaryId: 4
  },
  status: {
    id: 4,
    name: "Confirmed",
    operatorId: operators[3].id,
    primaryId: 4
  },
  checkNo: "chk2554666",
  checkAmount: 4000,
  royaltyPayments: [
    royaltyPayments[9],
    royaltyPayments[10],
    royaltyPayments[11]
  ],
  accountName: accounts[3].name,
  accountNumber: accounts[3].number,
  accountStatus: accounts[3].status
};

export const payment5: Payment = {
  id: 5,
  operator: operators[0],
  accountId: accounts[4].id,
  paymentDate: new Date('2019-05-01'),
  paymentType: {
    id: 5,
    name: "Electronic Transfer",
    operatorId: operators[0].id,
    primaryId: 5
  },
  status: {
    id: 5,
    name: "Paid",
    operatorId: operators[0].id,
    primaryId: 5
  },
  checkNo: "chk2554667",
  checkAmount: 5000,
  royaltyPayments: [
    royaltyPayments[12],
    royaltyPayments[13],
    royaltyPayments[14]
  ],
  accountName: accounts[4].name,
  accountNumber: accounts[4].number,
  accountStatus: accounts[4].status
};

export const payment6: Payment = {
  id: 6,
  operator: operators[1],
  accountId: accounts[5].id,
  paymentDate: new Date('2019-06-01'),
  paymentType: {
    id: 6,
    name: "Check",
    operatorId: operators[1].id,
    primaryId: 6
  },
  status: {
    id: 6,
    name: "Pending",
    operatorId: operators[1].id,
    primaryId: 6
  },
  checkNo: "chk2554668",
  checkAmount: 6000,
  royaltyPayments: [
    royaltyPayments[15],
    royaltyPayments[16],
    royaltyPayments[17]
  ],
  accountName: accounts[5].name,
  accountNumber: accounts[5].number,
  accountStatus: accounts[5].status
};

export const payments: Payment[] = [
  payment1,
  payment2,
  payment3,
  payment4,
  payment5,
  payment6
];

