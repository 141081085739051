import { AuthService } from '../../core/services/authService/auth.service';
import { Component, HostListener } from '@angular/core';
import { UserResponse } from '../../model/user';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent {

  public displayName = ''
  public AccountManagementLogo = '';
  public isUserDropdownOpen = false;
  public isNotificationDropdownOpen = false;
  public navbarOpen = false;

  constructor(
    private _authService: AuthService,
    private _router: Router
  ) {
    let userResponse: UserResponse = JSON.parse(localStorage.getItem('user'));
    if (userResponse) {
      this.displayName = userResponse.accountManager.name;
    }
  }

  get isMobile() {
    return window.innerWidth <= 768;
  }

  get loggedIn() {
    if (localStorage.getItem('user') !== null && this._router.url !== '/login') {
      return true;
    } else {
      return false;
    }
  }
  public onLogoutDropdownClicked() {
    this.isUserDropdownOpen = !this.isUserDropdownOpen;
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent) {
    const target = event.target as HTMLElement;
    const clickedInside = target.closest('#header_user');
    if (!clickedInside) {
      this.isUserDropdownOpen = false;
    }
  }

  public onNotificationDropdownClicked() {
    this.isNotificationDropdownOpen = !this.isNotificationDropdownOpen;
  }

  public getBankLogo() {
    if (this.AccountManagementLogo === '') {
      const currUser = this._authService.getUser();
      this.AccountManagementLogo = null
    }
    return this.AccountManagementLogo;
  }

  public logout() {
    this.displayName = '';
    this.isUserDropdownOpen = false;
    this.isNotificationDropdownOpen = false;
    this._authService.logOut();
    this._router.navigate(['/loggedOut']);
  }

  public toggleNavbar() {
    this.navbarOpen = !this.navbarOpen;
  }

}
