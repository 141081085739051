import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { environment } from '../../environments/environment';
import { AuthService } from './services/authService/auth.service';
import { MockAuthService } from './services/authService/mock-auth.service';
import { HttpAuthService } from './services/authService/http-auth.service';
import { MockAccountService } from './services/account/mock-account.service';
import { AccountService } from './services/account/account.service';
import { HttpAccountService } from './services/account/http-account.service';
import { LookupService } from './services/lookup/lookup.service';
import { MockLookupService } from './services/lookup/mock-lookup.service';
import { OperatorPaymentService } from './services/operator-payment/operator-payment.service';
import { MockOperatorPaymentService } from './services/operator-payment/mock-operator-payment.service';
import { HttpLookupService } from './services/lookup/http-lookup.service';
import { HttpOperatorPaymentService } from './services/operator-payment/http-operator-payment.service';
import { PropertyService } from './services/property/property.service';
import { MockPropertyService } from './services/property/mock-property.service';
import { HttpPropertyService } from './services/property/http-property.service';
import { PerformanceService } from './services/performance/performance.service';
import { MockPerformanceService } from './services/performance/mock-performance.service';
import { HttpPerformanceService } from './services/performance/http-performance.service';
import { AzureAuthModule } from '../modules/azure-auth/azure-auth.module';
import { msalConfig } from '../auth-config';
import { MarketService } from './services/market/market.service';
import { MockMarketService } from './services/market/mock-market.service';
import { HttpMarketService } from './services/market/http-market.service';
import { ProductService } from './services/product/product.service';
import { MockProductService } from './services/product/mock-product.service';
import { HttpProductService } from './services/product/http-product.service';

@NgModule({
  declarations: [],
  imports: [
    ...environment.mock ? [
      CommonModule
    ] : [
      CommonModule,
      AzureAuthModule.forRoot({
        configuration: msalConfig,
        scopes: []
      })
    ]
  ],
  providers: environment.mock ? [
    {provide: AuthService, useClass: MockAuthService},
    {provide: AccountService, useClass: MockAccountService},
    {provide: LookupService, useClass: MockLookupService},
    {provide: OperatorPaymentService, useClass: MockOperatorPaymentService},
    {provide: PropertyService, useClass: MockPropertyService},
    {provide: PerformanceService, useClass: MockPerformanceService},
    {provide: MarketService, useClass: MockMarketService},
    {provide: ProductService, useClass: MockProductService}
  ] : [
    {provide: AuthService, useClass: HttpAuthService},
    {provide: AccountService, useClass: HttpAccountService},
    {provide: LookupService, useClass: HttpLookupService},
    {provide: OperatorPaymentService, useClass: HttpOperatorPaymentService},
    {provide: PropertyService, useClass: HttpPropertyService},
    {provide: PerformanceService, useClass: HttpPerformanceService},
    {provide: MarketService, useClass: HttpMarketService},
    {provide: ProductService, useClass: HttpProductService}
  ]
})
export class CoreModule { }
