import { Injectable } from '@angular/core';
import { AccountService } from './account.service';
import { Observable, of } from 'rxjs';
import { Account, AccountSummary } from '../../../model/account';
import { accounts } from '../../../mockDB/accounts';
import { getRandomInt } from '../../../mockDB/helpers/randomNumbersHelper';
import { PayingOperator } from '../../../model/operator';

@Injectable({
  providedIn: 'root'
})
export class MockAccountService extends AccountService {

  constructor() {
    super();
  }

  getAccountManagerAccounts(): Observable<Account[]> {
    return of([accounts[getRandomInt(0, 4)], accounts[getRandomInt(0, 4)], accounts[getRandomInt(0, 4)]])
  }

  getAccount(accountId: number): Observable<Account> {
    return of(accounts[getRandomInt(0, 4)]);
  }

  getAccountManagerSummary(): Observable<AccountSummary> {
    return of({
      noAccounts: 3,
      noOperators: 2,
      noWells: 4,
      noProperties: 3
    });
  }

  getAccountSummary(accountId: number): Observable<AccountSummary> {
    return of({
      noAccounts: 3,
      noOperators: 2,
      noWells: 4,
      noProperties: 3
    });
  }

  getAccountPayingOperators(accountId: number): Observable<PayingOperator[]> {
    return of([{
      id: 1,
      name: 'Operator 1',
      contactInfo: 'Operator 1 contact info',
      logo: 'Operator 1 logo',
      operatorCode: 'OP1'
    }]);
  }

  getAccountManagerPayingOperators(): Observable<PayingOperator[]> {
    return of([{
      id: 1,
      name: 'Operator 1',
      contactInfo: 'Operator 1 contact info',
      logo: 'Operator 1 logo',
      operatorCode: 'OP1'
    }]);
  }

}
