<div class="content_body">
  <div class="stmnt_content">
    <div id="client_view_check" class="view_container">
      <!-- Check Details -->
      <div class="scroll_container" style="height: 300px;">
        <table class="table_grid no_wrap">
          <thead>
            <tr>
              <th><div class="table_header">Payment Date</div></th>
              <th><div class="table_header">Operator</div></th>
              <th><div class="table_header">Payment Status</div></th>
              <th><div class="table_header">Payment Type</div></th>
              <th><div class="table_header">Check #</div></th>
              <th><div class="table_header">No. Properties</div></th>
              <th><div class="table_header">No. Transactions</div></th>
              <th><div class="table_header">Amount</div></th>
            </tr>
          </thead>
          <tbody *ngIf="!pageLoading">
            <tr class="pointer" *ngFor="let payment of payments; let i = index" (click)="navigateToPaymentDetails(payment)" >
              <td>{{ payment?.paymentDate | date:'dd MMM yyyy' }}</td>
              <td>{{ payment?.operator?.name }}</td>
              <td>{{ payment?.status?.name }}</td>
              <td>{{ payment?.paymentType?.name }}</td>
              <td>{{ payment?.checkNo }}</td>
              <td align="center">{{ payment?.royaltyPayments?.length }}</td>
              <td align="center">{{ payment?.royaltyPayments?.length }}</td>
              <td align="right">
                $
                {{
                  payment?.checkAmount | number: "1.2-2":"en-US"
                }}
              </td>
            </tr>
          </tbody>
          <app-spinner *ngIf="pageLoading" [chartType]="'account-payments'"></app-spinner>
        </table>
      </div>

      <div class="stmnt_footer">
        <div class="stmnt_footer_inner">
          <app-export-footer
              [paymentsSummary]="paymentsSummary"
              [payments]="payments"
              [statements]="statements"
            ></app-export-footer>
          <app-statement-footer
              [currentTotal]="currentTotal"
            ></app-statement-footer>
        </div>
      </div>

    </div>
  </div>
</div>
