import { Payment } from '../../model/payment';
import { FormControl, FormGroup, ReactiveFormsModule, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { CommonModule, Location, DatePipe } from '@angular/common';
import { Account } from 'src/app/model/account';
import { catchError, finalize, takeUntil } from 'rxjs/operators';
import { Subject, of } from 'rxjs';
import { AlertComponent } from '../alert/alert.component';
import { WindowModule } from '@progress/kendo-angular-dialog';
import { AccountPaymentGridComponent } from '../account-payment-grid/account-payment-grid.component';
import { ChangeDetectorRef } from '@angular/core';
import { AccountService } from 'src/app/core/services/account/account.service';
import { OperatorPaymentService } from '../../core/services/operator-payment/operator-payment.service';
import { HttpErrorResponse } from '@angular/common/http';
import { DashboardSummaryComponent } from '../dashboard/dashboard-summary/dashboard-summary';
import { Operator, PayingOperator } from '../../model/operator';
import { Property } from '../../model/property';
import { SpinnerComponent } from '../spinner/spinner.component';
import { StorageService } from '../../core/services/storage/storage-service.service';
import { PropertyService } from '../../core/services/property/property.service';

@Component({
  selector: 'app-account-details',
  templateUrl: './account-details.component.html',
  styleUrls: ['./account-details.component.css'],
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    AlertComponent,
    WindowModule,
    AccountPaymentGridComponent,
    DashboardSummaryComponent,
    RouterModule,
    SpinnerComponent
  ],
  providers: [DatePipe]
})
export class AccountDetailsComponent implements OnInit {
  @ViewChild('pageSizeSelect', { static: true }) pageSizeSelect: any;

  public paymentForm: UntypedFormGroup = this.fb.group({
    operator: 0,
    month: this.datePipe.transform(new Date(), 'yyyyMM'),
    searchStr: [''],
    pageSize: 12
  });

  public account: Account;
  public allPayments: Payment[] = [];
  public payments: Payment[] = [];
  public allOperatorPayments: Payment[] = [];
  public operatorPayments: Payment[] = [];
  public paymentTotal: 0.00;
  public severanceTotal: 0.00;
  public accountForm: FormGroup;
  public selectedMonth: string = 'xxx';
  public selectedOperator: number = -1;
  public selectedPayment: Payment;
  public pageNo: number = 0;
  public operatorCount: number;
  public propertyCount: number;
  public wellCount: number;
  public properties: Property[];
  public months: string[];
  public summaryTotal: number;
  public chartType: 'account-details-payments';
  public accountProperties: Property[];
  public lastPage = false;
  public loadingMore = false;
  public currentTotal: number;
  public pageNumbers: number[] = [];
  public pageLoading: boolean = false;
  public operatorId: number = 0;
  public currentPage: number = 1;
  public currentPageAll: number = 1;
  public currentPageOperator: number = 1;
  public operators: PayingOperator[] = [];

  private lastOperatorId: number = 0;
  private pageNumbersAll: number[] = [];
  private pageNumbersOperator: number[] = [];
  private pageSize: number = 12;
  private unsubscribe = new Subject<void>();
  private accountId: number;

  constructor(
    private fb: UntypedFormBuilder,
    private paymentService: OperatorPaymentService,
    private accountService: AccountService,
    private storageService: StorageService,
    private location: Location,
    private datePipe: DatePipe,
    private cdr: ChangeDetectorRef,
    private router: Router,
    private propertyService: PropertyService
    ) {
  }

  ngOnInit() {
    this.accountForm = new FormGroup({
      month_filter: new FormControl('XXX'),
      operator_filter: new FormControl(-1),
      property_filter: new FormControl(-1)
    });
    this.accountForm.get('month_filter').valueChanges.subscribe(value => {
        this.selectedMonth = value;
      }
    );
    this.accountForm.get('operator_filter').valueChanges.subscribe(value => {
        this.selectedOperator = value;
        this.cdr.detectChanges();
      }
    );

    this.pageNumbers.push(1);
    if (this.storageService.getCurrentAccountId()) {
      this.accountId = this.storageService.getCurrentAccountId();
      this.selectedMonth = 'XXX';
      this.selectedOperator = -1;
      this.getAccount(this.accountId);
      this.getPaginatedAccountPayments();
      this.setTotalRecordsAccount();
      this.getCounts();
      this.getOperators();
    } else {
      console.info('No account id found');
      this.router.navigate(['/dashboard']);
    }

  }

  // Get methods

  get totalPages(): number {
    if (this.operatorId == 0) {
      return Math.ceil(this.storageService.getAccountPaymentsByPageNoRecords() / this.pageSize) + 1;
    } else {
      return Math.ceil(this.storageService.getAccountPaymentsByOperatorByPageNoRecords() / this.pageSize) + 1;
    }
  }

  // Public methods

  public trackByOperatorId(index: number, item: any) {
    return item.id;
  }

  public onOperatorChange(operatorId: number) {
    this.operatorId = operatorId;
    if (this.lastOperatorId !== operatorId) {
      this.storageService.resetAccountOperatorPayments();
      this.allOperatorPayments = [];
    }
    this.pageNo = 0;
    if (operatorId == 0) {
      this.payments = this.allPayments;
      this.pageNumbers = this.pageNumbersAll;
      this.currentPage = this.currentPageAll;
    } else {
      if (this.lastOperatorId == operatorId) {
        this.payments = this.allOperatorPayments;
        this.pageNumbers = this.pageNumbersOperator;
        this.currentPage = this.currentPageOperator;
      } else {
        this.lastOperatorId = operatorId;
        this.getPaginatedAccountPaymentsByOperator();
        this.currentPage = 1;
        this.currentPageOperator = 1;
        this.setTotalRecordsAccountByOperator();
      }
    }
  }

  public getMonths(payments: Payment[]) {
    return payments.reduce((unique, payment) => {
      const transformedDate = this.datePipe.transform(new Date(payment.paymentDate), 'MMM yyyy');
      if (unique.findIndex(date => date === transformedDate) === -1) {
        unique.push(transformedDate);
      }
      return unique;
    }, []);
  }

  private getOperators(): void{
    this.accountService.getAccountPayingOperators(this.accountId).pipe(
      takeUntil(this.unsubscribe)
    ).subscribe((operators: PayingOperator[]) => {
      this.operators = operators;
    });
  }

  private getAccount(ownerId) {
    this.accountService.getAccount(ownerId)
      .pipe(
        takeUntil(this.unsubscribe)
      )
      .subscribe((account: Account) => {
        this.account = account;
      })
  }

  public navigateToPropertyList(){
    this.getAccountProperties();
    this.router.navigate(['/accountProperties']);
  }

  public onBack() {
    this.location.back();
  }

  public onPageSizeChange() {
    this.pageSize = this.pageSizeSelect.nativeElement.value;
    this.pageNo = 0;
    if (this.operatorId == 0) {
      this.getPaginatedAccountPayments();
    } else {
      this.getPaginatedAccountPaymentsByOperator();
    }
    this.currentPage = 1;
    this.currentPageAll = 1;
    this.currentPageOperator = 1;
    this.setTotalRecordsAccount();
    this.setTotalRecordsAccountByOperator();
  }

  public goToFirstPage() {
    this.currentPage = 1;
    this.pageNo = 0;
    if (this.operatorId == 0) {
      this.currentPageAll = this.currentPage;
      this.payments = this.storageService.getAccountPaymentsByPage(this.pageNo);
    } else {
      this.currentPageOperator = this.currentPage;
      this.payments = this.storageService.getAccountPaymentsByOperatorByPage(this.pageNo);
    }
  }

  public goToLastPage() {
    this.currentPage = this.totalPages;
    this.pageNo = this.totalPages - 1;
    if (this.operatorId == 0) {
      this.currentPageAll = this.currentPage;
      if (this.storageService.getAccountPaymentsByPage(this.pageNo)) {
        this.payments = this.storageService.getAccountPaymentsByPage(this.pageNo);
      }
      this.getPaginatedAccountPayments();
    } else {
      this.currentPageOperator = this.currentPage;
      if ((this.lastOperatorId == this.operatorId) && this.storageService.getAccountPaymentsByOperatorByPage(this.pageNo)) {
        this.payments = this.storageService.getAccountPaymentsByOperatorByPage(this.pageNo);
      } else {
        this.getPaginatedAccountPaymentsByOperator();
      }
    }
  }

  public goToNextPage() {
      this.pageNo += 1;
      this.currentPage = this.pageNo + 1;
      if (!this.pageNumbers.includes(this.pageNo + 1)) {
        this.pageNumbers.push(this.pageNo + 1);
      }
      if (this.operatorId == 0) {
        this.currentPageAll = this.currentPage;
        if (this.storageService.getAccountPaymentsByPage(this.pageNo)) {
          this.payments = this.storageService.getAccountPaymentsByPage(this.pageNo);
        } else {
          this.getPaginatedAccountPayments();
        }
      } else {
        this.currentPageOperator = this.currentPage;
        if (this.operatorId === this.lastOperatorId) {
          if (this.storageService.getAccountPaymentsByOperatorByPage(this.pageNo)) {
            this.payments = this.storageService.getAccountPaymentsByOperatorByPage(this.pageNo);
          } else {
            this.getPaginatedAccountPaymentsByOperator();
          }
        } else {
          this.getPaginatedAccountPaymentsByOperator();
        }
      }
  }

  public goToPreviousPage() {
    this.pageNo -= 1;
    this.currentPage = this.pageNo + 1;
    if (this.operatorId === 0) {
      this.currentPageAll = this.currentPage;
      if (this.storageService.getAccountPaymentsByPage(this.pageNo)) {
        this.payments = this.storageService.getAccountPaymentsByPage(this.pageNo);
      } else {
        this.getPaginatedAccountPayments();
      }
    } else {
      this.currentPageOperator = this.currentPage;
      if (this.operatorId == this.lastOperatorId) {
        if (this.storageService.getAccountPaymentsByOperatorByPage(this.pageNo)) {
          this.payments = this.storageService.getAccountPaymentsByOperatorByPage(this.pageNo);
        } else {
          this.getPaginatedAccountPaymentsByOperator();
        }
      } else {
        this.getPaginatedAccountPaymentsByOperator();
      }

    }
  }

  // Private methods
  private getPaginatedAccountPayments() {
    this.pageLoading = true;
    return this.paymentService.getAccountPaymentsByPage(this.accountId, this.pageNo, this.pageSize)
    .pipe(
      takeUntil(this.unsubscribe),
      finalize(() => { this.pageLoading = false; }),
      catchError((error) => {
        console.error('Error fetching payments:', error);
        return [];
      })
    ).subscribe((payments) => {
      this.payments = payments;
      if (this.allPayments.length === 0) {
        this.allPayments = payments;
      } else {
        this.allPayments = [...this.allPayments, ...payments];
      }
      this.storageService.setAccountManagerPaymentsByOperatorByPage(this.pageNo, this.payments);
      this.currentTotal = this.getCurrentTotal();
    })
  }

  private getPaginatedAccountPaymentsByOperator() {
    this.pageLoading = true;
    return this.paymentService.getAccountManagerPaymentsByOperatorByPage(this.operatorId, this.pageNo, this.pageSize)
          .pipe(
            takeUntil(this.unsubscribe),
            finalize(() => this.pageLoading = false)
          ).subscribe((payments) => {
            this.payments = payments;
            if (this.allOperatorPayments.length === 0) {
              this.allOperatorPayments = payments;
            } else {
              this.allOperatorPayments = [...this.allOperatorPayments, ...payments];
            }
            this.storageService.setAccountPaymentsByOperatorByPage(this.pageNo, this.payments);
            this.currentTotal = this.getCurrentTotal();
          });
  }

  private getCurrentTotal() {
    return this.payments.reduce((total, payment) => total + payment.checkAmount, 0);
  }

  private setTotalRecordsAccount() {
    this.paymentService.getAccountPaymentsMoreRecords( this.accountId, 0, this.pageSize ).subscribe((totalRecords) => {
      this.storageService.setAccountPaymentsByPageNoRecords(totalRecords.noRecords);
    })
  }

  private setTotalRecordsAccountByOperator() {
    this.paymentService.getAccountPaymentsByOperatorMoreRecords( this.accountId, this.operatorId, 0, this.pageSize).subscribe((totalRecords) => {
      this.storageService.setAccountPaymentsByOperatorByPageNoRecords(totalRecords.noRecords);
    })
  }

  private getCounts() {
    this.accountService.getAccountSummary(this.accountId).subscribe((summary) => {
      this.operatorCount = summary.noOperators;
      this.propertyCount = summary.noProperties;
      this.wellCount = summary.noWells;
    });
  }

  private getAccountProperties() {
    this.propertyService.getAccountProperties(this.accountId).subscribe((properties) => {
      this.accountProperties = properties;
      this.storageService.setAccountProperties(properties);
    });
  }


}
