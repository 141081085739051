import { Component, Input } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { NgxChartsModule } from "@swimlane/ngx-charts";
import { trigger, transition, style, animate } from "@angular/animations";
import { GraphAndBarChartData } from "../../../../model/chart-data";
import { SpinnerComponent } from "../../../spinner/spinner.component";
import { NoDataComponent } from "../../../no-data/no-data.component";
import { StorageService } from "../../../../core/services/storage/storage-service.service";

@Component({
  selector: "app-graph-chart",
  standalone: true,
  templateUrl: "./graph-chart.component.html",
  styleUrls: ["./graph-chart.component.css"],
  imports: [
    CommonModule,
    FormsModule,
    NgxChartsModule,
    SpinnerComponent,
    NoDataComponent,
  ],
  animations: [
    trigger("animationState", [
      transition(":enter", [
        style({ opacity: 0 }),
        animate("500ms", style({ opacity: 1 })),
      ]),
      transition(":leave", [animate("500ms", style({ opacity: 0 }))]),
    ]),
  ],
})
export class GraphChartComponent {
  @Input() chartType?: string;
  @Input() breakdown?: string;
  @Input() isForHomePage?: boolean;

  public showXAxis: boolean = true;
  public showYAxis: boolean = true;
  public gradient: boolean = false;
  public showLegend: boolean = false;
  public legendPosition: string = "below";
  public showXAxisLabel: boolean = true;
  public showYAxisLabel: boolean = true;
  public trimXAxisTicks: boolean = true;
  public roundDomains: boolean = false;
  public showGridLines: boolean = true;
  public animations: boolean = true;
  public showRefLines: boolean = true;
  public showRefLabels: boolean = true;
  public rotateXAxisTicks: boolean = true;
  public showDataLabel: boolean = true;
  public showDataLabelOnAll: boolean = true;

  public graphAndBarChartData: GraphAndBarChartData[] = [];

  constructor(private storageService: StorageService) {}

  get isMobile() {
    return window.innerWidth <= 768;
  }

  get chartData() {
    switch (this.chartType) {
      case "revenueByProduct":
        if (this.isForHomePage) {
          return this.storageService.getAccountManagerPaymentSummaryByPeriodByProduct() as GraphAndBarChartData[];
        } else {
          return this.storageService.getAccountPaymentSummaryByPeriodByProduct() as GraphAndBarChartData[];
        }
      case "revenueByProperty":
        if (this.isForHomePage) {
          return this.storageService.getAccountManagerPaymentSummaryByPeriodByProperty() as GraphAndBarChartData[];
        } else {
          return this.storageService.getAccountPaymentSummaryByPeriodByProperty() as GraphAndBarChartData[];
        }
      default:
        break;
    }
    return
  }

  get dataValid() {
    switch (this.chartType) {
      case "revenueByProduct":
        if (this.isForHomePage) {
          return this.storageService.getAccountManagerPaymentSummaryByPeriodByProductValid();
        } else {
          return this.storageService.getAccountPaymentSummaryByPeriodByProductValid();
        }
      case "revenueByProperty":
        if (this.isForHomePage) {
          return this.storageService.getAccountManagerPaymentSummaryByPeriodByPropertyValid();
        } else {
          return this.storageService.getAccountPaymentSummaryByPeriodByPropertyValid();
        }
      default:
        break;
    }
    return
  }

  get loading() {
    return this.chartData.length <= 0;
  }

  get xAxisLabel() {
    switch (this.breakdown) {
      case "A":
        return "Year";
      case "M":
        return "Month";
      case "Q":
        return "Quarter";
      default:
        break;
    }
  }

  formatDollar(val: number): string {
    return '$' + val.toLocaleString();
  }
}
