
<div id="content">
  <!-- Pathway -->
  <div id="pathway">
    <div class="page_container">
      <div id="pathway_inner">
        <div class="pathway_left">
          <ul>
            <li>
              <button
                type="button"
                class="btn_normal btn_inverse_color btn_icon_only btn_no_border btn_no_padding"
                id="btn_back"
                title="Back"
                (click)="onBack()"
              >
                <span class="btn_icon"><span class="icon_back"></span></span>
                <span class="btn_text">Back</span>
              </button>
            </li>
            <li>
              <div class="pathway">
                <ul>
                  <li><span>Account Details</span></li>
                </ul>
              </div>
            </li>
          </ul>
        </div>
        <div class="pathway_right">
          <ul>
            <li>
              <form accept="#">
                <div class="form_row no_padding">
                  <div class="form_label hidden_label">
                    <label for="page_search">Search</label>
                  </div>
                  <div class="form_input">
                    <input
                      type="text"
                      class="inputbox searchbox padding_medium no_border"
                      id="page_search"
                      name="page_search"
                      placeholder="Search client’s names, property, lease, wells..."
                      style="width: 360px;"
                    />
                  </div>
                </div>
              </form>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>

  <app-dashboard-summary
    *ngIf="account" [account]="account"></app-dashboard-summary>
  <div class="summary-placeholder" *ngIf="!account">
    <app-spinner [chartType]="'payments'"></app-spinner>
  </div>
  <div class="page_container">
    <div id="content_inner">
      <div class="content_client">
        <div class="client_details_header">
          <div class="client_details_inner">
            <div class="client_card">
              <div class="client_card_icon">
                <span class="icon_profile"></span>
              </div>
              <div class="account_name">
                <div *ngIf="account?.accountOwnership?.[0]?.mineralRightsOwner?.ownername">
                  {{ account.accountOwnership[0].mineralRightsOwner.ownername }}
                </div>
                <div *ngIf="!account?.accountOwnership?.[0]?.mineralRightsOwner?.ownername && account?.accountOwnership?.[0]?.mineralRightsOwner?.fname && account?.accountOwnership?.[0]?.mineralRightsOwner?.lname">
                  {{ account.accountOwnership[0].mineralRightsOwner.fname }} {{ account.accountOwnership[0].mineralRightsOwner.lname }}
                </div>
              </div>
              <div class="client_card_title">Mineral Rights Owner</div>
            </div>
            <div class="client_links">
              <ul>
                <li>Operators ({{ operatorCount ?? 0 }})</li>
                <li><a class="link" (click)="navigateToPropertyList()">Properties ({{ propertyCount ?? 0 }})</a></li>
                <li>Wells ({{ wellCount ?? 0 }})</li>
              </ul>
            </div>
          </div>
        </div>

        <div class="client_details_body">
          <fieldset class="grouped no_border no_padding">
            <div class="data_group">
              <div class="data_row no_padding compact width_20">
                <div class="data_label">Phone</div>
                <div class="data_value">{{ account?.accountOwnership?.[0]?.mineralRightsOwner?.tel }}</div>
              </div>
              <div class="data_row no_padding compact width_25">
                <div class="data_label">Website</div>
                <div class="data_value">
                  <a href="javascript:void(0)">{{ account?.accountOwnership?.[0]?.mineralRightsOwner?.websiteUrl }}</a>
                </div>
              </div>
              <div class="data_row no_padding compact width_35">
                <div class="data_label">Address</div>
                <div class="data_value">
                  {{ account?.accountOwnership?.[0]?.mineralRightsOwner?.address1 }},
                  {{ account?.accountOwnership?.[0]?.mineralRightsOwner?.address2 }},
                  {{ account?.accountOwnership?.[0]?.mineralRightsOwner?.city }},
                  {{ account?.accountOwnership?.[0]?.mineralRightsOwner?.state.name }},
                  {{ account?.accountOwnership?.[0]?.mineralRightsOwner?.zipCode }}
                </div>
              </div>
            </div>
          </fieldset>
        </div>
      </div>

      <!-- Action Bar -->
      <div class="content_action_bar">
        <form [formGroup]="accountForm">
          <div class="action_bar_left">
            <ul>
              <li>
                <div class="form_row no_padding">
                  <div class="form_label hidden_label">
                    <label for="operator_list">Operators</label>
                  </div>
                  <div class="form_input">
                    <select
                      name="operator_list"
                      id="operator_list"
                      class="inputbox border"
                      formControlName="operator_filter"
                      (change)="onOperatorChange($event.target.value)"
                    >
                      <option [selected]="" value="-1">All Operators</option>
                      <option *ngFor="let operator of operators; trackBy: trackByOperatorId" value="{{ operator.id }}">
                        {{ operator.name }}
                      </option>
                    </select>
                  </div>
                </div>
              </li>

            </ul>
          </div>
        </form>
        <div class="action_bar_right">
          <form [formGroup]="paymentForm">
            <div class="page_selector">
              <label for="page_size">Results Per Page:</label>
              <select name="page_size"
                      id="page_size"
                      class="inputbox inputbox_filter"
                      formControlName="pageSize"
                      (change)="onPageSizeChange()"
                      #pageSizeSelect>
                <option value="12">12</option>
                <option value="25">25</option>
                <option value="50">50</option>
                <option value="100">100</option>
              </select>
            </div>
          </form>
        </div>
      </div>
      <app-account-payment-grid
          [payments]="payments"
          [summaryTotal]="summaryTotal"
          [account]="account"
          [loadingMore]="loadingMore"
          [lastPage]="lastPage"
          [currentTotal]="currentTotal"
          [pageLoading]="pageLoading"
        ></app-account-payment-grid>
        <div class="content_pagination flex_right_single_line">

          <div class="pagination_buttons">
            <button class="btn_pagination" (click)="goToFirstPage()" [disabled]="currentPage === 1"><<</button>
            <button class="btn_pagination" (click)="goToPreviousPage()" [disabled]="currentPage === 1"><</button>
            <div class="pagination_info">
              <span>Page {{ currentPage }} of {{ totalPages || 1 }}</span>
            </div>
            <button class="btn_pagination" (click)="goToNextPage()" [disabled]="currentPage === totalPages">></button>
            <button class="btn_pagination" (click)="goToLastPage()" [disabled]="currentPage === totalPages">>></button>
          </div>
        </div>
    </div>
  </div>
</div>
