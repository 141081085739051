import { Component, Input } from '@angular/core';
import { GraphAndBarChartData, PieChartData } from '../../../../model/chart-data';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { NoDataComponent } from '../../../no-data/no-data.component';
import { SpinnerComponent } from '../../../spinner/spinner.component';
import { StorageService } from '../../../../core/services/storage/storage-service.service';

@Component({
  selector: 'app-vertical-bar-chart',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    NgxChartsModule,
    SpinnerComponent,
    NoDataComponent
  ],
  templateUrl: './vertical-bar-chart.component.html',
  styleUrl: './vertical-bar-chart.component.css'
})
export class VerticalBarChartComponent {
  @Input() period?: string;
  @Input() chartType?: string;
  @Input() isDateRange?: boolean;
  @Input() isForHomePage?: boolean;

  barChartData: PieChartData[] = [];

  animations: boolean = true;
  showGridLines: boolean = true;
  roundDomains: boolean = false;
  rotateXAxisTicks: boolean = true;
  showLegend: boolean = true;
  legendPosition: string = 'right';
  showXAxis: boolean = true;
  showYAxis: boolean = true;

  constructor(private storageService: StorageService) { }

  get isMobile() {
    return window.innerWidth <= 768;
  }

  get graphAndBarChartData() {
    if (this.dataValid) {
      if (this.isForHomePage) {
        return this.storageService.getAccountManagerPaymentSummaryByPeriodDataObjects()
      } else {
        return this.storageService.getAccountPaymentSummaryByPeriodDataObjects()
      }
    }
    return
  }

  get dataValid() {
    if (this.isForHomePage) {
      return this.storageService.getAccountManagerPaymentSummaryByPeriodValid()
    } else {
      return this.storageService.getAccountPaymentSummaryByPeriodValid()
    }
  }

  get loading() {
    return this.graphAndBarChartData && this.graphAndBarChartData.length <= 0
    return
  }

  formatDollar(val: number): string {
    return '$' + val.toLocaleString();
  }

  getGrossAmount(data: GraphAndBarChartData[]): number {
    let total = 0;
    data.forEach(month => {
      month.series.forEach(item => {
        total += item.value;
      });
    });
    return total;
  }

  getLegendTitle(data: GraphAndBarChartData[]): string {
    return 'Gross Amount: ' + this.getGrossAmount(data);
  }

}

