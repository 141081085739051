<div class="widget_container quarter" id="widget_property_details">
  <div class="widget_inner">
    <div class="widget_header">
      <div class="widget_title">
        <h5>{{ well.name }}({{ well.apiNo }})</h5>
      </div>
      <div class="widget_actions">
      </div>
    </div>
    <div class="widget_content">
      <button
          class="btn_icon map-button"
          (click)="navigateToWellGIS(well.gisUrl)"
          [disabled]="!well.gisUrl"
        >
        <img src="../../../../assets/images/marker.svg" alt="Map Icon" class="icon_map" />
          <span>GIS URL</span>
        </button>
      <div class="data_list_container">
        <ul class="data_list">
          <li>
            <div class="data_list_label">Well Name</div>
            <div class="data_list_value">{{ well.name }}</div>
          </li>
          <li>
            <div class="data_list_label">Well Status</div>
            <div class="data_list_value">
              {{ well.status.name }}
            </div>
          </li>
          <li>
            <div class="data_list_label">Operator</div>
            <div class="data_list_value">
              {{ well.operator.name }}
            </div>
          </li>
          <li>
            <div class="data_list_label">API Serial Number</div>
            <div class="data_list_value">
              {{ well.apiNo }}
            </div>
          </li>
          <li>
            <div class="data_list_label">Spud Date</div>
            <div class="data_list_value">
              {{ well.spudDate | date: "dd MMM yyyy" }}
            </div>
          </li>
          <li>
            <div class="data_list_label">Completion Date</div>
            <div class="data_list_value">
              {{ well.completionDate | date: "dd MMM yyyy" }}
            </div>
          </li>
          <li>
            <div class="data_list_label">First Production Date</div>
            <div class="data_list_value">
              {{ well.firstProductionDate | date: "dd MMM yyyy" }}
            </div>
          </li>
        </ul>
        <ul class="data_list">
          <li>
            <div class="data_list_label">Total Vertical Depth</div>
            <div class="data_list_value">
              {{ well.totalVerticalDepth | number: "1.0-0" }} ft
            </div>
          </li>
          <li>
            <div class="data_list_label">Section</div>
            <div class="data_list_value">
              {{ well.geographicalData.section }}
            </div>
          </li>
          <li>
            <div class="data_list_label">Section #</div>
            <div class="data_list_value">
              {{ well.geographicalData.sectionNo }}
            </div>
          </li>
          <li>
            <div class="data_list_label">Township</div>
            <div class="data_list_value">
              {{ well.geographicalData.township }}
            </div>
          </li>
          <li>
            <div class="data_list_label">Range</div>
            <div class="data_list_value">
              {{ well.geographicalData.range }}
            </div>
          </li>
          <li>
            <div class="data_list_label">State</div>
            <div class="data_list_value">
              {{ well.geographicalData.county?.state?.name }}
            </div>
          </li>
          <li>
            <div class="data_list_label">County Parish</div>
            <div class="data_list_value">
              {{ well.geographicalData.county?.name }}
            </div>
        </ul>
        <ul class="data_list">
          <li>
            <div class="data_list_label">Survey Name</div>
            <div class="data_list_value">
              {{ well.geographicalData.surveyName }}
            </div>
          </li>
          <li>
            <div class="data_list_label">Abstract</div>
            <div class="data_list_value">
              {{ well.geographicalData.abstractData }}
            </div>
          </li>
          <li>
            <div class="data_list_label">Block</div>
            <div class="data_list_value">
              {{ well.geographicalData.block }}
            </div>
          </li>
          <li>
            <div class="data_list_label">Latitude</div>
            <div class="data_list_value">
              {{ well.latitude }}
            </div>
          </li>
          <li>
            <div class="data_list_label">Longitude</div>
            <div class="data_list_value">
              {{ well.longitude }}
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
