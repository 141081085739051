import { Title } from "../model/title";
import { properties } from "./properties";

export const title1: Title = {
  id: 1,
  assetNumber: "1",
  status: "Active",
  gisUrl: "https://www.google.com/maps/place/Oil+Derrick/@32.7580018,-97.0716665,19z/data=!4m14!1m7!3m6!1s0x864e8712d396477b:0x31cbae1314cedcdc!2sOil+Derrick!8m2!3d32.758056!4d-97.0711434!16s%2Fm%2F0c41syb!3m5!1s0x864e8712d396477b:0x31cbae1314cedcdc!8m2!3d32.758056!4d-97.0711434!16s%2Fm%2F0c41syb?entry=ttu",
  property: properties[0],
  insertBy: 1,
  insertDate: new Date('2020-01-01'),
  modifyBy: 1,
  modifyDate: new Date('2020-01-01')
};

export const title2: Title = {
  id: 2,
  assetNumber: "2",
  status: "Inactive",
  gisUrl: "https://www.google.com/maps/place/Oil+Derrick/@32.7580018,-97.0716665,19z/data=!4m14!1m7!3m6!1s0x864e8712d396477b:0x31cbae1314cedcdc!2sOil+Derrick!8m2!3d32.758056!4d-97.0711434!16s%2Fm%2F0c41syb!3m5!1s0x864e8712d396477b:0x31cbae1314cedcdc!8m2!3d32.758056!4d-97.0711434!16s%2Fm%2F0c41syb?entry=ttu",
  property: properties[1],
  insertBy: 2,
  insertDate: new Date('2020-02-01'),
  modifyBy: 2,
  modifyDate: new Date('2020-02-01')
};

export const title3: Title = {
  id: 3,
  assetNumber: "3",
  status: "Active",
  gisUrl: "https://www.google.com/maps/place/Oil+Derrick/@32.7580018,-97.0716665,19z/data=!4m14!1m7!3m6!1s0x864e8712d396477b:0x31cbae1314cedcdc!2sOil+Derrick!8m2!3d32.758056!4d-97.0711434!16s%2Fm%2F0c41syb!3m5!1s0x864e8712d396477b:0x31cbae1314cedcdc!8m2!3d32.758056!4d-97.0711434!16s%2Fm%2F0c41syb?entry=ttu",
  property: properties[2],
  insertBy: 3,
  insertDate: new Date('2020-03-01'),
  modifyBy: 3,
  modifyDate: new Date('2020-03-01')
};

export const title4: Title = {
  id: 4,
  assetNumber: "4",
  status: "Inactive",
  gisUrl: "https://www.google.com/maps/place/Oil+Derrick/@32.7580018,-97.0716665,19z/data=!4m14!1m7!3m6!1s0x864e8712d396477b:0x31cbae1314cedcdc!2sOil+Derrick!8m2!3d32.758056!4d-97.0711434!16s%2Fm%2F0c41syb!3m5!1s0x864e8712d396477b:0x31cbae1314cedcdc!8m2!3d32.758056!4d-97.0711434!16s%2Fm%2F0c41syb?entry=ttu",
  property: properties[3],
  insertBy: 4,
  insertDate: new Date('2020-04-01'),
  modifyBy: 4,
  modifyDate: new Date('2020-04-01')
};

export const title5: Title = {
  id: 5,
  assetNumber: "5",
  status: "Active",
  gisUrl: "https://www.google.com/maps/place/Oil+Derrick/@32.7580018,-97.0716665,19z/data=!4m14!1m7!3m6!1s0x864e8712d396477b:0x31cbae1314cedcdc!2sOil+Derrick!8m2!3d32.758056!4d-97.0711434!16s%2Fm%2F0c41syb!3m5!1s0x864e8712d396477b:0x31cbae1314cedcdc!8m2!3d32.758056!4d-97.0711434!16s%2Fm%2F0c41syb?entry=ttu",
  property: properties[4],
  insertBy: 5,
  insertDate: new Date('2020-05-01'),
  modifyBy: 5,
  modifyDate: new Date('2020-05-01')
};

export const title6: Title = {
  id: 6,
  assetNumber: "6",
  status: "Inactive",
  gisUrl: "https://www.google.com/maps/place/Oil+Derrick/@32.7580018,-97.0716665,19z/data=!4m14!1m7!3m6!1s0x864e8712d396477b:0x31cbae1314cedcdc!2sOil+Derrick!8m2!3d32.758056!4d-97.0711434!16s%2Fm%2F0c41syb!3m5!1s0x864e8712d396477b:0x31cbae1314cedcdc!8m2!3d32.758056!4d-97.0711434!16s%2Fm%2F0c41syb?entry=ttu",
  property: properties[5],
  insertBy: 6,
  insertDate: new Date('2020-06-01'),
  modifyBy: 6,
  modifyDate: new Date('2020-06-01')
};

export const title7: Title = {
  id: 7,
  assetNumber: "7",
  status: "Active",
  gisUrl: "https://www.google.com/maps/place/Oil+Derrick/@32.7580018,-97.0716665,19z/data=!4m14!1m7!3m6!1s0x864e8712d396477b:0x31cbae1314cedcdc!2sOil+Derrick!8m2!3d32.758056!4d-97.0711434!16s%2Fm%2F0c41syb!3m5!1s0x864e8712d396477b:0x31cbae1314cedcdc!8m2!3d32.758056!4d-97.0711434!16s%2Fm%2F0c41syb?entry=ttu",
  property: properties[6],
  insertBy: 7,
  insertDate: new Date('2020-07-01'),
  modifyBy: 7,
  modifyDate: new Date('2020-07-01')
};

export const title8: Title = {
  id: 8,
  assetNumber: "8",
  status: "Inactive",
  gisUrl: "https://www.google.com/maps/place/Oil+Derrick/@32.7580018,-97.0716665,19z/data=!4m14!1m7!3m6!1s0x864e8712d396477b:0x31cbae1314cedcdc!2sOil+Derrick!8m2!3d32.758056!4d-97.0711434!16s%2Fm%2F0c41syb!3m5!1s0x864e8712d396477b:0x31cbae1314cedcdc!8m2!3d32.758056!4d-97.0711434!16s%2Fm%2F0c41syb?entry=ttu",
  property: properties[7],
  insertBy: 8,
  insertDate: new Date('2020-08-01'),
  modifyBy: 8,
  modifyDate: new Date('2020-08-01')
};

export const title9: Title = {
  id: 9,
  assetNumber: "9",
  status: "Active",
  gisUrl: "https://www.google.com/maps/place/Oil+Derrick/@32.7580018,-97.0716665,19z/data=!4m14!1m7!3m6!1s0x864e8712d396477b:0x31cbae1314cedcdc!2sOil+Derrick!8m2!3d32.758056!4d-97.0711434!16s%2Fm%2F0c41syb!3m5!1s0x864e8712d396477b:0x31cbae1314cedcdc!8m2!3d32.758056!4d-97.0711434!16s%2Fm%2F0c41syb?entry=ttu",
  property: properties[8],
  insertBy: 9,
  insertDate: new Date('2020-09-01'),
  modifyBy: 9,
  modifyDate: new Date('2020-09-01')
};

export const title10: Title = {
  id: 10,
  assetNumber: "10",
  status: "Inactive",
  gisUrl: "https://www.google.com/maps/place/Oil+Derrick/@32.7580018,-97.0716665,19z/data=!4m14!1m7!3m6!1s0x864e8712d396477b:0x31cbae1314cedcdc!2sOil+Derrick!8m2!3d32.758056!4d-97.0711434!16s%2Fm%2F0c41syb!3m5!1s0x864e8712d396477b:0x31cbae1314cedcdc!8m2!3d32.758056!4d-97.0711434!16s%2Fm%2F0c41syb?entry=ttu",
  property: properties[9],
  insertBy: 10,
  insertDate: new Date('2020-10-01'),
  modifyBy: 10,
  modifyDate: new Date('2020-10-01')
};

export const title11: Title = {
  id: 11,
  assetNumber: "11",
  status: "Active",
  gisUrl: "https://www.google.com/maps/place/Oil+Derrick/@32.7580018,-97.0716665,19z/data=!4m14!1m7!3m6!1s0x864e8712d396477b:0x31cbae1314cedcdc!2sOil+Derrick!8m2!3d32.758056!4d-97.0711434!16s%2Fm%2F0c41syb!3m5!1s0x864e8712d396477b:0x31cbae1314cedcdc!8m2!3d32.758056!4d-97.0711434!16s%2Fm%2F0c41syb?entry=ttu",
  property: properties[10],
  insertBy: 11,
  insertDate: new Date('2020-11-01'),
  modifyBy: 11,
  modifyDate: new Date('2020-11-01')
};

export const title12: Title = {
  id: 12,
  assetNumber: "12",
  status: "Inactive",
  gisUrl: "https://www.google.com/maps/place/Oil+Derrick/@32.7580018,-97.0716665,19z/data=!4m14!1m7!3m6!1s0x864e8712d396477b:0x31cbae1314cedcdc!2sOil+Derrick!8m2!3d32.758056!4d-97.0711434!16s%2Fm%2F0c41syb!3m5!1s0x864e8712d396477b:0x31cbae1314cedcdc!8m2!3d32.758056!4d-97.0711434!16s%2Fm%2F0c41syb?entry=ttu",
  property: properties[11],
  insertBy: 12,
  insertDate: new Date('2020-12-01'),
  modifyBy: 12,
  modifyDate: new Date('2020-12-01')
};

export const title13: Title = {
  id: 13,
  assetNumber: "13",
  status: "Active",
  gisUrl: "https://www.google.com/maps/place/Oil+Derrick/@32.7580018,-97.0716665,19z/data=!4m14!1m7!3m6!1s0x864e8712d396477b:0x31cbae1314cedcdc!2sOil+Derrick!8m2!3d32.758056!4d-97.0711434!16s%2Fm%2F0c41syb!3m5!1s0x864e8712d396477b:0x31cbae1314cedcdc!8m2!3d32.758056!4d-97.0711434!16s%2Fm%2F0c41syb?entry=ttu",
  property: properties[12],
  insertBy: 13,
  insertDate: new Date('2021-01-01'),
  modifyBy: 13,
  modifyDate: new Date('2021-01-01')
};

export const title14: Title = {
  id: 14,
  assetNumber: "14",
  status: "Inactive",
  gisUrl: "https://www.google.com/maps/place/Oil+Derrick/@32.7580018,-97.0716665,19z/data=!4m14!1m7!3m6!1s0x864e8712d396477b:0x31cbae1314cedcdc!2sOil+Derrick!8m2!3d32.758056!4d-97.0711434!16s%2Fm%2F0c41syb!3m5!1s0x864e8712d396477b:0x31cbae1314cedcdc!8m2!3d32.758056!4d-97.0711434!16s%2Fm%2F0c41syb?entry=ttu",
  property: properties[13],
  insertBy: 14,
  insertDate: new Date('2021-02-01'),
  modifyBy: 14,
  modifyDate: new Date('2021-02-01')
};

export const title15: Title = {
  id: 15,
  assetNumber: "15",
  status: "Active",
  gisUrl: "https://www.google.com/maps/place/Oil+Derrick/@32.7580018,-97.0716665,19z/data=!4m14!1m7!3m6!1s0x864e8712d396477b:0x31cbae1314cedcdc!2sOil+Derrick!8m2!3d32.758056!4d-97.0711434!16s%2Fm%2F0c41syb!3m5!1s0x864e8712d396477b:0x31cbae1314cedcdc!8m2!3d32.758056!4d-97.0711434!16s%2Fm%2F0c41syb?entry=ttu",
  property: properties[14],
  insertBy: 15,
  insertDate: new Date('2021-03-01'),
  modifyBy: 15,
  modifyDate: new Date('2021-03-01')
};

export const title16: Title = {
  id: 16,
  assetNumber: "16",
  status: "Inactive",
  gisUrl: "https://www.google.com/maps/place/Oil+Derrick/@32.7580018,-97.0716665,19z/data=!4m14!1m7!3m6!1s0x864e8712d396477b:0x31cbae1314cedcdc!2sOil+Derrick!8m2!3d32.758056!4d-97.0711434!16s%2Fm%2F0c41syb!3m5!1s0x864e8712d396477b:0x31cbae1314cedcdc!8m2!3d32.758056!4d-97.0711434!16s%2Fm%2F0c41syb?entry=ttu",
  property: properties[15],
  insertBy: 16,
  insertDate: new Date('2021-04-01'),
  modifyBy: 16,
  modifyDate: new Date('2021-04-01')
};

export const title17: Title = {
  id: 17,
  assetNumber: "17",
  status: "Active",
  gisUrl: "https://www.google.com/maps/place/Oil+Derrick/@32.7580018,-97.0716665,19z/data=!4m14!1m7!3m6!1s0x864e8712d396477b:0x31cbae1314cedcdc!2sOil+Derrick!8m2!3d32.758056!4d-97.0711434!16s%2Fm%2F0c41syb!3m5!1s0x864e8712d396477b:0x31cbae1314cedcdc!8m2!3d32.758056!4d-97.0711434!16s%2Fm%2F0c41syb?entry=ttu",
  property: properties[16],
  insertBy: 17,
  insertDate: new Date('2021-05-01'),
  modifyBy: 17,
  modifyDate: new Date('2021-05-01')
};

export const title18: Title = {
  id: 18,
  assetNumber: "18",
  status: "Inactive",
  gisUrl: "https://www.google.com/maps/place/Oil+Derrick/@32.7580018,-97.0716665,19z/data=!4m14!1m7!3m6!1s0x864e8712d396477b:0x31cbae1314cedcdc!2sOil+Derrick!8m2!3d32.758056!4d-97.0711434!16s%2Fm%2F0c41syb!3m5!1s0x864e8712d396477b:0x31cbae1314cedcdc!8m2!3d32.758056!4d-97.0711434!16s%2Fm%2F0c41syb?entry=ttu",
  property: properties[17],
  insertBy: 18,
  insertDate: new Date('2021-06-01'),
  modifyBy: 18,
  modifyDate: new Date('2021-06-01')
};

export const titles: Title[] = [
  title1,
  title2,
  title3,
  title4,
  title5,
  title6,
  title7,
  title8,
  title9,
  title10,
  title11,
  title12,
  title13,
  title14,
  title15,
  title16,
  title17,
  title18
];