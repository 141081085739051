import { Product } from "../model/product";

export  const product1: Product = {
  id: 1,
  name: 'OIL',
  operatorId: 1,
  primaryId: 0
};

export  const product2: Product = {
  id: 2,
  name: 'GAS',
  operatorId: 2,
  primaryId: 0
};

export  const product3: Product = {
  id: 3,
  name: 'OTHER',
  operatorId: 3,
  primaryId: 0
};

export const product4: Product = {
  id: 4,
  name: 'COMBINATION',
  operatorId: 4,
  primaryId: 0
};

export const product5: Product = {
  id: 5,
  name: 'SPECIAL',
  operatorId: 5,
  primaryId: 0
};

export const product6: Product = {
  id: 6,
  name: 'SPECIAL',
  operatorId: 6,
  primaryId: 0
};

export const product7: Product = {
  id: 7,
  name: 'SPECIAL',
  operatorId: 7,
  primaryId: 0
};

export const product8: Product = {
  id: 8,
  name: 'SPECIAL',
  operatorId: 8,
  primaryId: 0
};

export const product9: Product = {
  id: 9,
  name: 'SPECIAL',
  operatorId: 9,
  primaryId: 0
};

export const product10: Product = {
  id: 10,
  name: 'SPECIAL',
  operatorId: 10,
  primaryId: 0
};

export const product11: Product = {
  id: 11,
  name: 'SPECIAL',
  operatorId: 11,
  primaryId: 0
};

export const product12: Product = {
  id: 12,
  name: 'SPECIAL',
  operatorId: 12,
  primaryId: 0
};

export const product13: Product = {
  id: 13,
  name: 'SPECIAL',
  operatorId: 13,
  primaryId: 0
};

export const product14: Product = {
  id: 14,
  name: 'SPECIAL',
  operatorId: 14,
  primaryId: 0
};

export const product15: Product = {
  id: 15,
  name: 'SPECIAL',
  operatorId: 15,
  primaryId: 0
};

export const product16: Product = {
  id: 16,
  name: 'SPECIAL',
  operatorId: 16,
  primaryId: 0
};

export const product17: Product = {
  id: 17,
  name: 'SPECIAL',
  operatorId: 17,
  primaryId: 0
};

export const product18: Product = {
  id: 18,
  name: 'SPECIAL',
  operatorId: 18,
  primaryId: 0
};

export const product19: Product = {
  id: 19,
  name: 'SPECIAL',
  operatorId: 19,
  primaryId: 0
};

export const product20: Product = {
  id: 20,
  name: 'SPECIAL',
  operatorId: 20,
  primaryId: 0
};

export const products: Product[] = [
  product1,
  product2,
  product3,
  product4,
  product5,
  product6,
  product7,
  product8,
  product9,
  product10,
  product11,
  product12,
  product13,
  product14,
  product15,
  product16,
  product17,
  product18,
  product19,
  product20
];
