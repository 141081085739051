<div id="dashboard_search">
  <div class="page_container">
    <div id="dashboard_search_inner">
      <div id="search_form">
        <div id="search_form_input">
          <div class="form_row no_padding">
            <div class="form_label hidden_label">
              <label for="dashboard_search_input">Search</label>
            </div>
            <div class="form_input">
              <input
                type="text"
                id="dashboard_search_input"
                name="dashboard_search_input"
                class="inputbox searchbox padding_large"
                placeholder="Search by account, property, well and more…"
                [(ngModel)]="searchKeyword"
                (input)="performSearch()"
              />
            </div>
          </div>
          <!-- Display search results for accounts -->
          <div id="search_form_results" *ngIf="searchKeyword && (accounts.length > 0 || properties.length > 0 || wells.length > 0)">
            <div id="search_form_results_inner">
              <div class="search_form_header">
                <h3 class="search_form_title">
                  Search Results
                  <span class="search_result_count">
                    ({{ accounts.length }} owners, {{ properties.length }} properties, {{ wells.length }} wells)
                  </span>
                </h3>
              </div>
              <div class="search_form_content">
                <ul id="results_list">
                  <!-- Display search results for accounts -->
                  <li *ngFor="let account of accounts">
                    <a (click)="navigateToAccountDetails(account.id)" class="result_link type_accounts">
                      <span class="result_icon"><span class="icon_owner"></span></span>
                      <span class="result_details">
                        <span class="result_name">{{ account.name }}</span>
                        <span class="result_id">{{ account.id }}</span>
                        <span class="result_error"></span>
                        <span class="result_address">
                          {{ account?.accountOwnership?.mineralRightsOwner?.address1 }},
                          {{ account?.accountOwnership?.mineralRightsOwner?.address2 }},
                          {{ account?.accountOwnership?.mineralRightsOwner?.city }},
                          {{ account?.accountOwnership?.mineralRightsOwner?.state }},
                          {{ account?.accountOwnership?.mineralRightsOwner?.zipCode }}
                        </span>
                      </span>
                    </a>
                  </li>
                  <!-- Display search results for properties -->
                  <li *ngFor="let property of properties">
                    <a (click)="navigateToPropertyDetails(property.id)" class="result_link type_property">
                      <span class="result_icon"><span class="icon_property"></span></span>
                      <span class="result_details">
                        <span class="result_name">{{ property?.name }}</span>
                        <span class="result_id">Property No: {{ property?.propertyNo }}</span>
                        <span class="result_error"></span>
                        <span class="result_address">
                          <span *ngIf="property?.geographicalData">
                            {{ property?.geographicalData?.county?.name }},
                            {{ property?.geographicalData?.section }},
                            {{ property?.geographicalData?.block }},
                            {{ property?.geographicalData?.township }},
                            {{ property?.geographicalData?.range }},
                            {{ property?.geographicalData?.surveyName }},
                            {{ property?.geographicalData?.abstract }},
                            Gross Acreage: {{ property?.geographicalData?.grossAcreage }},
                            Net Acreage: {{ property?.geographicalData?.netAcreage }}
                          </span>
                        </span>
                      </span>
                    </a>
                  </li>
                  <!-- Display search results for wells -->
                  <li *ngFor="let well of wells">
                    <a (click)="navigateToWellDetails(well.propertyId)" class="result_link type_well">
                      <span class="result_icon"><span class="icon_wells"></span></span>
                      <span class="result_details">
                        <span class="result_name">{{ well?.name }}</span>
                        <span class="result_id">Well No: {{ well?.apiNo }}</span>
                        <span class="result_error"></span>
                        <span class="result_address">
                          <span *ngIf="well?.geographicalData">
                            {{ well?.geographicalData?.county?.name }},
                            {{ well?.geographicalData?.section }},
                            {{ well?.geographicalData?.block }},
                            {{ well?.geographicalData?.township }},
                            {{ well?.geographicalData?.range }},
                            {{ well?.geographicalData?.surveyName }},
                            {{ well?.geographicalData?.abstract }},
                            API No: {{ well?.geographicalData?.apiNo }}
                          </span>
                        </span>
                      </span>
                    </a>
                  </li>

                </ul>
              </div>
            </div>
          </div>
        </div>

        <div class="search_actions">
          <div class="search_actions_left">

          </div>
          <div class="search_actions_right">

          </div>
        </div>
      </div>
    </div>
  </div>
</div>
