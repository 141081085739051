import { MineralRightsOwner } from "../model/account";

export const MRO1: MineralRightsOwner = {
  id: 1,
  ownername: 'Michael Wilderman',
  fname: 'Michael',
  lname: 'Wilderman',
  suffix: null,
  taxNumber: '0555-833-4211',
  address1: '123 Main St.',
  address2: 'Apt. 1',
  city: 'Karnes',
  state: null,
  zipCode: '12345',
  tel: '0555-833-4211',
  fax: '0556-605-7645',
  cell: '0790-737-6672',
  websiteUrl: null,
  status: 'Active',
  operatorMappings: [],
  insertBy: 0,
  insertDate: '2024-03-28 10:43:31 +00:00',
  modifyBy: 0,
  modifyDate: '2024-03-28 10:43:31 +00:00'
}

export const MRO2: MineralRightsOwner = {
  id: 2,
  ownername: 'Nathan Lee',
  fname: 'Nathan',
  lname: 'Lee',
  suffix: null,
  taxNumber: '0555-124-6227',
  address1: '123 Main St.',
  address2: 'Apt. 2',
  city: 'Lavaca',
  state: null,
  zipCode: '12345',
  tel: '0555-124-6227',
  fax: '0556-738-8259',
  cell: '0799-088-7009',
  websiteUrl: null,
  status: 'Active',
  operatorMappings: [],
  insertBy: 0,
  insertDate: '2024-03-28 10:43:31 +00:00',
  modifyBy: 0,
  modifyDate: '2024-03-28 10:43:31 +00:00'
}

export const MRO3: MineralRightsOwner = {
  id: 3,
  ownername: 'Mathew Curtis',
  fname: 'Mathew',
  lname: 'Curtis',
  suffix: null,
  taxNumber: '0555-240-0999',
  address1: '123 Main St.',
  address2: 'Apt. 3',
  city: 'Midland',
  state: null,
  zipCode: '12345',
  tel: '0555-240-0999',
  fax: '0556-778-5904',
  cell: '0796-319-7422',
  websiteUrl: null,
  status: 'Active',
  operatorMappings: [],
  insertBy: 0,
  insertDate: '2024-03-28 10:43:31 +00:00',
  modifyBy: 0,
  modifyDate: '2024-03-28 10:43:31 +00:00'
}

export const MRO4: MineralRightsOwner = {
  id: 4,
  ownername: 'Kristen Bayer',
  fname: 'Kristen',
  lname: 'Bayer',
  suffix: null,
  taxNumber: '0555-753-5402',
  address1: '321 Main St.',
  address2: 'Apt. 4',
  city: 'Loving',
  state: null,
  zipCode: '12345',
  tel: '0555-753-5402',
  fax: '0556-440-5837',
  cell: '0795-229-3589',
  websiteUrl: null,
  status: 'Active',
  operatorMappings: [],
  insertBy: 0,
  insertDate: '2024-03-28 10:43:31 +00:00',
  modifyBy: 0,
  modifyDate: '2024-03-28 10:43:31 +00:00'
}

export const MRO5: MineralRightsOwner = {
  id: 5,
  ownername: 'Keith Craven',
  fname: 'Keith',
  lname: 'Craven',
  suffix: null,
  taxNumber: '0555-106-4549',
  address1: '321 Main St.',
  address2: 'Apt. 5',
  city: 'Multnomah',
  state: null,
  zipCode: '12345',
  tel: '0555-106-4549',
  fax: '0556-955-4129',
  cell: '0791-751-1631',
  websiteUrl: null,
  status: 'Active',
  operatorMappings: [],
  insertBy: 0,
  insertDate: '2024-03-28 10:43:31 +00:00',
  modifyBy: 0,
  modifyDate: '2024-03-28 10:43:31 +00:00'
}

export const MRO6: MineralRightsOwner = {
  id: 6,
  ownername: 'Korey Rose',
  fname: 'Korey',
  lname: 'Rose',
  suffix: null,
  taxNumber: '0555-561-3172',
  address1: '321 Main St.',
  address2: 'Apt. 6',
  city: 'Anytown',
  state: null,
  zipCode: '12345',
  tel: '0555-561-3172',
  fax: '0556-902-5585',
  cell: '0790-757-5297',
  websiteUrl: null,
  status: 'Active',
  operatorMappings: [],
  insertBy: 0,
  insertDate: '2024-03-28 10:43:31 +00:00',
  modifyBy: 0,
  modifyDate: '2024-03-28 10:43:31 +00:00'
}

export const MRO7: MineralRightsOwner = {
  id: 7,
  ownername: 'Krystal Stephen',
  fname: 'Krystal',
  lname: 'Stephen',
  suffix: null,
  taxNumber: '0555-374-2842',
  address1: '456 Main St.',
  address2: 'Apt. 7',
  city: 'Anytown',
  state: null,
  zipCode: '12345',
  tel: '0555-374-2842',
  fax: '0556-297-6325',
  cell: '0792-459-4849',
  websiteUrl: null,
  status: 'Active',
  operatorMappings: [],
  insertBy: 0,
  insertDate: '2024-03-28 10:43:31 +00:00',
  modifyBy: 0,
  modifyDate: '2024-03-28 10:43:31 +00:00'
}

export const MRO8: MineralRightsOwner = {
  id: 8,
  ownername: 'Pat Henderson',
  fname: 'Pat',
  lname: 'Henderson',
  suffix: null,
  taxNumber: '0555-045-7712',
  address1: '123 Main St.',
  address2: 'Apt. 8',
  city: 'Anytown',
  state: null,
  zipCode: '12345',
  tel: '0555-045-7712',
  fax: '0556-720-2885',
  cell: '0792-541-8610',
  websiteUrl: null,
  status: 'Active',
  operatorMappings: [],
  insertBy: 0,
  insertDate: '2024-03-28 10:43:31 +00:00',
  modifyBy: 0,
  modifyDate: '2024-03-28 10:43:31 +00:00'
}

export const MRO9: MineralRightsOwner = {
  id: 9,
  ownername: 'Rose Perry',
  fname: 'Rose',
  lname: 'Perry',
  suffix: null,
  taxNumber: '0555-682-8290',
  address1: '543 Main St.',
  address2: 'Apt. 9',
  city: 'Midland',
  state: null,
  zipCode: '12345',
  tel: '0555-682-8290',
  fax: '0556-097-0013',
  cell: '0797-564-6804',
  websiteUrl: null,
  status: 'Active',
  operatorMappings: [],
  insertBy: 0,
  insertDate: '2024-03-28 10:43:31 +00:00',
  modifyBy: 0,
  modifyDate: '2024-03-28 10:43:31 +00:00'
}

export const MRO10: MineralRightsOwner = {
  id: 10,
  ownername: 'Tracy Cox',
  fname: 'Tracy',
  lname: 'Cox',
  suffix: null,
  taxNumber: '0555-709-5047',
  address1: '123 Main St.',
  address2: 'Apt. 10',
  city: 'Uptown',
  state: null,
  zipCode: '12345',
  tel: '0555-709-5047',
  fax: '0556-396-4672',
  cell: '0791-335-2343',
  websiteUrl: null,
  status: 'Active',
  operatorMappings: [],
  insertBy: 0,
  insertDate: '2024-03-28 10:43:31 +00:00',
  modifyBy: 0,
  modifyDate: '2024-03-28 10:43:31 +00:00'
}



export const MROs = [MRO1, MRO2, MRO3, MRO4, MRO5, MRO6, MRO7, MRO8, MRO9, MRO10];
