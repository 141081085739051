import { Component, Input } from "@angular/core";
import { AccountService } from "../../core/services/account/account.service";
import { Payment, RoyaltyPayment } from "../../model/payment";
import { Account, MineralRightsOwner } from "src/app/model/account";
import * as FileSaver from "file-saver";
import { Property } from "src/app/model/property";

@Component({
  selector: "app-export-footer",
  standalone: true,
  imports: [],
  templateUrl: "./export-footer.component.html",
  styleUrl: "./export-footer.component.css",
})
export class ExportFooterComponent {
  @Input() paymentsSummary?: any;
  @Input() payments?: Payment[];
  @Input() paymentDetail?: Payment;
  @Input() statements?: any;
  @Input() operatorPayments?: Payment[];
  @Input() accounts?: Account[];
  @Input() properties: Property[];
  constructor(private accountService: AccountService) { }


  exportPropertiesToCSV() {
    const csvData = this.convertPropertiesToCSV(this.properties);
    const blob = new Blob([csvData], { type: "text/csv;charset=utf-8" });
    const fileName = "properties_" + this.getFormattedDateTime() + ".csv";
    FileSaver.saveAs(blob, fileName);
  }

  private getFormattedDateTime(): string {
    const date = new Date();
    return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}_${date.getHours()}-${date.getMinutes()}-${date.getSeconds()}`;
  }

  // Helper function to convert properties to CSV format
  private convertPropertiesToCSV(properties: Property[]): string {
    const header = ["Description", "Property", "Name", "State", "County"].join(",");
    const rows = properties.map((property) => {
      return [
        property.description || "",
        property.propertyNo || "",
        property.name || "",
        property.geographicalData?.county?.state?.name || "",
        property.geographicalData?.county?.name || ""
      ].join(",");
    });
    return `${header}\n${rows.join("\n")}`;
  }

  exportAccountDetailsPayments() {
    const headers = [
      "Payment Date",
      "Operator",
      "Payment Status",
      "Payment Type",
      "Check #",
      "No. Properties",
      "No. Transactions",
      "Amount"
    ]

    const data = this.payments.map(payment => {
      return [
        payment.paymentDate,
        payment.operator.name,
        payment.status.name,
        payment.paymentType.name,
        payment.checkNo,
        payment.royaltyPayments.length,
        payment.royaltyPayments.length,
        payment.checkAmount
      ];
    });

    return `${headers.join(",")}\n${data.map(row => row.join(",")).join("\n")}`;

  }



  public exportaccounts() {
    const headers = [
      "Account",
      "Account Name",
      "Status",
      "Owner Name",
      "First Name",
      "Last Name",
      "Address1",
      "Address2",
      "City",
      "State",
      "Zipcode"
    ];

    const defaultMineralRightsOwner: MineralRightsOwner = {
      id: 0,
      ownername: '',
      fname: '',
      lname: '',
      suffix: '',
      taxNumber: '',
      address1: '',
      address2: '',
      city: '',
      state: null,
      zipCode: '',
      tel: '',
      fax: '',
      cell: '',
      email: '',
      websiteUrl: '',
      status: '',
      operatorMappings: [],
      insertBy: 0,
      insertDate: '',
      modifyBy: 0,
      modifyDate: ''
    };

    const data = this.accounts.map(account => {
      const mineralRightsOwner = account.accountOwnership?.mineralRightsOwner || defaultMineralRightsOwner;
      return [
        account.id || '',
        account.name || '',
        mineralRightsOwner.status || '',
        mineralRightsOwner.ownername || '',
        mineralRightsOwner.fname || '',
        mineralRightsOwner.lname || '',
        mineralRightsOwner.address1 || '',
        mineralRightsOwner.address2 || '',
        mineralRightsOwner.city || '',
        mineralRightsOwner.state.name || '',
        mineralRightsOwner.zipCode || ''
      ];
    });

    const csvData = `${headers.join(",")}\n${data.map(row => row.join(",")).join("\n")}`;
    const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8' });
    const fileName = 'accounts.csv';
    FileSaver.saveAs(blob, fileName);
  }


  public exportToCSV() {
    const date = new Date();
    const dateTimeFormat = `${date.getFullYear()}-${date.getMonth() + 1
      }-${date.getDate()}_${date.getHours()}-${date.getMinutes()}-${date.getSeconds()}`;

    if (this.operatorPayments) {
      const csvData = this.exportOperatorPayments();
      const blob = new Blob([csvData], { type: "text/csv;charset=utf-8" });
      const fileName = "operator_payments_" + dateTimeFormat + ".csv";
      FileSaver.saveAs(blob, fileName);
    } else if (this.paymentDetail) {
      const csvData = this.exportPaymentDetail();
      const blob = new Blob([csvData], { type: "text/csv;charset=utf-8" });
      const fileName = "payment_detail_" + dateTimeFormat + ".csv";
      FileSaver.saveAs(blob, fileName);
    } else if (this.accounts) {
      this.exportaccounts();
    } else if (this.properties) {
      this.exportPropertiesToCSV();
    } else if (this.payments) {
      const csvData = this.exportAccountDetailsPayments();
      const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8' });
      const fileName = "operator_payments_" + dateTimeFormat + ".csv"
      FileSaver.saveAs(blob, fileName);
    }
  }

  public exportOperatorPayments() {
    const headers = [
      "Payment Date",
      "Operator",
      "Payment Status",
      "Payment Type",
      "Account",
      "Check #",
      "No. Properties",
      "No. Transactions",
      "Amount",
    ];
    const data = this.operatorPayments.map((payment: Payment) => {
      return [
        payment.paymentDate,
        payment.operator.name,
        payment.status.name,
        payment.paymentType.name,
        payment.accountId,
        payment.checkNo,
        payment.royaltyPayments.length,
        payment.royaltyPayments.length,
        payment.checkAmount,
      ];
    });
    return `${headers}\n${data.join("\n")}`;
  }

  public exportPaymentDetail() {
    if (
      this.paymentDetail
    ) {
      const staticHeadersBefore = [
        "Issue Date",
        "Well",
        "Prod. Date",
        "Product",
        "Price",
        "Sales Volume",
        "Sales Gross Value",
      ];
      const staticHeadersAfterSales = [
        "Sales Net Value",
        "Interest Type",
        "Decimal",
        "Owner Volume",
        "Owner Gross Value",
      ];
      const staticHeadersAfterOwner = ["Amount"];

      const firstRoyaltyPayment = this.paymentDetail.royaltyPayments[0];
      const salesDeductionHeaders = Object.values(firstRoyaltyPayment.sale.deductionObjects).map(deduction => deduction.name);
      const ownerDeductioHeaders = Object.values(firstRoyaltyPayment.deductionObjects).map(deduction => deduction.name);

      const headers = [
        ...staticHeadersBefore,
        ...salesDeductionHeaders,
        ...staticHeadersAfterSales,
        ...ownerDeductioHeaders,
        ...staticHeadersAfterOwner,
      ];

      const data = this.paymentDetail.royaltyPayments.map((royaltyPayment: RoyaltyPayment) => {
        const { paymentDate, sale, divisionOrder, mroVolume, mroGrossValue, well, deductionObjects } = royaltyPayment;
        const { operator, prodDate, product, unitPrice, saleVolume, grossValue, netValue } = sale;
        const { title: { property: { name: propertyName } }, id: doi, interestType: { name: interestTypeName }, decimalInterest } = divisionOrder;

        const row = [
          paymentDate || '',
          well.name || '',
          prodDate || '',
          product.name || '',
          unitPrice || '',
          saleVolume || '',
          grossValue || '',
          ...Object.values(sale.deductionObjects).map(deduction => deduction.amount || ''),
          netValue || '',
          interestTypeName || '',
          decimalInterest || '',
          mroVolume || '',
          mroGrossValue || '',
          ...Object.values(deductionObjects).map(deduction => deduction.amount || ''),
          netValue || ''
        ];

        return row.join(",");
      });

      return `${headers.join(",")}\n${data.join("\n")}`;
    }
  }
}
