import { Injectable } from '@angular/core';
import { PerformanceService } from './performance.service';
import { Observable, of } from 'rxjs';
import { performances } from '../../../mockDB/performances';
import { PropertyPerformance } from '../../../model/performance';

@Injectable({
  providedIn: 'root'
})
export class MockPerformanceService extends PerformanceService {

  constructor() {
    super();
   }

   // eslint-disable-next-line @typescript-eslint/no-unused-vars
   getPropertyPerformance(): Observable<PropertyPerformance[]> {
    return of(performances)
  }
}

