<div id="mro_content">
  <div id="pathway">
    <div class="page_container">
      <div id="pathway_inner">
        <div class="pathway_left">
          <ul>
            <li>
              <button
                type="button"
                class="btn_normal btn_inverse_color btn_icon_only btn_no_border btn_no_padding"
                id="btn_back"
                title="Back"
                (click)="onBack()"
              >
                <span class="btn_icon"><span class="icon_back"></span></span>
                <span class="btn_text">Back</span>
              </button>
            </li>
            <li>
              <div class="pathway">
                <ul>
                  <li><span>Payment Details</span></li>
                </ul>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <div class="mobile_container">
    <div id="mro_recent_payments_inner">


      <div class="mro_content_inner">
        <div class="space_divider"></div>
        <!-- Grid Viewer -->
        <app-payment-grid
          [payment]="payment"
        ></app-payment-grid>
        <div class="stmnt_footer_inner">
           <app-payment-footer-paid
            [payment]="payment"
          ></app-payment-footer-paid>

          <app-export-footer
            [paymentDetail]="payment"
          ></app-export-footer>
        </div>

      </div>
    </div>
  </div>
</div>
