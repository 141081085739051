import { Component, OnInit } from '@angular/core';
import { AccountService } from '../../../core/services/account/account.service';
import { PropertyService } from '../../../core/services/property/property.service';
import { Subject, takeUntil, catchError, throwError } from 'rxjs';
import { MarketService } from '../../../core/services/market/market.service';
import { ResourcePriceData } from '../../../model/resourcePriceData';
import { HttpService } from '../../../core/services/httpService/http.service';
import { NewPaymentsComponent } from '../new-payments/new-payments.component';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-dashboard-widgets',
  standalone: true,
  imports: [
    NewPaymentsComponent,
    CommonModule
  ],
  templateUrl: './dashboard-widgets.component.html',
  styleUrls: ['./dashboard-widgets.component.css'],
})
export class DashboardWidgetsComponent implements OnInit {
  public crudeOilPriceDate: string;
  public crudeOilPrice: number;
  public crudeOilPriceChanged: number;
  public naturalGasPriceDate: string;
  public naturalGasPrice: number;
  public naturalGasPriceChanged: number;
  public Unsubscribe = new Subject();
  public resources = ['Crude Oil', 'Natural Gas'];
  public accountCount: number;
  public wellCount: number;
  public operatorCount: number;
  public propertyCount: number;

  private _accounts;
  private _inactiveAccountCount;
  private _activeAccountCount;

  constructor(
    private accountService: AccountService,
    private marketService: MarketService,
    private httpService: HttpService
  ) { }

  ngOnInit() {
    this.httpService.token$.subscribe(token => {
      if (token) {
        this.getAccountManagerSummary();
        this.getMarketPrices();
      }
    });
  }

  get accounts() {
      this._accounts = this.accountService.getAccounts();
      return this._accounts;
  }

  get inactiveAccountCount() {
    if (!this._inactiveAccountCount) {
      this._inactiveAccountCount = this.accounts.filter(account => account.status !== 'OPEN').length;
    }
    return this._inactiveAccountCount;
  }

  get activeAccountCount() {
    if (!this._activeAccountCount) {
      this._activeAccountCount = this.accounts.filter(account => account.status === 'OPEN').length;
    }
    return this._activeAccountCount;
  }

  public getMarketPrices() {
    for (const resource of this.resources) {
      this.marketService.getMarketPrice(resource)
        .pipe(
          takeUntil(this.Unsubscribe),
          catchError((error) => {
            console.error('getMarketPrice:Error: ' + JSON.stringify(error));
            return throwError(error);
          })
        )
        .subscribe(
          (response: ResourcePriceData) => {
            if (response) {
              if (resource === 'Crude Oil') {
                this.crudeOilPriceDate = response.readingDateTime;
                this.crudeOilPrice = response.price;
                this.crudeOilPriceChanged = response.difference;
              } else if (resource === 'Natural Gas') {
                this.naturalGasPriceDate = response.readingDateTime;
                this.naturalGasPrice = response.price;
                this.naturalGasPriceChanged = response.difference;
              }
            }
          }
        );
    }
  }

  private getAccountManagerSummary() {
    this.accountService.getAccountManagerSummary()
      .pipe(
        takeUntil(this.Unsubscribe),
        catchError((error) => {
          console.error('getAccountSummary:Error: ' + JSON.stringify(error));
          return throwError(error);
        })
      )
      .subscribe(
        (response) => {
          if (response) {
            this.operatorCount = response.noOperators;
            this.wellCount = response.noWells;
            this.propertyCount = response.noProperties;
          }
        }
      );
  }


}
