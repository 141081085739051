  <div style="max-height: 450px; top: 50%; left: 50%" *ngIf="loading">
    <app-spinner [chartType]="'account-details-payments'"></app-spinner>
  </div>
  <div class="row tables-wrapper" *ngIf="!loading">
    <div class="col-md-6">
      <table>
        <tr>
          <td>No. of Properties</td>
        </tr>
        <tr>
          <td>
            <h5>{{ chartData.noProperties ? chartData.noProperties : '0' }}</h5>
          </td>
        </tr>
        <tr>
          <td>Gross Royalties</td>
        </tr>
        <tr>
          <td>
              <h5>{{ chartData.grossAmount ? (chartData.grossAmount | currency) : '$0.00' }}</h5>
          </td>
        </tr>
        <tr>
          <td>Net Royalties</td>
        </tr>
        <tr>
          <td>
            <h5>{{ chartData.netAmount ? (chartData.netAmount | currency) : '$0.00' }}</h5>
          </td>
        </tr>
      </table>
    </div>
    <div class="col-md-6">
      <table>
        <!-- <tr>
          <td>No. of Checks</td>
        </tr> -->
        <tr>
          <td>
            <!-- <h5>{{ chartData.noChecks ? chartData.noChecks : '0' }}</h5> -->
          </td>
        </tr>
        <tr>
          <td>Severance</td>
        </tr>
        <tr>
          <td>
            <h5>{{ chartData.severance ? (chartData.severance | currency) : '$0.00'}}</h5>
          </td>
        </tr>
        <tr>
          <td>Deductions</td>
        </tr>
        <tr>
          <td>
            <h5>{{ chartData.deductions ? (chartData.deductions | currency) : '$0.00' }}</h5>
          </td>
        </tr>
      </table>
    </div>
  </div>

