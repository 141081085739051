import { Bank } from "../model/bank";
import { randomDateMinOrMax } from "./helpers/randomDateHelper";

export const bank1: Bank = {
  id: 1,
  name: 'Bank of America',
  participating: 'Yes',
  logoPath: '',
  addr1: '100 N Tryon St',
  addr2: '',
  city: 'Charlotte',
  stateCode: 'NC',
  zip: '28202',
  tel: '704-386-5681',
  fax: '',
  email: 'bankofamerica@test.lan',
  insertBy: 1,
  insertDate: randomDateMinOrMax(2010, 2020, false),
  modifiedBy: 1,
  modifiedDate: randomDateMinOrMax(2010, 2020, true)
};

export const bank2: Bank = {
  id: 2,
  name: 'Wells Fargo',
  participating: 'Yes',
  logoPath: '',
  addr1: '301 S Tryon St',
  addr2: '',
  city: 'Charlotte',
  stateCode: 'NC',
  zip: '28202',
  tel: '704-386-5681',
  fax: '',
  email: 'wellsfargo@test.lan',
  insertBy: 1,
  insertDate: randomDateMinOrMax(2010, 2020, false),
  modifiedBy: 1,
  modifiedDate: randomDateMinOrMax(2010, 2020, true)
};

export const bank3: Bank = {
  id: 3,
  name: 'Truist',
  participating: 'Yes',
  logoPath: '',
  addr1: '214 N Tryon St',
  addr2: '',
  city: 'Charlotte',
  stateCode: 'NC',
  zip: '28202',
  tel: '704-386-5681',
  fax: '',
  email: 'truist@test.lan',
  insertBy: 1,
  insertDate: randomDateMinOrMax(2010, 2020, false),
  modifiedBy: 1,
  modifiedDate: randomDateMinOrMax(2010, 2020, true)
};

export const bank4: Bank = {
  id: 4,
  name: 'BB&T',
  participating: 'Yes',
  logoPath: '',
  addr1: '200 S College St',
  addr2: '',
  city: 'Charlotte',
  stateCode: 'NC',
  zip: '28202',
  tel: '704-386-5681',
  fax: '',
  email: 'bb&t@test.lan',
  insertBy: 1,
  insertDate: randomDateMinOrMax(2010, 2020, false),
  modifiedBy: 1,
  modifiedDate: randomDateMinOrMax(2010, 2020, true)
};

export const bank5: Bank = {
  id: 5,
  name: 'First Citizens Bank',
  participating: 'Yes',
  logoPath: '',
  addr1: '128 S Tryon St',
  addr2: '',
  city: 'Charlotte',
  stateCode: 'NC',
  zip: '28202',
  tel: '704-386-5681',
  fax: '',
  email: 'firstcitizensbank@test.lan',
  insertBy: 1,
  insertDate: randomDateMinOrMax(2010, 2020, false),
  modifiedBy: 1,
  modifiedDate: randomDateMinOrMax(2010, 2020, true)
};

export const bank6: Bank = {
  id: 6,
  name: 'PNC Bank',
  participating: 'Yes',
  logoPath: '',
  addr1: '201 S College St',
  addr2: '',
  city: 'Charlotte',
  stateCode: 'NC',
  zip: '28202',
  tel: '704-386-5681',
  fax: '',
  email: 'pncbank@test.lan',
  insertBy: 1,
  insertDate: randomDateMinOrMax(2010, 2020, false),
  modifiedBy: 1,
  modifiedDate: randomDateMinOrMax(2010, 2020, true)
};

export const banks = [bank1, bank2, bank3, bank4, bank5, bank6];
