import { Component, OnInit } from '@angular/core';
import { Account, MineralRightsOwner } from '../../../model/account';
import { Property } from '../../../model/property';
import { Router } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { AccountService } from 'src/app/core/services/account/account.service';
import { PropertyService } from 'src/app/core/services/property/property.service';
import { StorageService } from '../../../core/services/storage/storage-service.service';
import { Well } from '../../../model/well';

@Component({
  selector: 'app-dashboard-search',
  standalone: true,
  imports: [
    FormsModule,
    CommonModule
  ],
  templateUrl: './dashboard-search.component.html',
  styleUrls: ['./dashboard-search.component.css']
})
export class DashboardSearchComponent implements OnInit {
  public accounts: Account[] = [];
  public properties: Property[] = [];
  public wells: Well[] = [];
  public searchKeyword: string = '';

  constructor(
    private accountService: AccountService,
    private propertyService: PropertyService,
    private storageService: StorageService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.getAccounts();
    this.getProperties();
  }

  getAccounts(): void {
    this.accountService.getAccountManagerAccounts().subscribe(
      (data: Account[]) => {
        this.accounts = data;
      },
      (error) => {
        console.error('Error fetching accounts:', error);
      }
    );
  }

  getProperties(): void {
    this.propertyService.getAccountManagerProperties().subscribe(
      (data: Property[]) => {
        this.properties = data;
        this.getWellsFromProperties(this.properties);
      },
      (error) => {
        console.error('Error fetching properties:', error);
      }
    );

  }

  private getWellsFromProperties(properties: Property[]): void {
    properties.forEach(property => {
      property.wells.forEach(well => {
        well.propertyId = property.id;
      });
      this.wells = [...this.wells, ...property.wells];
    });
  }

  performSearch(): void {
    if (!this.searchKeyword.trim()) {
      this.getAccounts();
      this.getProperties();
      return;
    }

    const searchKeywordLower = this.searchKeyword.toLowerCase();

    this.accounts = this.accounts.filter(account => {
      return (
        account.name.toLowerCase().includes(searchKeywordLower) ||
        (account.id && account.id.toString().includes(searchKeywordLower)) ||
        this.searchMineralRightsOwner(account.accountOwnership?.mineralRightsOwner)
      );
    });

    this.properties = this.properties.filter(property => {
      return (
        property.name.toLowerCase().includes(searchKeywordLower) ||
        property.propertyNo.toLowerCase().includes(searchKeywordLower) ||
        property.geographicalData?.county.name.toLowerCase().includes(searchKeywordLower) ||
        property.geographicalData?.section.toLowerCase().includes(searchKeywordLower) ||
        property.geographicalData?.block.toLowerCase().includes(searchKeywordLower) ||
        property.geographicalData?.township.toLowerCase().includes(searchKeywordLower) ||
        property.geographicalData?.range.toLowerCase().includes(searchKeywordLower) ||
        property.geographicalData?.surveyName.toLowerCase().includes(searchKeywordLower) ||
        property.geographicalData?.county.state.name.toLowerCase().includes(searchKeywordLower)
      );
    });

    this.wells = this.wells.filter(well => {
      return (
        well.name.toLowerCase().includes(searchKeywordLower) ||
        well.apiNo.toLowerCase().includes(searchKeywordLower)
      );
    });
  }

  navigateToAccountDetails(accountId: number): void {
    this.storageService.setCurrentAccountId(accountId);
    this.router.navigate(['/accountDetails']);
  }

  navigateToPropertyDetails(propertyId: number): void {
    this.storageService.setCurrentPropertyId(propertyId);
    this.router.navigate(['/propertyDetail']);
  }

  navigateToWellDetails(propertyId: number): void {
    this.storageService.setCurrentPropertyId(propertyId);
    this.router.navigate(['/propertyDetail']);
  }

  private searchMineralRightsOwner(mineralRightsOwner: MineralRightsOwner): boolean {
    if (!mineralRightsOwner) return false;
    return (
      (mineralRightsOwner.ownername && mineralRightsOwner.ownername.toLowerCase().includes(this.searchKeyword.toLowerCase())) ||
      (mineralRightsOwner.fname && mineralRightsOwner.fname.toLowerCase().includes(this.searchKeyword.toLowerCase())) ||
      (mineralRightsOwner.lname && mineralRightsOwner.lname.toLowerCase().includes(this.searchKeyword.toLowerCase())) ||
      (mineralRightsOwner.address1 && mineralRightsOwner.address1.toLowerCase().includes(this.searchKeyword.toLowerCase())) ||
      (mineralRightsOwner.address2 && mineralRightsOwner.address2.toLowerCase().includes(this.searchKeyword.toLowerCase())) ||
      (mineralRightsOwner.city && mineralRightsOwner.city.toLowerCase().includes(this.searchKeyword.toLowerCase())) ||
      (mineralRightsOwner.state && mineralRightsOwner.state.name.toLowerCase().includes(this.searchKeyword.toLowerCase())) ||
      (mineralRightsOwner.zipCode && mineralRightsOwner.zipCode.toLowerCase().includes(this.searchKeyword.toLowerCase()))
    );
  }

}
