import { Sale } from "../model/sale";
import { decks } from "./decks";
import { deductionObjects } from "./deductionObjects";
import { deductions } from "./deductions";
import { operators } from "./operators";
import { products } from "./products";
import { purchasers } from "./purchasers";

export const sale1: Sale = {
  id: 1,
  operator: operators[0],
  operatorSalesCode: 'operatorSalesCode1',
  saleDate: new Date('2021-01-01'),
  year: 2021,
  month: 1,
  product: products[0],
  unitPrice: 1,
  saleVolume: 1,
  grossValue: 1,
  netValue: 1,
  deductionObjects: [deductionObjects[0]],
  deductions: [deductions[0]],
  prodDate: new Date('2021-01-01'),
  fromProdDate: new Date('2021-01-01'),
  purchaser: purchasers[0],
  deckId: decks[0],
  insertBy: 1,
  insertDate: new Date('2021-01-01'),
  modifyBy: 1,
  modifyDate: new Date('2021-01-01'),
};

export const sale2: Sale = {
  id: 2,
  operator: operators[1],
  operatorSalesCode: 'operatorSalesCode2',
  saleDate: new Date('2021-02-01'),
  year: 2021,
  month: 2,
  product: products[1],
  unitPrice: 2,
  saleVolume: 2,
  grossValue: 2,
  netValue: 2,
  deductionObjects: [deductionObjects[1]],
  deductions: [deductions[1]],
  prodDate: new Date('2021-02-01'),
  fromProdDate: new Date('2021-02-01'),
  purchaser: purchasers[1],
  deckId: decks[1],
  insertBy: 2,
  insertDate: new Date('2021-02-01'),
  modifyBy: 2,
  modifyDate: new Date('2021-02-01'),
};

export const sale3: Sale = {
  id: 3,
  operator: operators[2],
  operatorSalesCode: 'operatorSalesCode3',
  saleDate: new Date('2021-03-01'),
  year: 2021,
  month: 3,
  product: products[2],
  unitPrice: 3,
  saleVolume: 3,
  grossValue: 3,
  netValue: 3,
  deductionObjects: [deductionObjects[2]],
  deductions: [deductions[2]],
  prodDate: new Date('2021-03-01'),
  fromProdDate: new Date('2021-03-01'),
  purchaser: purchasers[2],
  deckId: decks[2],
  insertBy: 3,
  insertDate: new Date('2021-03-01'),
  modifyBy: 3,
  modifyDate: new Date('2021-03-01'),
};

export const sale4: Sale = {
  id: 4,
  operator: operators[3],
  operatorSalesCode: 'operatorSalesCode4',
  saleDate: new Date('2021-04-01'),
  year: 2021,
  month: 4,
  product: products[3],
  unitPrice: 4,
  saleVolume: 4,
  grossValue: 4,
  netValue: 4,
  deductionObjects: [deductionObjects[3]],
  deductions: [deductions[3]],
  prodDate: new Date('2021-04-01'),
  fromProdDate: new Date('2021-04-01'),
  purchaser: purchasers[3],
  deckId: decks[3],
  insertBy: 4,
  insertDate: new Date('2021-04-01'),
  modifyBy: 4,
  modifyDate: new Date('2021-04-01'),
};

export const sale5: Sale = {
  id: 5,
  operator: operators[4],
  operatorSalesCode: 'operatorSalesCode5',
  saleDate: new Date('2021-05-01'),
  year: 2021,
  month: 5,
  product: products[4],
  unitPrice: 5,
  saleVolume: 5,
  grossValue: 5,
  netValue: 5,
  deductionObjects: [deductionObjects[4]],
  deductions: [deductions[4]],
  prodDate: new Date('2021-05-01'),
  fromProdDate: new Date('2021-05-01'),
  purchaser: purchasers[4],
  deckId: decks[4],
  insertBy: 5,
  insertDate: new Date('2021-05-01'),
  modifyBy: 5,
  modifyDate: new Date('2021-05-01'),
};

export const sale6: Sale = {
  id: 6,
  operator: operators[5],
  operatorSalesCode: 'operatorSalesCode6',
  saleDate: new Date('2021-06-01'),
  year: 2021,
  month: 6,
  product: products[5],
  unitPrice: 6,
  saleVolume: 6,
  grossValue: 6,
  netValue: 6,
  deductionObjects: [deductionObjects[5]],
  deductions: [deductions[5]],
  prodDate: new Date('2021-06-01'),
  fromProdDate: new Date('2021-06-01'),
  purchaser: purchasers[5],
  deckId: decks[5],
  insertBy: 6,
  insertDate: new Date('2021-06-01'),
  modifyBy: 6,
  modifyDate: new Date('2021-06-01'),
};

export const sale7: Sale = {
  id: 7,
  operator: operators[6],
  operatorSalesCode: 'operatorSalesCode7',
  saleDate: new Date('2021-07-01'),
  year: 2021,
  month: 7,
  product: products[6],
  unitPrice: 7,
  saleVolume: 7,
  grossValue: 7,
  netValue: 7,
  deductionObjects: [deductionObjects[6]],
  deductions: [deductions[6]],
  prodDate: new Date('2021-07-01'),
  fromProdDate: new Date('2021-07-01'),
  purchaser: purchasers[6],
  deckId: decks[6],
  insertBy: 7,
  insertDate: new Date('2021-07-01'),
  modifyBy: 7,
  modifyDate: new Date('2021-07-01'),
};

export const sale8: Sale = {
  id: 8,
  operator: operators[7],
  operatorSalesCode: 'operatorSalesCode8',
  saleDate: new Date('2021-08-01'),
  year: 2021,
  month: 8,
  product: products[7],
  unitPrice: 8,
  saleVolume: 8,
  grossValue: 8,
  netValue: 8,
  deductionObjects: [deductionObjects[7]],
  deductions: [deductions[7]],
  prodDate: new Date('2021-08-01'),
  fromProdDate: new Date('2021-08-01'),
  purchaser: purchasers[7],
  deckId: decks[7],
  insertBy: 8,
  insertDate: new Date('2021-08-01'),
  modifyBy: 8,
  modifyDate: new Date('2021-08-01'),
};

export const sale9: Sale = {
  id: 9,
  operator: operators[8],
  operatorSalesCode: 'operatorSalesCode9',
  saleDate: new Date('2021-09-01'),
  year: 2021,
  month: 9,
  product: products[8],
  unitPrice: 9,
  saleVolume: 9,
  grossValue: 9,
  netValue: 9,
  deductionObjects: [deductionObjects[8]],
  deductions: [deductions[8]],
  prodDate: new Date('2021-09-01'),
  fromProdDate: new Date('2021-09-01'),
  purchaser: purchasers[8],
  deckId: decks[8],
  insertBy: 9,
  insertDate: new Date('2021-09-01'),
  modifyBy: 9,
  modifyDate: new Date('2021-09-01'),
};

export const sale10: Sale = {
  id: 10,
  operator: operators[9],
  operatorSalesCode: 'operatorSalesCode10',
  saleDate: new Date('2021-10-01'),
  year: 2021,
  month: 10,
  product: products[9],
  unitPrice: 10,
  saleVolume: 10,
  grossValue: 10,
  netValue: 10,
  deductionObjects: [deductionObjects[9]],
  deductions: [deductions[9]],
  prodDate: new Date('2021-10-01'),
  fromProdDate: new Date('2021-10-01'),
  purchaser: purchasers[9],
  deckId: decks[9],
  insertBy: 10,
  insertDate: new Date('2021-10-01'),
  modifyBy: 10,
  modifyDate: new Date('2021-10-01'),
};

export const sale11: Sale = {
  id: 11,
  operator: operators[10],
  operatorSalesCode: 'operatorSalesCode11',
  saleDate: new Date('2021-11-01'),
  year: 2021,
  month: 11,
  product: products[10],
  unitPrice: 11,
  saleVolume: 11,
  grossValue: 11,
  netValue: 11,
  deductionObjects: [deductionObjects[10]],
  deductions: [deductions[10]],
  prodDate: new Date('2021-11-01'),
  fromProdDate: new Date('2021-11-01'),
  purchaser: purchasers[10],
  deckId: decks[10],
  insertBy: 11,
  insertDate: new Date('2021-11-01'),
  modifyBy: 11,
  modifyDate: new Date('2021-11-01'),
};

export const sale12: Sale = {
  id: 12,
  operator: operators[11],
  operatorSalesCode: 'operatorSalesCode12',
  saleDate: new Date('2021-12-01'),
  year: 2021,
  month: 12,
  product: products[11],
  unitPrice: 12,
  saleVolume: 12,
  grossValue: 12,
  netValue: 12,
  deductionObjects: [deductionObjects[11]],
  deductions: [deductions[11]],
  prodDate: new Date('2021-12-01'),
  fromProdDate: new Date('2021-12-01'),
  purchaser: purchasers[11],
  deckId: decks[11],
  insertBy: 12,
  insertDate: new Date('2021-12-01'),
  modifyBy: 12,
  modifyDate: new Date('2021-12-01'),
};

export const sale13: Sale = {
  id: 13,
  operator: operators[12],
  operatorSalesCode: 'operatorSalesCode13',
  saleDate: new Date('2022-01-01'),
  year: 2022,
  month: 1,
  product: products[12],
  unitPrice: 13,
  saleVolume: 13,
  grossValue: 13,
  netValue: 13,
  deductionObjects: [deductionObjects[12]],
  deductions: [deductions[12]],
  prodDate: new Date('2022-01-01'),
  fromProdDate: new Date('2022-01-01'),
  purchaser: purchasers[12],
  deckId: decks[12],
  insertBy: 13,
  insertDate: new Date('2022-01-01'),
  modifyBy: 13,
  modifyDate: new Date('2022-01-01'),
};

export const sale14: Sale = {
  id: 14,
  operator: operators[13],
  operatorSalesCode: 'operatorSalesCode14',
  saleDate: new Date('2022-02-01'),
  year: 2022,
  month: 2,
  product: products[13],
  unitPrice: 14,
  saleVolume: 14,
  grossValue: 14,
  netValue: 14,
  deductionObjects: [deductionObjects[13]],
  deductions: [deductions[13]],
  prodDate: new Date('2022-02-01'),
  fromProdDate: new Date('2022-02-01'),
  purchaser: purchasers[13],
  deckId: decks[13],
  insertBy: 14,
  insertDate: new Date('2022-02-01'),
  modifyBy: 14,
  modifyDate: new Date('2022-02-01'),
};

export const sale15: Sale = {
  id: 15,
  operator: operators[14],
  operatorSalesCode: 'operatorSalesCode15',
  saleDate: new Date('2022-03-01'),
  year: 2022,
  month: 3,
  product: products[14],
  unitPrice: 15,
  saleVolume: 15,
  grossValue: 15,
  netValue: 15,
  deductionObjects: [deductionObjects[14]],
  deductions: [deductions[14]],
  prodDate: new Date('2022-03-01'),
  fromProdDate: new Date('2022-03-01'),
  purchaser: purchasers[14],
  deckId: decks[14],
  insertBy: 15,
  insertDate: new Date('2022-03-01'),
  modifyBy: 15,
  modifyDate: new Date('2022-03-01'),
};

export const sale16: Sale = {
  id: 16,
  operator: operators[15],
  operatorSalesCode: 'operatorSalesCode16',
  saleDate: new Date('2022-04-01'),
  year: 2022,
  month: 4,
  product: products[15],
  unitPrice: 16,
  saleVolume: 16,
  grossValue: 16,
  netValue: 16,
  deductionObjects: [deductionObjects[15]],
  deductions: [deductions[15]],
  prodDate: new Date('2022-04-01'),
  fromProdDate: new Date('2022-04-01'),
  purchaser: purchasers[15],
  deckId: decks[15],
  insertBy: 16,
  insertDate: new Date('2022-04-01'),
  modifyBy: 16,
  modifyDate: new Date('2022-04-01'),
};

export const sale17: Sale = {
  id: 17,
  operator: operators[16],
  operatorSalesCode: 'operatorSalesCode17',
  saleDate: new Date('2022-05-01'),
  year: 2022,
  month: 5,
  product: products[16],
  unitPrice: 17,
  saleVolume: 17,
  grossValue: 17,
  netValue: 17,
  deductionObjects: [deductionObjects[16]],
  deductions: [deductions[16]],
  prodDate: new Date('2022-05-01'),
  fromProdDate: new Date('2022-05-01'),
  purchaser: purchasers[16],
  deckId: decks[16],
  insertBy: 17,
  insertDate: new Date('2022-05-01'),
  modifyBy: 17,
  modifyDate: new Date('2022-05-01'),
};

export const sale18: Sale = {
  id: 18,
  operator: operators[17],
  operatorSalesCode: 'operatorSalesCode18',
  saleDate: new Date('2022-06-01'),
  year: 2022,
  month: 6,
  product: products[17],
  unitPrice: 18,
  saleVolume: 18,
  grossValue: 18,
  netValue: 18,
  deductionObjects: [deductionObjects[17]],
  deductions: [deductions[17]],
  prodDate: new Date('2022-06-01'),
  fromProdDate: new Date('2022-06-01'),
  purchaser: purchasers[17],
  deckId: decks[17],
  insertBy: 18,
  insertDate: new Date('2022-06-01'),
  modifyBy: 18,
  modifyDate: new Date('2022-06-01'),
};

export const sale19: Sale = {
  id: 19,
  operator: operators[18],
  operatorSalesCode: 'operatorSalesCode19',
  saleDate: new Date('2022-07-01'),
  year: 2022,
  month: 7,
  product: products[18],
  unitPrice: 19,
  saleVolume: 19,
  grossValue: 19,
  netValue: 19,
  deductionObjects: [deductionObjects[18]],
  deductions: [deductions[18]],
  prodDate: new Date('2022-07-01'),
  fromProdDate: new Date('2022-07-01'),
  purchaser: purchasers[18],
  deckId: decks[18],
  insertBy: 19,
  insertDate: new Date('2022-07-01'),
  modifyBy: 19,
  modifyDate: new Date('2022-07-01'),
};

export const sale20: Sale = {
  id: 20,
  operator: operators[19],
  operatorSalesCode: 'operatorSalesCode20',
  saleDate: new Date('2022-08-01'),
  year: 2022,
  month: 8,
  product: products[19],
  unitPrice: 20,
  saleVolume: 20,
  grossValue: 20,
  netValue: 20,
  deductionObjects: [deductionObjects[19]],
  deductions: [deductions[19]],
  prodDate: new Date('2022-08-01'),
  fromProdDate: new Date('2022-08-01'),
  purchaser: purchasers[19],
  deckId: decks[19],
  insertBy: 20,
  insertDate: new Date('2022-08-01'),
  modifyBy: 20,
  modifyDate: new Date('2022-08-01'),
};

export const sales: Sale[] = [
  sale1,
  sale2,
  sale3,
  sale4,
  sale5,
  sale6,
  sale7,
  sale8,
  sale9,
  sale10,
  sale11,
  sale12,
  sale13,
  sale14,
  sale15,
  sale16,
  sale17,
  sale18,
  sale19,
  sale20
];
