import { Product } from '../../../model/product'
import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Property } from '../../../model/property';
import { PropertyService } from '../../../core/services/property/property.service';
import { PieChartComponent } from './pie-chart/pie-chart.component';
import { GraphChartComponent } from './graph-chart/graph-chart.component';
import { BarChartComponent } from './bar-chart/bar-chart.component';
import { DataCardComponent } from './data-card/data-card.component';
import { VerticalBarChartComponent } from './vertical-bar-chart/vertical-bar-chart.component';
import { StorageService } from '../../../core/services/storage/storage-service.service';
import { PaymentSummary } from '../../../model/payment_summary';
import { OperatorPaymentService } from '../../../core/services/operator-payment/operator-payment.service';
import { Account } from '../../../model/account';
import { ProductService } from '../../../core/services/product/product.service';
import { Router } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
@Component({
  selector: 'app-dashboard-summary',
  standalone: true,
  imports: [
    CommonModule,
    PieChartComponent,
    GraphChartComponent,
    BarChartComponent,
    DataCardComponent,
    VerticalBarChartComponent
  ],
  templateUrl: './dashboard-summary.component.html',
  styleUrls: ['./dashboard-summary.component.css']

})
export class DashboardSummaryComponent implements OnInit {
  @Input() account?: Account;
  private url: string;
  private propertiesData: Property[] = [];
  private productData: Product[] = [];

  public checkDates: string[];
  public paymentSummaryData: PaymentSummary[];
  public fromDate: string;
  public toDate: string = new Date().toISOString().split('T')[0];
  public currentPage: number = 0;
  public propertyId: number = -1;
  public productId: number = -1;
  public isDateRange: boolean = false;
  public breakdown: string = "M";
  public today: string = new Date().toISOString().split('T')[0];
  public totalPages: number = 3;
  public dashboard: boolean;

  private unsubscribe = new Subject<void>();

  constructor(
    private paymentsService: OperatorPaymentService,
    private propertyService: PropertyService,
    private storageService: StorageService,
    private productService: ProductService,
    private router: Router
  ) {
    this.url = this.router.url;
  }

  ngOnInit(): void {
    this.setInitialFromDate();
    if (!this.url.includes('dashboard')) {
      this.dashboard = false;
      this.getAccountProducts();
      this.getAccountProperties();
    } else {
      this.dashboard = true;
      this.getAccontManagerProducts();
      this.getAccountManagerProperties();
    }
    this.initializeData();
  }

  get properties() {
    return this.propertiesData;
  }

  get products() {
    return this.productData;
  }

  private getAccountManagerProperties(): void {
    this.propertyService.getAccountManagerProperties()
      .pipe(
        takeUntil(this.unsubscribe)
      ).subscribe((data) => {
        this.propertiesData = data;
        this.paymentsService.getAccountManagerPendingPaymentCount().subscribe(pendingCount => {
          if (pendingCount.paymentCount > 0) {
            const unknownProperty: Property = { id: 0, name: 'Unknown', description: '', propertyNo: '', effectiveFrom: new Date(), wells: [], operatorList: '' };
            this.propertiesData.push(unknownProperty);
          }
        });
        this.storageService.setAccountManagerProperties(this.propertiesData);
      }
      );
  }

  private getAccountProperties(): void {
    this.propertyService.getAccountProperties(this.account?.id)
      .pipe(
        takeUntil(this.unsubscribe)
      ).subscribe((data) => {
        this.propertiesData = data;
        this.paymentsService.getAccountPendingPaymentCount(this.account?.id).subscribe(pendingCount => {
          if (pendingCount.paymentCount > 0) {
            const unknownProperty: Property = { id: 0, name: 'Unknown', description: '', propertyNo: '', effectiveFrom: new Date(), wells: [], operatorList: '' };
            this.propertiesData.push(unknownProperty);
          }
        });
        this.storageService.setAccountProperties(this.propertiesData);
      }
      );
  }

  private getAccontManagerProducts(): void {
    this.productService.getAccountManagerProducts()
      .pipe(
        takeUntil(this.unsubscribe)
      ).subscribe((data) => {
        this.productData = data;
        this.paymentsService.getAccountManagerPendingPaymentCount().subscribe(pendingCount => {
          if (pendingCount.paymentCount > 0) {
            const unknownProduct: Product = { id: 99, name: 'Unknown', operatorId: 0, primaryId: 99 };
            this.productData.push(unknownProduct);
          }
        });
      }
      );
  }

  private getAccountProducts(): void {
    this.productService.getAccountProducts(this.account?.id)
      .pipe(
        takeUntil(this.unsubscribe)
      ).subscribe((data) => {
        this.productData = data;
        this.paymentsService.getAccountPendingPaymentCount(this.account?.id).subscribe(pendingCount => {
          if (pendingCount.paymentCount > 0) {
            const unknownProduct: Product = { id: 99, name: 'Unknown', operatorId: 0, primaryId: 99 };
            this.productData.push(unknownProduct);
          }
        });
      }
      );
  }

  public goToPreviousPage(): void {
    if (this.currentPage > 0) {
      this.currentPage--;
    }
  }

  public goToNextPage(): void {
    if (this.currentPage < this.totalPages - 1) {
      this.currentPage++;
    }
  }

  private setInitialFromDate(): void {
    const date = new Date();
    date.setMonth(date.getMonth() - 18);
    this.fromDate = date.toISOString().split('T')[0];
  }

  private initializeData(): void {
    if (!this.account) {
      this.getAccountManagerPaymentSummaryByPeriod();
      this.getAccountManagerPaymentSummaryByPeriodByProduct();
      this.getAccountManagerPaymentSummaryByPeriodByProperty();
      this.getAccountManagerPaymentSummaryByProduct();
      this.getAccountManagerPaymentSummaryByProperty();
    } else {
      this.getAccountPaymentSummaryByPeriod();
      this.getAccountPaymentSummaryByPeriodByProduct();
      this.getAccountPaymentSummaryByPeriodByProperty();
      this.getAccountPaymentSummaryByProduct();
      this.getAccountPaymentSummaryByProperty();
    }
  }

  private getAccountManagerPaymentSummaryByProperty(): void {
    this.paymentsService.getAccountManagerPaymentSummaryByProperty(
      this.fromDate,
      this.toDate,
      this.propertyId,
      this.productId
    ).subscribe((data) => {
      this.storageService.setAccountManagerPaymentSummaryByProperty(data);
    });
  }

  private getAccountManagerPaymentSummaryByProduct(): void {
    this.paymentsService.getAccountManagerPaymentSummaryByProduct(
      this.fromDate,
      this.toDate,
      this.propertyId,
      this.productId
    ).subscribe((data) => {
      this.storageService.setAccountManagerPaymentSummaryByProduct(data);
    }
    );
  }

  private getAccountManagerPaymentSummaryByPeriod(): void {
    this.paymentsService.getAccountManagerPaymentSummaryByPeriod(
      this.fromDate,
      this.toDate,
      this.propertyId,
      this.productId,
      this.breakdown
    ).subscribe((data) => {
      this.storageService.setAccountManagerPaymentSummaryByPeriod(data);
    }
    );
  }

  private getAccountManagerPaymentSummaryByPeriodByProduct(): void {
    this.paymentsService.getAccountManagerPaymentSummaryByPeriodByProduct(
      this.fromDate,
      this.toDate,
      this.breakdown,
      this.propertyId,
      this.productId,
      this.breakdown
    ).subscribe((data) => {
      this.storageService.setAccountManagerPaymentSummaryByPeriodByProduct(data);
    }
    );
  }

  private getAccountManagerPaymentSummaryByPeriodByProperty(): void {
    this.paymentsService.getAccountManagerPaymentSummaryByPeriodByProperty(
      this.fromDate,
      this.toDate,
      this.breakdown,
      this.propertyId,
      this.productId,
      this.breakdown
    ).subscribe((data) => {
      this.storageService.setAccountManagerPaymentSummaryByPeriodByProperty(data);
    }
    );
  }

  private getAccountPaymentSummaryByProperty(): void {
    this.paymentsService.getAccountPaymentSummaryByProperty(
      this.account.id,
      this.fromDate,
      this.toDate,
      this.propertyId,
      this.productId
    ).subscribe((data) => {
      this.storageService.setAccountPaymentSummaryByProperty(data);
    });
  }

  private getAccountPaymentSummaryByProduct(): void {
    this.paymentsService.getAccountPaymentSummaryByProduct(
      this.account.id,
      this.fromDate,
      this.toDate,
      this.propertyId,
      this.productId
    ).subscribe((data) => {
      this.storageService.setAccountPaymentSummaryByProduct(data);
    });
  }

  private getAccountPaymentSummaryByPeriod(): void {
    this.paymentsService.getAccountPaymentSummaryByPeriod(
      this.account.id,
      this.fromDate,
      this.toDate,
      this.propertyId,
      this.productId,
      this.breakdown
    ).subscribe((data) => {
      this.storageService.setAccountPaymentSummaryByPeriod(data);
    });
  }

  private getAccountPaymentSummaryByPeriodByProduct(): void {
    this.paymentsService.getAccountPaymentSummaryByPeriodByProduct(
      this.account.id,
      this.fromDate,
      this.toDate,
      this.breakdown,
      this.propertyId,
      this.productId,
      this.breakdown
    ).subscribe((data) => {
      this.storageService.setAccountPaymentSummaryByPeriodByProduct(data);
    });
  }

  private getAccountPaymentSummaryByPeriodByProperty(): void {
    this.paymentsService.getAccountPaymnetSummaryByPeriodByProperty(
      this.account.id,
      this.fromDate,
      this.toDate,
      this.breakdown,
      this.propertyId,
      this.productId,
      this.breakdown
    ).subscribe((data) => {
      this.storageService.setAccountPaymentSummaryByPeriodByProperty(data);
    });
  }



  updateBreakdown(breakdown: string): void {
    this.breakdown = breakdown;
    this.initializeData();
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onFromDateChange(event: any): void {
    this.fromDate = event.target.value;
    this.isDateRange = true;
    this.initializeData();
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onToDateChange(event: any): void {
    this.toDate = event.target.value;
    this.initializeData();
  }

  onPropertyNameChange(propertyId: number): void {
    this.propertyId = propertyId;
    this.initializeData();
  }

  onProductTypeChange(productId: number): void {
    this.productId = productId;
    this.initializeData();
  }

  onPropertyChange(propertyId: number): void {
    this.propertyId = propertyId;
  }

  onProductChange(productId: number): void {
    this.productId = productId;
  }

  goToPage(pageIndex: number): void {
    this.currentPage = pageIndex;
  }

}
