<div id="footer">
  <div class="page_container">
    <div id="footer_inner" class="d-flex justify-content-between align-items-center">
      <div id="footer_legal">
        &copy; Copyright {{ copyrightYear }} PipelineDL. All Rights Reserved
      </div>
      <div id="footer_links" *ngIf="this.loggedIn()">
        <ul>
          <li><a routerLink="/dashboard">Home</a></li>
          <li><a routerLink="/accounts">Accounts</a></li>
          <li><a routerLink="/properties">Properties</a></li>
          <li><a routerLink="/payments">Payments</a></li>
        </ul>
      </div>
      <div id="footer_logo">
        <img src="./assets/images/powered_by_pipeline@2x.png" alt="Powered by PipelineDL" />
      </div>
    </div>
  </div>
</div>
