import { BrowserCacheLocation, Configuration } from "@azure/msal-browser";
import { environment } from "../environments/environment";

const isIE = window.navigator.userAgent.indexOf("MSIE ") > -1 || window.navigator.userAgent.indexOf("Trident/") > -1;

export const b2cPolicies = {
  names: {
    signIn: environment.B2C_POLICY_NAME_SIGN_IN,
    resetPassword: environment.B2C_POLICY_NAME_RESET_PASSWORD
  },
  authorities: {
    signIn: {
      authority: environment.B2C_AUTHORITY_URL_SIGN_IN
    },
    resetPassword: {
      authority: environment.B2C_AUTHORITY_URL_RESET_PASSWORD
    }
  },
  authorityDomain: environment.B2C_AUTHORITY_DOMAIN_URL,
};

export const msalConfig: Configuration = {
  auth: {
      clientId: environment.B2C_CLIENT_ID,
      knownAuthorities: [
        b2cPolicies.authorityDomain
      ],
      authority: b2cPolicies.authorities.signIn.authority,
      redirectUri: '/auth',
      postLogoutRedirectUri: '/',
  },
  cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
      storeAuthStateInCookie: isIE
  },
  system: {
      allowNativeBroker: false,
  }
};

export const loginRequest = {
  scopes: []
};
