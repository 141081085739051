import { Injectable } from '@angular/core';
import { PropertyService } from './property.service';
import { Observable, of } from 'rxjs';
import { properties } from '../../../mockDB/properties';
import { Property } from '../../../model/property';

@Injectable({
  providedIn: 'root'
})
export class MockPropertyService extends PropertyService {
  getAccountProperties(accountId: number): Observable<Property[]> {
    throw new Error('Method not implemented.');
  }
  getAccountManagerProperties(): Observable<Property[]> {
    return of(properties);
  }


}
