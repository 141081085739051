import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { State } from '../../../model/state';
import { County } from '../../../model/county';
import { Operator } from '../../../model/operator';
import { Purchaser } from '../../../model/purchaser';
import { Product } from '../../../model/product';
import { Well } from '../../../model/well';
import { Bank } from '../../../model/bank';
import { HttpErrorResponse } from '@angular/common/http';
import { HttpService } from '../httpService/http.service';

@Injectable({
  providedIn: 'root'
})
export class HttpLookupService {
  private apiUrl = environment.apiUrl;

  constructor(private httpService: HttpService) { }

  getStates(): Observable<State[]> {
    const url = `${this.apiUrl}/getStates`;
    return this.httpService.get(url, false);
  }

  getAllStatesCounties(): Observable<State[]> {
    const url = `${this.apiUrl}/getAllStatesCounties`;
    return this.httpService.get(url, false);
  }

  getCounties(stateName: string): Observable<County[]> {
    const url = `${this.apiUrl}/getCounties/${stateName}`;
    return this.httpService.get(url, false);
  }

  getOperators(): Observable<Operator[]> {
    const url = `${this.apiUrl}/getOperators`;
    return this.httpService.get(url, false);
  }

  getOperator(id: number): Observable<Operator> {
    const url = `${this.apiUrl}/getOperator/${id}`;
    return this.httpService.get(url, false);
  }

  getMroOperators(): Observable<Operator[]> {
    const url = `${this.apiUrl}/getMroOperators`;
    return this.httpService.get(url, false);
  }

  getPurchasers(): Observable<Purchaser[]> {
    const url = `${this.apiUrl}/getPurchasers`;
    return this.httpService.get(url, false);
  }

  getProducts(): Observable<Product[]> {
    const url = `${this.apiUrl}/getProducts`;
    return this.httpService.get(url, false);
  }

  getWells(): Observable<Well[]> {
    const url = `${this.apiUrl}/getWells`;
    return this.httpService.get(url, false);
  }

  getBanks(): Observable<Bank[]> {
    const url = `${this.apiUrl}/getBanks`;
    return this.httpService.get(url, false);
  }

  getInterestTypes(): Observable<string[]> {
    const url = `${this.apiUrl}/getInterestTypes`;
    return this.httpService.get(url, false);
  }

  // getDivisionOrderInterestTypes(): Observable<DivisionOrderInterestType[]> {
  //   const url = `${this.apiUrl}/getDivisionOrderInterestTypes`;
  //   return this.httpService.get(url, false);
  // }


  private handleError(error: HttpErrorResponse) {
    console.error('An error occurred:', error);
    return throwError('Something went wrong. Please try again later.');
  }
}
