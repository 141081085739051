import { Deduction } from "../model/deduction";
import { getRandomInt } from './helpers/randomNumbersHelper';
import { deductionTypes } from "./deduction_types";
import { products } from "./products";

export const deduction1: Deduction = {
  royaltyPaymentId: 1,
  checkNo: 12345,
  salesId: 1,
  divisionorderId: 1,
  decimalInterest: 0.1,
  productName: products[0].name,
  deductionType: deductionTypes[getRandomInt(0, 8)],
  amount: 100
};

export const deduction2: Deduction = {
  royaltyPaymentId: 2,
  checkNo: 12345,
  salesId: 1,
  divisionorderId: 1,
  decimalInterest: 0.1,
  productName: products[1].name,
  deductionType: deductionTypes[getRandomInt(0, 8)],
  amount: 100
};

export const deduction3: Deduction = {
  royaltyPaymentId: 3,
  checkNo: 12345,
  salesId: 1,
  divisionorderId: 1,
  decimalInterest: 0.1,
  productName: products[2].name,
  deductionType: deductionTypes[getRandomInt(0, 8)],
  amount: 100
};

export const deduction4: Deduction = {
  royaltyPaymentId: 4,
  checkNo: 12345,
  salesId: 1,
  divisionorderId: 1,
  decimalInterest: 0.1,
  productName: products[3].name,
  deductionType: deductionTypes[getRandomInt(0, 8)],
  amount: 100
};

export const deduction5: Deduction = {
  royaltyPaymentId: 5,
  checkNo: 12345,
  salesId: 1,
  divisionorderId: 1,
  decimalInterest: 0.1,
  productName: products[4].name,
  deductionType: deductionTypes[getRandomInt(0, 8)],
  amount: 100
};

export const deduction6: Deduction = {
  royaltyPaymentId: 6,
  checkNo: 12345,
  salesId: 1,
  divisionorderId: 1,
  decimalInterest: 0.1,
  productName: products[5].name,
  deductionType: deductionTypes[getRandomInt(0, 8)],
  amount: 100
};

export const deduction7: Deduction = {
  royaltyPaymentId: 7,
  checkNo: 12345,
  salesId: 1,
  divisionorderId: 1,
  decimalInterest: 0.1,
  productName: products[6].name,
  deductionType: deductionTypes[getRandomInt(0, 8)],
  amount: 100
};

export const deduction8: Deduction = {
  royaltyPaymentId: 8,
  checkNo: 12345,
  salesId: 1,
  divisionorderId: 1,
  decimalInterest: 0.1,
  productName: products[7].name,
  deductionType: deductionTypes[getRandomInt(0, 8)],
  amount: 100
};

export const deduction9: Deduction = {
  royaltyPaymentId: 9,
  checkNo: 12345,
  salesId: 1,
  divisionorderId: 1,
  decimalInterest: 0.1,
  productName: products[8].name,
  deductionType: deductionTypes[getRandomInt(0, 8)],
  amount: 100
};

export const deduction10: Deduction = {
  royaltyPaymentId: 10,
  checkNo: 12345,
  salesId: 1,
  divisionorderId: 1,
  decimalInterest: 0.1,
  productName: products[9].name,
  deductionType: deductionTypes[getRandomInt(0, 8)],
  amount: 100
};

export const deduction11: Deduction = {
  royaltyPaymentId: 11,
  checkNo: 12345,
  salesId: 1,
  divisionorderId: 1,
  decimalInterest: 0.1,
  productName: products[10].name,
  deductionType: deductionTypes[getRandomInt(0, 8)],
  amount: 100
};

export const deduction12: Deduction = {
  royaltyPaymentId: 12,
  checkNo: 12345,
  salesId: 1,
  divisionorderId: 1,
  decimalInterest: 0.1,
  productName: products[11].name,
  deductionType: deductionTypes[getRandomInt(0, 8)],
  amount: 100
};

export const deduction13: Deduction = {
  royaltyPaymentId: 13,
  checkNo: 12345,
  salesId: 1,
  divisionorderId: 1,
  decimalInterest: 0.1,
  productName: products[12].name,
  deductionType: deductionTypes[getRandomInt(0, 8)],
  amount: 100
};

export const deduction14: Deduction = {
  royaltyPaymentId: 14,
  checkNo: 12345,
  salesId: 1,
  divisionorderId: 1,
  decimalInterest: 0.1,
  productName: products[13].name,
  deductionType: deductionTypes[getRandomInt(0, 8)],
  amount: 100
};

export const deduction15: Deduction = {
  royaltyPaymentId: 15,
  checkNo: 12345,
  salesId: 1,
  divisionorderId: 1,
  decimalInterest: 0.1,
  productName: products[14].name,
  deductionType: deductionTypes[getRandomInt(0, 8)],
  amount: 100
};

export const deduction16: Deduction = {
  royaltyPaymentId: 16,
  checkNo: 12345,
  salesId: 1,
  divisionorderId: 1,
  decimalInterest: 0.1,
  productName: products[15].name,
  deductionType: deductionTypes[getRandomInt(0, 8)],
  amount: 100
};

export const deduction17: Deduction = {
  royaltyPaymentId: 17,
  checkNo: 12345,
  salesId: 1,
  divisionorderId: 1,
  decimalInterest: 0.1,
  productName: products[16].name,
  deductionType: deductionTypes[getRandomInt(0, 8)],
  amount: 100
};

export const deduction18: Deduction = {
  royaltyPaymentId: 18,
  checkNo: 12345,
  salesId: 1,
  divisionorderId: 1,
  decimalInterest: 0.1,
  productName: products[17].name,
  deductionType: deductionTypes[getRandomInt(0, 8)],
  amount: 100
};

export const deduction19: Deduction = {
  royaltyPaymentId: 19,
  checkNo: 12345,
  salesId: 1,
  divisionorderId: 1,
  decimalInterest: 0.1,
  productName: products[18].name,
  deductionType: deductionTypes[getRandomInt(0, 8)],
  amount: 100
};

export const deduction20: Deduction = {
  royaltyPaymentId: 20,
  checkNo: 12345,
  salesId: 1,
  divisionorderId: 1,
  decimalInterest: 0.1,
  productName: products[19].name,
  deductionType: deductionTypes[getRandomInt(0, 8)],
  amount: 100
};

export const deductions: Deduction[] = [
  deduction1,
  deduction2,
  deduction3,
  deduction4,
  deduction5,
  deduction6,
  deduction7,
  deduction8,
  deduction9,
  deduction10,
  deduction11,
  deduction12,
  deduction13,
  deduction14,
  deduction15,
  deduction16,
  deduction17,
  deduction18,
  deduction19,
  deduction20
];
