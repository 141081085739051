import { RoyaltyPayment } from '../model/payment';
import { deductionObjects } from './deductionObjects';
import { deductions } from './deductions';
import { divisionOrders } from './divisionOrders';
import { interestTypes } from './interestTypes';
import { sales } from './sales';
import { wells } from './wells';

export const royaltyPayment1: RoyaltyPayment = {
  id: 1,
  operatorPaymentCode: 'operatorPaymentCode1',
  sale: sales[0],
  divisionOrder: divisionOrders[0],
  paymentDate: new Date('2021-01-01'),
  mroVolume: 1,
  mroGrossValue: 1,
  mroNetValue: 1,
  severance: 1,
  deductionObjects: [
    deductionObjects[0]
  ],
  deductions: [
    deductions[0]
  ],
  well: wells[0]
};

export const royaltyPayment2: RoyaltyPayment = {
  id: 2,
  operatorPaymentCode: 'operatorPaymentCode2',
  sale: sales[1],
  divisionOrder: divisionOrders[1],
  paymentDate: new Date('2021-02-01'),
  mroVolume: 2,
  mroGrossValue: 2,
  mroNetValue: 2,
  severance: 2,
  deductionObjects: [
    deductionObjects[1]
  ],
  deductions: [
    deductions[1]
  ],
  well: wells[1]
};

export const royaltyPayment3: RoyaltyPayment = {
  id: 3,
  operatorPaymentCode: 'operatorPaymentCode3',
  sale: sales[2],
  divisionOrder: divisionOrders[2],
  paymentDate: new Date('2021-03-01'),
  mroVolume: 3,
  mroGrossValue: 3,
  mroNetValue: 3,
  severance: 3,
  deductionObjects: [
    deductionObjects[2]
  ],
  deductions: [
    deductions[2]
  ],
  well: wells[2]
};

export const royaltyPayment4: RoyaltyPayment = {
  id: 4,
  operatorPaymentCode: 'operatorPaymentCode4',
  sale: sales[3],
  divisionOrder: divisionOrders[3],
  paymentDate: new Date('2021-04-01'),
  mroVolume: 4,
  mroGrossValue: 4,
  mroNetValue: 4,
  severance: 4,
  deductionObjects: [
    deductionObjects[3]
  ],
  deductions: [
    deductions[3]
  ],
  well: wells[3]
};

export const royaltyPayment5: RoyaltyPayment = {
  id: 5,
  operatorPaymentCode: 'operatorPaymentCode5',
  sale: sales[4],
  divisionOrder: divisionOrders[4],
  paymentDate: new Date('2021-05-01'),
  mroVolume: 5,
  mroGrossValue: 5,
  mroNetValue: 5,
  severance: 5,
  deductionObjects: [
    deductionObjects[4]
  ],
  deductions: [
    deductions[4]
  ],
  well: wells[4]
};

export const royaltyPayment6: RoyaltyPayment = {
  id: 6,
  operatorPaymentCode: 'operatorPaymentCode6',
  sale: sales[5],
  divisionOrder: divisionOrders[5],
  paymentDate: new Date('2021-06-01'),
  mroVolume: 6,
  mroGrossValue: 6,
  mroNetValue: 6,
  severance: 6,
  deductionObjects: [
    deductionObjects[5]
  ],
  deductions: [
    deductions[5]
  ],
  well: wells[5]
};

export const royaltyPayment7: RoyaltyPayment = {
  id: 7,
  operatorPaymentCode: 'operatorPaymentCode7',
  sale: sales[6],
  divisionOrder: divisionOrders[6],
  paymentDate: new Date('2021-07-01'),
  mroVolume: 7,
  mroGrossValue: 7,
  mroNetValue: 7,
  severance: 7,
  deductionObjects: [
    deductionObjects[6]
  ],
  deductions: [
    deductions[6]
  ],
  well: wells[6]
};

export const royaltyPayment8: RoyaltyPayment = {
  id: 8,
  operatorPaymentCode: 'operatorPaymentCode8',
  sale: sales[7],
  divisionOrder: divisionOrders[7],
  paymentDate: new Date('2021-08-01'),
  mroVolume: 8,
  mroGrossValue: 8,
  mroNetValue: 8,
  severance: 8,
  deductionObjects: [
    deductionObjects[7]
  ],
  deductions: [
    deductions[7]
  ],
  well: wells[7]
};

export const royaltyPayment9: RoyaltyPayment = {
  id: 9,
  operatorPaymentCode: 'operatorPaymentCode9',
  sale: sales[8],
  divisionOrder: divisionOrders[8],
  paymentDate: new Date('2021-09-01'),
  mroVolume: 9,
  mroGrossValue: 9,
  mroNetValue: 9,
  severance: 9,
  deductionObjects: [
    deductionObjects[8]
  ],
  deductions: [
    deductions[8]
  ],
  well: wells[8]
};

export const royaltyPayment10: RoyaltyPayment = {
  id: 10,
  operatorPaymentCode: 'operatorPaymentCode10',
  sale: sales[9],
  divisionOrder: divisionOrders[9],
  paymentDate: new Date('2021-10-01'),
  mroVolume: 10,
  mroGrossValue: 10,
  mroNetValue: 10,
  severance: 10,
  deductionObjects: [
    deductionObjects[9]
  ],
  deductions: [
    deductions[9]
  ],
  well: wells[9]
};

export const royaltyPayment11: RoyaltyPayment = {
  id: 11,
  operatorPaymentCode: 'operatorPaymentCode11',
  sale: sales[10],
  divisionOrder: divisionOrders[10],
  paymentDate: new Date('2021-11-01'),
  mroVolume: 11,
  mroGrossValue: 11,
  mroNetValue: 11,
  severance: 11,
  deductionObjects: [
    deductionObjects[10]
  ],
  deductions: [
    deductions[10]
  ],
  well: wells[10]
};

export const royaltyPayment12: RoyaltyPayment = {
  id: 12,
  operatorPaymentCode: 'operatorPaymentCode12',
  sale: sales[11],
  divisionOrder: divisionOrders[11],
  paymentDate: new Date('2021-12-01'),
  mroVolume: 12,
  mroGrossValue: 12,
  mroNetValue: 12,
  severance: 12,
  deductionObjects: [
    deductionObjects[11]
  ],
  deductions: [
    deductions[11]
  ],
  well: wells[11]
};

export const royaltyPayment13: RoyaltyPayment = {
  id: 13,
  operatorPaymentCode: 'operatorPaymentCode13',
  sale: sales[12],
  divisionOrder: divisionOrders[12],
  paymentDate: new Date('2022-01-01'),
  mroVolume: 13,
  mroGrossValue: 13,
  mroNetValue: 13,
  severance: 13,
  deductionObjects: [
    deductionObjects[12]
  ],
  deductions: [
    deductions[12]
  ],
  well: wells[12]
};

export const royaltyPayment14: RoyaltyPayment = {
  id: 14,
  operatorPaymentCode: 'operatorPaymentCode14',
  sale: sales[13],
  divisionOrder: divisionOrders[13],
  paymentDate: new Date('2022-02-01'),
  mroVolume: 14,
  mroGrossValue: 14,
  mroNetValue: 14,
  severance: 14,
  deductionObjects: [
    deductionObjects[13]
  ],
  deductions: [
    deductions[13]
  ],
  well: wells[13]
};

export const royaltyPayment15: RoyaltyPayment = {
  id: 15,
  operatorPaymentCode: 'operatorPaymentCode15',
  sale: sales[14],
  divisionOrder: divisionOrders[14],
  paymentDate: new Date('2022-03-01'),
  mroVolume: 15,
  mroGrossValue: 15,
  mroNetValue: 15,
  severance: 15,
  deductionObjects: [
    deductionObjects[14]
  ],
  deductions: [
    deductions[14]
  ],
  well: wells[14]
};

export const royaltyPayment16: RoyaltyPayment = {
  id: 16,
  operatorPaymentCode: 'operatorPaymentCode16',
  sale: sales[15],
  divisionOrder: divisionOrders[15],
  paymentDate: new Date('2022-04-01'),
  mroVolume: 16,
  mroGrossValue: 16,
  mroNetValue: 16,
  severance: 16,
  deductionObjects: [
    deductionObjects[15]
  ],
  deductions: [
    deductions[15]
  ],
  well: wells[15]
};

export const royaltyPayment17: RoyaltyPayment = {
  id: 17,
  operatorPaymentCode: 'operatorPaymentCode17',
  sale: sales[16],
  divisionOrder: divisionOrders[16],
  paymentDate: new Date('2022-05-01'),
  mroVolume: 17,
  mroGrossValue: 17,
  mroNetValue: 17,
  severance: 17,
  deductionObjects: [
    deductionObjects[16]
  ],
  deductions: [
    deductions[16]
  ],
  well: wells[16]
};

export const royaltyPayment18: RoyaltyPayment = {
  id: 18,
  operatorPaymentCode: 'operatorPaymentCode18',
  sale: sales[17],
  divisionOrder: divisionOrders[17],
  paymentDate: new Date('2022-06-01'),
  mroVolume: 18,
  mroGrossValue: 18,
  mroNetValue: 18,
  severance: 18,
  deductionObjects: [
    deductionObjects[17]
  ],
  deductions: [
    deductions[17]
  ],
  well: wells[17]
};

export const royaltyPayment19: RoyaltyPayment = {
  id: 19,
  operatorPaymentCode: 'operatorPaymentCode19',
  sale: sales[18],
  divisionOrder: divisionOrders[18],
  paymentDate: new Date('2022-07-01'),
  mroVolume: 19,
  mroGrossValue: 19,
  mroNetValue: 19,
  severance: 19,
  deductionObjects: [
    deductionObjects[18]
  ],
  deductions: [
    deductions[18]
  ],
  well: wells[0]
};


export const royaltyPayments = [
  royaltyPayment1,
  royaltyPayment2,
  royaltyPayment3,
  royaltyPayment4,
  royaltyPayment5,
  royaltyPayment6,
  royaltyPayment7,
  royaltyPayment8,
  royaltyPayment9,
  royaltyPayment10,
  royaltyPayment11,
  royaltyPayment12,
  royaltyPayment13,
  royaltyPayment14,
  royaltyPayment15,
  royaltyPayment16,
  royaltyPayment17,
  royaltyPayment18,
  royaltyPayment19
];
