import { Component, Input } from '@angular/core';
import { Property } from '../../../model/property';
import { CommonModule } from '@angular/common';
import { ModalModule } from '../../modal/modal.module';

@Component({
  selector: 'app-property-detail-widget',
  standalone: true,
  imports: [
    CommonModule,
    ModalModule
  ],
  templateUrl: './property-detail-widget.component.html',
  styleUrl: './property-detail-widget.component.css'
})
export class PropertyDetailWidgetComponent {
  @Input() property: Property;
  public lastUpdated: Date = new Date();
  public modalOpen: boolean = false;

  openModal() {
    this.modalOpen = true;
  }

  closeModal() {
    this.modalOpen = false;
  }
}
