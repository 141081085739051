import { PropertyPerformance } from '../model/performance';
import { randomDateMinOrMax } from './helpers/randomDateHelper';
import { products } from './products';

export const performance1: PropertyPerformance = {
  productId: products[0].id,
  productName: products[0].name,
  salesAmount: 100,
  salesDate: randomDateMinOrMax(2024, 2023, true)
};

export const performance2: PropertyPerformance = {
  productId: products[1].id,
  productName: products[1].name,
  salesAmount: 200,
  salesDate: randomDateMinOrMax(2024, 2023, true)
};

export const performance3: PropertyPerformance = {
  productId: products[2].id,
  productName: products[2].name,
  salesAmount: 300,
  salesDate: randomDateMinOrMax(2024, 2023, true)
};

export const performance4: PropertyPerformance = {
  productId: products[0].id,
  productName: products[0].name,
  salesAmount: 400,
  salesDate: randomDateMinOrMax(2024, 2023, true)
};

export const performance5: PropertyPerformance = {
  productId: products[2].id,
  productName: products[2].name,
  salesAmount: 500,
  salesDate: randomDateMinOrMax(2024, 2023, true)
};

export const performance6: PropertyPerformance = {
  productId: products[1].id,
  productName: products[1].name,
  salesAmount: 600,
  salesDate: randomDateMinOrMax(2024, 2023, true)
};

export const performance7: PropertyPerformance = {
  productId: products[0].id,
  productName: products[0].name,
  salesAmount: 700,
  salesDate: randomDateMinOrMax(2024, 2023, true)
};

export const performance8: PropertyPerformance = {
  productId: products[2].id,
  productName: products[2].name,
  salesAmount: 800,
  salesDate: randomDateMinOrMax(2024, 2023, true)
};

export const performance9: PropertyPerformance = {
  productId: products[1].id,
  productName: products[1].name,
  salesAmount: 900,
  salesDate: randomDateMinOrMax(2024, 2023, true)
};

export const performance10: PropertyPerformance = {
  productId: products[0].id,
  productName: products[0].name,
  salesAmount: 1000,
  salesDate: randomDateMinOrMax(2024, 2023, true)
};

export const performance11: PropertyPerformance = {
  productId: products[2].id,
  productName: products[2].name,
  salesAmount: 1100,
  salesDate: randomDateMinOrMax(2024, 2023, true)
};

export const performance12: PropertyPerformance = {
  productId: products[1].id,
  productName: products[1].name,
  salesAmount: 1200,
  salesDate: randomDateMinOrMax(2024, 2023, true)
};

export const performance13: PropertyPerformance = {
  productId: products[0].id,
  productName: products[0].name,
  salesAmount: 1300,
  salesDate: randomDateMinOrMax(2024, 2023, true)
};

export const performance14: PropertyPerformance = {
  productId: products[2].id,
  productName: products[2].name,
  salesAmount: 1400,
  salesDate: randomDateMinOrMax(2024, 2023, true)
};

export const performance15: PropertyPerformance = {
  productId: products[1].id,
  productName: products[1].name,
  salesAmount: 1500,
  salesDate: randomDateMinOrMax(2024, 2023, true)
};

export const performance16: PropertyPerformance = {
  productId: products[0].id,
  productName: products[0].name,
  salesAmount: 1600,
  salesDate: randomDateMinOrMax(2024, 2023, true)
};

export const performance17: PropertyPerformance = {
  productId: products[2].id,
  productName: products[2].name,
  salesAmount: 1700,
  salesDate: randomDateMinOrMax(2024, 2023, true)
};

export const performance18: PropertyPerformance = {
  productId: products[1].id,
  productName: products[1].name,
  salesAmount: 1800,
  salesDate: randomDateMinOrMax(2024, 2023, true)
};

export const performance19: PropertyPerformance = {
  productId: products[0].id,
  productName: products[0].name,
  salesAmount: 1900,
  salesDate: randomDateMinOrMax(2024, 2023, true)
};

export const performance20: PropertyPerformance = {
  productId: products[2].id,
  productName: products[2].name,
  salesAmount: 2000,
  salesDate: randomDateMinOrMax(2024, 2023, true)
};

export const performance21: PropertyPerformance = {
  productId: products[1].id,
  productName: products[1].name,
  salesAmount: 2100,
  salesDate: randomDateMinOrMax(2024, 2023, true)
};

export const performance22: PropertyPerformance = {
  productId: products[0].id,
  productName: products[0].name,
  salesAmount: 2200,
  salesDate: randomDateMinOrMax(2024, 2023, true)
};

export const performance23: PropertyPerformance = {
  productId: products[2].id,
  productName: products[2].name,
  salesAmount: 2300,
  salesDate: randomDateMinOrMax(2024, 2023, true)
};

export const performance24: PropertyPerformance = {
  productId: products[1].id,
  productName: products[1].name,
  salesAmount: 2400,
  salesDate: randomDateMinOrMax(2024, 2023, true)
};

export const performance25: PropertyPerformance = {
  productId: products[0].id,
  productName: products[0].name,
  salesAmount: 2500,
  salesDate: randomDateMinOrMax(2024, 2023, true)
};

export const performance26: PropertyPerformance = {
  productId: products[2].id,
  productName: products[2].name,
  salesAmount: 2600,
  salesDate: randomDateMinOrMax(2024, 2023, true)
};

export const performance27: PropertyPerformance = {
  productId: products[1].id,
  productName: products[1].name,
  salesAmount: 2700,
  salesDate: randomDateMinOrMax(2024, 2023, true)
};

export const performance28: PropertyPerformance = {
  productId: products[0].id,
  productName: products[0].name,
  salesAmount: 2800,
  salesDate: randomDateMinOrMax(2024, 2023, true)
};

export const performance29: PropertyPerformance = {
  productId: products[2].id,
  productName: products[2].name,
  salesAmount: 2900,
  salesDate: randomDateMinOrMax(2024, 2023, true)
};

export const performance30: PropertyPerformance = {
  productId: products[1].id,
  productName: products[1].name,
  salesAmount: 3000,
  salesDate: randomDateMinOrMax(2024, 2023, true)
};

export const performances: PropertyPerformance[] = [
  performance1,
  performance2,
  performance3,
  performance4,
  performance5,
  performance6,
  performance7,
  performance8,
  performance9,
  performance10,
  performance11,
  performance12,
  performance13,
  performance14,
  performance15,
  performance16,
  performance17,
  performance18,
  performance19,
  performance20,
  performance21,
  performance22,
  performance23,
  performance24,
  performance25,
  performance26,
  performance27,
  performance28,
  performance29,
  performance30
];
