import { Component, Input } from '@angular/core';

import { CommonModule } from '@angular/common';
import { SpinnerComponent } from '../../../spinner/spinner.component';
import { StorageService } from '../../../../core/services/storage/storage-service.service';

@Component({
  selector: 'app-data-card',
  standalone: true,
  imports: [
    CommonModule,
    SpinnerComponent
  ],
  templateUrl: './data-card.component.html',
  styleUrl: './data-card.component.css'
})
export class DataCardComponent {
  @Input() chartType?: string;
  @Input() period?: string;
  @Input() isDateRange?: boolean;
  @Input() isForHomePage?: boolean;

  constructor(
    private storageService: StorageService
  ) { }

  get chartData() {
    switch (this.chartType) {
      case 'payment':
        if (this.isForHomePage) {
          return this.storageService.getAccountManagerPaymentSummaryByPeriod();
        } else {
          return this.storageService.getAccountPaymentSummaryByPeriod();
        }
      case 'barchart':
        if (this.isForHomePage) {
          return this.storageService.getAccountManagerPaymentSummaryByPeriod();
        } else {
          return this.storageService.getAccountPaymentSummaryByPeriod();
        }
    }
  }

  get loading() {
    return !this.chartData
  }


}
