import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../../core/services/authService/auth.service';
import { JwtHelperService } from '@auth0/angular-jwt';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(
    private router: Router,
    private authService: AuthService,
    private jwtHelper: JwtHelperService
  ) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> {
    return new Promise((resolve) => {
      setTimeout(
        () => {
          if (this.jwtHelper.isTokenExpired(localStorage.getItem("token"))) {
            console.info("Token is expired");
            localStorage.clear();
            resolve(false);
          } else {
            console.info("Token is valid");
            resolve(true);
          }
        },
        state.url === "/dashboard" ? 0 : 0
      );
    });
  }

}

export class MockAuthGuard implements CanActivate {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    console.info('MockAuthGuard');
    return Promise.resolve(true);
  }
}
