import { Injectable } from '@angular/core';
import { ProductService } from './product.service';
import { Observable } from 'rxjs';
import { Product } from '../../../model/product';

@Injectable({
  providedIn: 'root'
})
export class MockProductService extends ProductService {
  getAccountProducts(accountId: number): Observable<Product[]> {
    throw new Error('Method not implemented.');
  }
  getAccountManagerProducts(): Observable<Product[]> {
    throw new Error('Method not implemented.');
  }

  
}
