import { Operator } from "../model/operator";


export const operator1: Operator = {
  id: 1,
  name: 'MARATHON OIL',
  contactInfo: 'operator1',
  logo: 'operator1'
};

export const operator2: Operator = {
  id: 2,
  name: 'MARATHON OIL',
  contactInfo: 'operator2',
  logo: 'operator2'
};

export const operator3: Operator = {
  id: 3,
  name: 'MARATHON OIL',
  contactInfo: 'operator3',
  logo: 'operator3'
};

export const operator4: Operator = {
  id: 4,
  name: 'MARATHON OIL',
  contactInfo: 'operator4',
  logo: 'operator4'
};

export const operator5: Operator = {
  id: 5,
  name: 'MARATHON OIL',
  contactInfo: 'operator5',
  logo: 'operator5'
};

export const operator6: Operator = {
  id: 6,
  name: 'MARATHON OIL',
  contactInfo: 'operator6',
  logo: 'operator6'
};

export const operator7: Operator = {
  id: 7,
  name: 'MARATHON OIL',
  contactInfo: 'operator7',
  logo: 'operator7'
};

export const operator8: Operator = {
  id: 8,
  name: 'MARATHON OIL',
  contactInfo: 'operator8',
  logo: 'operator8'
};

export const operator9: Operator = {
  id: 9,
  name: 'MARATHON OIL',
  contactInfo: 'operator9',
  logo: 'operator9'
};

export const operator10: Operator = {
  id: 10,
  name: 'MARATHON OIL',
  contactInfo: 'operator10',
  logo: 'operator10'
};

export const operator11: Operator = {
  id: 11,
  name: 'MARATHON OIL',
  contactInfo: 'operator11',
  logo: 'operator11'
};

export const operator12: Operator = {
  id: 12,
  name: 'MARATHON OIL',
  contactInfo: 'operator12',
  logo: 'operator12'
};

export const operator13: Operator = {
  id: 13,
  name: 'MARATHON OIL',
  contactInfo: 'operator13',
  logo: 'operator13'
};

export const operator14: Operator = {
  id: 14,
  name: 'MARATHON OIL',
  contactInfo: 'operator14',
  logo: 'operator14'
};

export const operator15: Operator = {
  id: 15,
  name: 'MARATHON OIL',
  contactInfo: 'operator15',
  logo: 'operator15'
};

export const operator16: Operator = {
  id: 16,
  name: 'MARATHON OIL',
  contactInfo: 'operator16',
  logo: 'operator16'
};

export const operator17: Operator = {
  id: 17,
  name: 'MARATHON OIL',
  contactInfo: 'operator17',
  logo: 'operator17'
};

export const operator18: Operator = {
  id: 18,
  name: 'MARATHON OIL',
  contactInfo: 'operator18',
  logo: 'operator18'
};

export const operator19: Operator = {
  id: 19,
  name: 'MARATHON OIL',
  contactInfo: 'operator19',
  logo: 'operator19'
};

export const operator20: Operator = {
  id: 20,
  name: 'MARATHON OIL',
  contactInfo: 'operator20',
  logo: 'operator20'
};

export const operators: Operator[] = [
  operator1,
  operator2,
  operator3,
  operator4,
  operator5,
  operator6,
  operator7,
  operator8,
  operator9,
  operator10,
  operator11,
  operator12,
  operator13,
  operator14,
  operator15,
  operator16,
  operator17,
  operator18,
  operator19,
  operator20

];

/* export const operator2_1: Operator2 = {
  operatorId: 1,
  operatorName: 'MARATHON OIL',
  operatorContactInfo: 'operator1',
  operatorLogo: 'operator1'
};

export const operator2_2: Operator2 = {
  operatorId: 2,
  operatorName: 'MARATHON OIL',
  operatorContactInfo: 'operator2',
  operatorLogo: 'operator2'
};

export const operator2_3: Operator2 = {
  operatorId: 3,
  operatorName: 'MARATHON OIL',
  operatorContactInfo: 'operator3',
  operatorLogo: 'operator3'
};

export const operator2_4: Operator2 = {
  operatorId: 4,
  operatorName: 'MARATHON OIL',
  operatorContactInfo: 'operator4',
  operatorLogo: 'operator4'
};

export const operator2_5: Operator2 = {
  operatorId: 5,
  operatorName: 'MARATHON OIL',
  operatorContactInfo: 'operator5',
  operatorLogo: 'operator5'
};

export const operator2_6: Operator2 = {
  operatorId: 6,
  operatorName: 'MARATHON OIL',
  operatorContactInfo: 'operator6',
  operatorLogo: 'operator6'
};

export const operator2_7: Operator2 = {
  operatorId: 7,
  operatorName: 'MARATHON OIL',
  operatorContactInfo: 'operator7',
  operatorLogo: 'operator7'
};

export const operator2_8: Operator2 = {
  operatorId: 8,
  operatorName: 'MARATHON OIL',
  operatorContactInfo: 'operator8',
  operatorLogo: 'operator8'
};

export const operator2_9: Operator2 = {
  operatorId: 9,
  operatorName: 'MARATHON OIL',
  operatorContactInfo: 'operator9',
  operatorLogo: 'operator9'
};

export const operator2_10: Operator2 = {
  operatorId: 10,
  operatorName: 'MARATHON OIL',
  operatorContactInfo: 'operator10',
  operatorLogo: 'operator10'
};

export const operator2_11: Operator2 = {
  operatorId: 11,
  operatorName: 'MARATHON OIL',
  operatorContactInfo: 'operator11',
  operatorLogo: 'operator11'
};

export const operator2_12: Operator2 = {
  operatorId: 12,
  operatorName: 'MARATHON OIL',
  operatorContactInfo: 'operator12',
  operatorLogo: 'operator12'
};

export const operator2_13: Operator2 = {
  operatorId: 13,
  operatorName: 'MARATHON OIL',
  operatorContactInfo: 'operator13',
  operatorLogo: 'operator13'
};

export const operator2_14: Operator2 = {
  operatorId: 14,
  operatorName: 'MARATHON OIL',
  operatorContactInfo: 'operator14',
  operatorLogo: 'operator14'
};

export const operator2_15: Operator2 = {
  operatorId: 15,
  operatorName: 'MARATHON OIL',
  operatorContactInfo: 'operator15',
  operatorLogo: 'operator15'
};

export const operator2_16: Operator2 = {
  operatorId: 16,
  operatorName: 'MARATHON OIL',
  operatorContactInfo: 'operator16',
  operatorLogo: 'operator16'
};

export const operator2_17: Operator2 = {
  operatorId: 17,
  operatorName: 'MARATHON OIL',
  operatorContactInfo: 'operator17',
  operatorLogo: 'operator17'
};

export const operator2_18: Operator2 = {
  operatorId: 18,
  operatorName: 'MARATHON OIL',
  operatorContactInfo: 'operator18',
  operatorLogo: 'operator18'
};

export const operator2_19: Operator2 = {
  operatorId: 19,
  operatorName: 'MARATHON OIL',
  operatorContactInfo: 'operator19',
  operatorLogo: 'operator19'
};

export const operator2_20: Operator2 = {
  operatorId: 20,
  operatorName: 'MARATHON OIL',
  operatorContactInfo: 'operator20',
  operatorLogo: 'operator20'
};

export const operators2: Operator2[] = [
  operator2_1,
  operator2_2,
  operator2_3,
  operator2_4,
  operator2_5,
  operator2_6,
  operator2_7,
  operator2_8,
  operator2_9,
  operator2_10,
  operator2_11,
  operator2_12,
  operator2_13,
  operator2_14,
  operator2_15,
  operator2_16,
  operator2_17,
  operator2_18,
  operator2_19,
  operator2_20

];
 */

