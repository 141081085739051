import { Injectable } from '@angular/core';
import { OperatorPaymentService } from './operator-payment.service';
import { Observable, of } from 'rxjs';
import { MoreRecords, NewPaymentsSummary, Payment } from '../../../model/payment';
import { getRandomInt } from '../../../mockDB/helpers/randomNumbersHelper';
import { payments } from '../../../mockDB/payments';
import { PieChartData, GraphAndBarChartData } from '../../../model/chart-data';
import { PaymentSummary, PaymentCount } from '../../../model/payment_summary';
import { mockPaymentSummaryByProduct, mockPaymentSummaryByProperty, mockPieChartDataCard } from 'src/app/mockDB/pie-chart-mock';
import { mockProductChartData, mockPropertyChartData } from 'src/app/mockDB/graph-bar-chart';
import { mockPaymentCount } from '../../../mockDB/payment-count';

@Injectable({
  providedIn: 'root'
})
export class MockOperatorPaymentService extends OperatorPaymentService {

  constructor() {
    super();
  }

  getAccountManagerPaymentSummaryByProperty(fromDate: string, toDate: string, propertyId: number, productId: number): Observable<PieChartData[]> {
    return of(mockPaymentSummaryByProperty);
  }

  getAccountManagerPaymentSummaryByProduct(fromDate: string, toDate: string, propertyId: number, productId: number): Observable<PieChartData[]> {
    return of(mockPaymentSummaryByProduct);
  }

  getAccountManagerPaymentSummaryByPeriod(fromDate: string, toDate: string, propertyId: number, productId: number, breakdown: string): Observable<PaymentSummary> {
    return of(mockPieChartDataCard);
  }

  getAccountManagerPaymentSummaryByPeriodByProperty(fromDate: string, toDate: string, period: string, propertyId: number, productId: number, breakdown: string): Observable<GraphAndBarChartData[]> {
    return of(mockPropertyChartData);
  }

  getAccountManagerPaymentSummaryByPeriodByProduct(fromDate: string, toDate: string, period: string, propertyId: number, productId: number, breakdown: string): Observable<GraphAndBarChartData[]> {
    return of(mockProductChartData);
  }

  getAccountPaymentSummaryByProperty(accountId: number, fromDate: string, toDate: string, propertyId: number, productId: number): Observable<PieChartData[]> {

    return of(mockPaymentSummaryByProperty);
  }

  getAccountPaymentSummaryByProduct(accountId: number, fromDate: string, toDate: string, propertyId: number, productId: number): Observable<PieChartData[]> {
    return of(mockPaymentSummaryByProduct);
  }

  getAccountPaymentSummaryByPeriod(accountId: number, fromDate: string, toDate: string, propertyId: number, productId: number, breakdoen: string): Observable<PaymentSummary> {
    return of(mockPieChartDataCard);
  }

  getAccountPaymnetSummaryByPeriodByProperty(accountId: number, fromDate: string, toDate: string, period: string, propertyId: number, productId: number, breakdown: string): Observable<GraphAndBarChartData[]> {
    return of(mockPropertyChartData);
  }

  getAccountPaymentSummaryByPeriodByProduct(accountId: number, fromDate: string, toDate: string, period: string, propertyId: number, productId: number, breakdown: string): Observable<GraphAndBarChartData[]> {
    return of(mockProductChartData);
  }

  getAccountManagerPaymentsByPage(pageNo: number, pageSize: number): Observable<Payment[]> {
    return of([payments[getRandomInt(0, payments.length - 1)], payments[getRandomInt(0, payments.length - 1)], payments[getRandomInt(0, payments.length - 1)]]);
  }

  getAccountManagerPayments(): Observable<Payment[]> {
    return of(payments);
  }

  getPayment(paymentId: number): Observable<Payment> {
    return of(payments[getRandomInt(0, payments.length - 1)]);
  }

  getAccountPaymentsByPage(accountId: number, pageNo: number, pageSize: number): Observable<Payment[]> {
    return of([payments[getRandomInt(0, payments.length - 1)], payments[getRandomInt(0, payments.length - 1)], payments[getRandomInt(0, payments.length - 1)]]);
  }

  getAccountManagerPaymentsMoreRecords(pageNo: number, pageSize: number): Observable<MoreRecords> {
    return of({ noRecords: getRandomInt(1, 10) });
  }

  getAccountManagerPaymentsByOperatorMoreRecords(operatorId: number, pageNo: number, pageSize: number): Observable<MoreRecords> {
    return of({ noRecords: getRandomInt(1, 10) });
  }

  getAccountManagerPaymentsByOperatorByPage(operatorId: number, pageNo: number, pageSize: number): Observable<Payment[]> {
    return of([payments[getRandomInt(0, payments.length - 1)], payments[getRandomInt(0, payments.length - 1)], payments[getRandomInt(0, payments.length - 1)]]);
  }

  getAccountPaymentsByOperatorMoreRecords(operatorId: number): Observable<MoreRecords> {
    return of({ noRecords: getRandomInt(1, 10) });
  }

  getAccountPaymentsByOperatorByPage(operatorId: number): Observable<Payment[]> {
    return of([payments[getRandomInt(0, payments.length - 1)], payments[getRandomInt(0, payments.length - 1)], payments[getRandomInt(0, payments.length - 1)]]);
  }

  getAccountPaymentsMoreRecords(accountId: number, pageNo: number, pageSize: number): Observable<MoreRecords> {
    return of({ noRecords: getRandomInt(1, 10) });
  }

  getAccountManagerSummaryPayment(noDays: number): Observable<NewPaymentsSummary> {
    return of({ totalGross: getRandomInt(1, 100), totalNet: getRandomInt(1, 100), totalDeductions: getRandomInt(1, 100) });
  }

  getAccountPendingPaymentCount(accountId: number):
    Observable<PaymentCount> {
    return of(mockPaymentCount);
  };

  getAccountManagerPendingPaymentCount():
    Observable<PaymentCount> {
    return of(mockPaymentCount);
  };
}
