<div id="header"  *ngIf="loggedIn">
  <div class="page_container">
    <div id="header_inner">
      <!-- Header Logo -->
      <div id="header_logo">
        <a routerLink="/dashboard" title="Go to Homepage">
          <span class="header_logo_image">
            <img src="../../../assets/images/pipelineDL_logo_rreverse675w.png" alt="Bank Logo"/>
            <img src="../assets/images/OGTS_logo_small.png" alt="Bank Logo"/>
          </span>
          <span class="hidden_text">Bank2</span>
        </a>
      </div>
      <div id="header_logo" *ngIf="!loggedIn">
        <a routerLink="/" title="Go to Homepage">
          <span class="header_logo_image">
            <img src="../../../assets/images/pipelineDL_logo_rreverse675w.png" alt="Bank Logo"/>
            <img src="../assets/images/OGTS_logo_small.png" alt="Bank Logo"/>
          </span>
          <span class="hidden_text">Bank</span>
        </a>
      </div>

      <!-- Header Menu -->
      <nav class="navbar navbar-expand-lg navbar-light">
        <button class="navbar-toggler" type="button" (click)="toggleNavbar()" [class.collapsed]="!navbarOpen" [attr.aria-expanded]="navbarOpen" data-target="#navbarNav" aria-controls="navbarNav" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarNav" [class.show]="navbarOpen" *ngIf="loggedIn">
          <ul class="navbar-nav">
            <li class="nav-item">
              <a class="nav-link" routerLink="/accounts" (click)="toggleNavbar()">Accounts</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" routerLink="/properties" (click)="toggleNavbar()">Properties</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" routerLink="/payments" (click)="toggleNavbar()">Payments</a>
            </li>
          </ul>
        </div>
      </nav>

      <!-- Header User -->
      <div id="header_user" *ngIf="loggedIn" (click)="onLogoutDropdownClicked()">
        <div class="dropdown_container">
          <button type="button" class="btn_normal btn_dropdown btn_big btn_inverse_color" id="btn_user">
            <span class="btn_text" *ngIf="!isMobile">{{ displayName }}</span>
            <span class="btn_icon" *ngIf="isMobile"><span class="icon_profile"></span></span>
            <span class="btn_helper"><span class="icon_chevron_down"></span></span>
          </button>
          <div id="btn_user_container" class="dropdown_content min_width position_left" [class.show]="isUserDropdownOpen">
            <div class="dropdown_content_body">
              <button type="button" class="btn_normal" *ngIf="isMobile">{{ displayName }}</button>
              <button type="button" class="btn_normal" (click)="logout()">Logout</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
