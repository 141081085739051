
  <h3 class="module_title">Payment Notifications</h3>

  <div class="widget_group fixed_height">
    <!-- New Payments -->
    <div class="widget_container" id="widget_new_payments">
      <div class="widget_inner background_purple">
        <div class="widget_header small">
          <div class="widget_title">
            <h3>New Payments (Last 30 Days)</h3>
          </div>
          <div class="widget_actions"></div>
        </div>
        <div class="widget_content">
          <div class="widget_content_icon">
            <div class="widget_content_icon_main">
              <span class="icon_new_payments"></span>
            </div>
            <div class="widget_content_icon_shadow">
              <span class="icon_new_payments_shadow"></span>
            </div>
          </div>
          <div class="widget_data_row">
            <div class="widget_data_label">Gross ${{ (aggregateGross | number:'1.0-0') || 0 }}</div>
            <div class="widget_data_label">Net ${{ (aggregateNet | number:'1.0-0') || 0 }}</div>
            <div class="widget_data_label">Deductions ${{ (aggregateDeductions | number:'1.0-0') || 0 }}</div>
          </div>
          <div class="widget_data_button">
            <button type="button" class="btn_normal btn_purple">
              <span class="btn_text"><a routerLink="/payments">View Payments</a></span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
