<div class="stmnt_footer_inner">
  <div class="stmnt_footer_left">
    <div class="helpdesk">
      <!-- <div class="helpdesk_icon">
        <div class="helpdesk_icon_main">
          <span class="icon_helpdesk"></span>
        </div>
        <div class="helpdesk_icon_shadow">
          <span class="icon_helpdesk_shadow"></span>
        </div>
      </div> -->
      <!-- <div class="helpdesk_text">
        <p>
          Need help with this Statement?<br />Contact Corporate
          Oil on 555 012-3933
        </p>
      </div> -->
    </div>
    <div class="link_options has_padding pos_left">
      <ul>
        <li>
          <button type="button"
                  class="btn_normal btn_purple"
                  (click)="exportToCSV()">
            <span class="btn_icon"
            ><span class="icon_csv"></span
            ></span>
            <span class="btn_text">Export CSV</span>
          </button>
        </li>
      </ul>
    </div>
  </div>
