import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { PropertyService } from '../../core/services/property/property.service';
import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { catchError, finalize } from 'rxjs/operators';
import { OperatorPaymentService } from '../../core/services/operator-payment/operator-payment.service';
import { AuthService } from '../../core/services/authService/auth.service';
import { AccountService } from '../../core/services/account/account.service';
import { HttpService } from '../../core/services/httpService/http.service';
import { DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core';
import { environment } from '../../../environments/environment';
import { Observable, Subject, Subscription, of } from 'rxjs';
import { MsalService } from '@azure/msal-angular';
import { StorageService } from '../../core/services/storage/storage-service.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { IdleService } from '../../core/services/idle/idle.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  public title = 'Oil and Gas - Account Management Portal';
  public displayName = '';
  public idleState = 'NOT_STARTED';
  public countdown?: number = null;
  public lastPing?: Date = null;
  public resetRunning:boolean = false;

  private accountManagerPaymentsByPageCount: number = 0;
  private readonly _destroying$ = new Subject<void>();
  private subscription: Subscription;


  constructor(
    private propertyService: PropertyService,
    private paymentService: OperatorPaymentService,
    private accountService: AccountService,
    private authService: AuthService,
    private httpService: HttpService,
    private _router: Router,
    private idle: Idle,
    private cd: ChangeDetectorRef,
    private msalService: MsalService,
    private jwtHelper: JwtHelperService,
    private idleStateService: IdleService,
    private storageService: StorageService
  ) {
    if (!this.jwtHelper.isTokenExpired(localStorage.getItem("token"))) {
      this.getIdleState();
      if (this.idleState === "NOT_STARTED") {
        this.startWatching();
      }
    }
    this.subscription = this.authService.token$.subscribe(token => {
      if (token) {
        if (localStorage.getItem("token")) {
          this.getIdleState();
          if (this.idleState === "NOT_STARTED") {
            this.startWatching();
          }
        }
      }
    });
  }

  ngAfterViewInit() {
    if (this.authService.loggedIn()) {
      if (!this.resetRunning) {
        console.info('Resetting idle 2');
        this.reset();
      }
    }
  }

  ngOnInit(): void {
    if (environment.mock) {
      localStorage.setItem('mock', 'true');
      this.initializeApp();
    } else {
      if (!localStorage.getItem("userData")) {
        this.clearMsalCache().subscribe(() => {
          console.info("Clearing MSAL")
        });
      }
      if (localStorage.getItem('mock') === 'true') {
        localStorage.clear();
      }
      this.httpService.token$.subscribe(token => {
      if (token) {
        this.initializeApp();
      }
    });
    this.reset();
    }
  }

  removeMsalInteraction() {
    const ending = 'active-account';

    for (let i = 0; i < localStorage.length; i++) {
      const key = localStorage.key(i);
      if (key.endsWith(ending)) {
        localStorage.removeItem(key);
        break;
      }
    }
  }

  initializeApp() {
    this.getProperties();
    // this.getPayments();
    // this.getPaymentsByPage(0, 12);
    this.getAccountManagerAccounts();
  }

  get isLoggedIn(): boolean {
    if (environment.mock) {
      return true;
    }
    return localStorage.getItem('token') !== null;
  }

  reset() {
    console.info('Resetting idle');
    this.idle.watch();
    this.idleState = 'NOT_IDLE';
    this.countdown = null;
    this.lastPing = null;
  }

  private clearMsalCache(): Observable<any> {
    return this.msalService.logoutPopup().pipe(
      catchError(error => {
        console.error('Error logging out:', error);
        return of(null);
      })
    );
  }

  private getProperties() {
    this.propertyService.getAccountManagerProperties()
      .pipe(
        catchError((error) => {
          if (error instanceof HttpErrorResponse) {
            if (error.status === 401) {
              console.info('getProperties:Unauthorized');
              this._router.navigate(['/login']);
            }
          }
          return [];
        }),
        finalize(() => {
          console.info('getProperties:Completed');
        })
      )
      .subscribe((data) => {
        this.propertyService.setProperties(data);
    });
  }

  private getPayments() {
    this.paymentService.getAccountManagerPaymentsByPage(0,12)
      .pipe(
        catchError((error) => {
          if (error instanceof HttpErrorResponse) {
            if (error.status === 401) {
              console.info('getPayments:Unauthorized');
              this._router.navigate(['/login']);
            }
          }
          return [];
        })
      )
      .subscribe((data) => {
        this.paymentService.setPayments(data);
    });
  }

  private getPaymentsByPage(pageNo: number, pageSize: number) {
    this.getAccountManagerMoreRecords(pageNo, pageSize);
  }

  private getAccountManagerPaymentsByPage(pageNo: number, pageSize: number) {
    this.paymentService.getAccountManagerPaymentsByPage(pageNo, pageSize)
      .pipe(
        catchError((error) => {
          if (error instanceof HttpErrorResponse) {
            if (error.status === 401) {
              console.info('getAccountManagerPaymentsByPage:Unauthorized');
              this._router.navigate(['/login']);
            }
          }
          return [];
        })
      )
      .subscribe((data) => {
        this.paymentService.setPayments(data);
        this.storageService.setAccountManagerPaymentsByPage(pageNo,data);
    });
  }

  private getAccountManagerMoreRecords(pageNo: number, pageSize: number) {
    this.paymentService.getAccountManagerPaymentsMoreRecords(pageNo, pageSize)
      .pipe(
        catchError((error) => {
          if (error instanceof HttpErrorResponse) {
            if (error.status === 401) {
              console.info('getAccountManagerByPageNoRecords:Unauthorized');
              this._router.navigate(['/login']);
            }
          }
          return [];
        })
      )
      .subscribe((data) => {
        this.storageService.setAccountManagerPaymentsByPageNoRecords(data);
        this.paymentService.setPayments(data);
        this.accountManagerPaymentsByPageCount= data.noRecords/pageSize;
        for (let i = 0; i <= this.accountManagerPaymentsByPageCount; i++) {
          this.getAccountManagerPaymentsByPage(i, 12);
        }
    });
  }

  private getAccountManagerAccounts() {
    this.accountService.getAccountManagerAccounts()
      .pipe(
        catchError((error) => {
          if (error instanceof HttpErrorResponse) {
            if (error.status === 401) {
              console.info('getAccountManagerAccounts:Unauthorized');
              this._router.navigate(['/login']);
            }
          }
          return [];
        })
      )
      .subscribe((data) => {
        this.accountService.setAccounts(data);
    });
  }

  private startWatching() {
    this.idleStateService.startWatching();
  }

  private getIdleState() {
    this.idleStateService.idleState$.subscribe(state => {
      this.idleState = state;
    });
  }

}
