import { Observable, Subject, Subscription, catchError, finalize, interval, map, of, takeUntil } from 'rxjs';
import { Component, OnDestroy } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { AuthenticationResult } from '@azure/msal-browser';
import { Router } from '@angular/router';
import { AzureAuthService } from '../../modules/azure-auth/services/azure-auth/azure-auth.service';
import { AuthService } from '../../core/services/authService/auth.service';
import * as jwt_decode from 'jwt-decode';
import { IdleService } from '../../core/services/idle/idle.service';


@Component({
  selector: 'app-token',
  templateUrl: './token.component.html'
})
export class TokenComponent implements OnDestroy {
  public isExpired$ = new Observable<boolean>();
  public subscription: Subscription;

  private unsubscribe = new Subject();

  constructor(
    private jwtHelper: JwtHelperService,
    private authService: AzureAuthService,
    private _auth: AuthService,
    private router: Router,
    private idleService: IdleService
  ) { }

  ngOnInit() {
    if (localStorage.getItem('userData') !== null) {
      const authResult = JSON.parse(localStorage.getItem("userData")) as AuthenticationResult;
      const issueDateTime = (authResult.idTokenClaims as { iat: number }).iat * 1000;
      const twentyFourHoursAgo = new Date();
      twentyFourHoursAgo.setHours(twentyFourHoursAgo.getHours() - 24);

      if (issueDateTime > twentyFourHoursAgo.getTime()) {
        console.info('Token was issued in the last 24 hours');
        this.removeMsalInteraction();
        this.checkTokenExpiration();
      } else {
        localStorage.clear();
      }
    } else {
      localStorage.clear();
      this.router.navigate(['/login']);
    }
    interval(1000 * 60).subscribe(
      () => {
        this.checkTokenExpiration();
      }
    );
  }

  private removeMsalInteraction() {
    const ending = 'active-account';
    for (let i = 0; i < localStorage.length; i++) {
      const key = localStorage.key(i);
      if (key.endsWith(ending)) {
        localStorage.removeItem(key);
        break;
      }
    }
  }

  public refreshToken() {
    this.handleTokenExpiration();
  }

  private checkTokenExpiration() {
    if (this.jwtHelper.isTokenExpired(localStorage.getItem('token')!)) {
      console.info(`Token has expired at ${new Date()}`);
      this.isExpired$ = of(true);
    } else {
      console.info(`Token is valid at ${new Date()}`);
      this.isExpired$ = of(false);
    }
    this.subscription = this.isExpired$.subscribe(isExpired => {
      if (isExpired) {
        this.handleTokenExpiration();
      }
    });
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  private handleTokenExpiration() {
    const idleRemainingTime = this.idleService.getRemainingIdleTime();
    if (idleRemainingTime <= 0) {
      console.info('Token has expired and user has been idle for more than 10 minutes');
      this.removeMsalInteraction();
      localStorage.clear();
      this.router.navigate(['/login']);
    } else {
      const authResult = JSON.parse(localStorage.getItem("userData")) as AuthenticationResult;
      this.removeMsalInteraction();
      if (authResult) {
        this.authService.getActiveAccount().pipe(
          takeUntil(this.unsubscribe),
          finalize(() => {
            this._auth.loginUser(authResult.account.username, authResult.idToken).subscribe(
              user => {
                this._auth.setUser(user);
                localStorage.setItem('token', user.token);
                localStorage.setItem('user', JSON.stringify(user));
                this._auth.setToken(user.token);
              }
            );
          })
        ).subscribe()
      } else {
        localStorage.clear();
        this.router.navigate(['/login']);
      }
    }
  }

}
