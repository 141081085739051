import { Injectable } from '@angular/core';
import { Account, AccountSummary } from '../../../model/account';
import { Observable } from 'rxjs';
import { PayingOperator } from '../../../model/operator';

@Injectable({
  providedIn: 'root'
})
export abstract class AccountService {

  private accounts: Account[] = [];

  abstract getAccountManagerAccounts(): Observable<Account[]>;

  abstract getAccount(accountId: number): Observable<Account>;

  abstract getAccountManagerSummary(): Observable<AccountSummary>;

  abstract getAccountSummary(accountId: number): Observable<AccountSummary>;

  abstract getAccountPayingOperators(accountId: number): Observable<PayingOperator[]>;

  abstract getAccountManagerPayingOperators(): Observable<PayingOperator[]>;

  getAccounts(): Account[] {
    return this.accounts;
  }

  setAccounts(accounts: Account[]): void {
    this.accounts = accounts;
  }



}
