import { PreloadPaginationService } from './../../core/services/preloadPagination/preload-pagination.service';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { PaymentSearch } from '../../model/payment_search';
import { CommonModule, DatePipe, Location } from '@angular/common';
import { FormsModule, ReactiveFormsModule, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Observable, Subject, map, BehaviorSubject, combineLatest, switchMap, of } from 'rxjs';
import { Payment } from '../../model/payment';
import { OperatorPaymentService } from '../../core/services/operator-payment/operator-payment.service';
import { ExportFooterComponent } from '../export-footer/export-footer.component';
import { Router } from '@angular/router';
import { StorageService } from '../../core/services/storage/storage-service.service';
import { SpinnerComponent } from '../spinner/spinner.component';
import { Well } from '../../model/well';
import { AccountService } from '../../core/services/account/account.service';
import { PayingOperator } from '../../model/operator';
import { MatTooltipModule } from '@angular/material/tooltip';

@Component({
  selector: 'app-payments',
  standalone: true,
  templateUrl: './payments.component.html',
  styleUrls: ['./payments.component.css'],
  providers: [DatePipe],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    ExportFooterComponent,
    FormsModule,
    SpinnerComponent,
    MatTooltipModule
  ]
})
export class PaymentsComponent implements OnInit {
  @ViewChild('pageSizeSelect', { static: true }) pageSizeSelect: any;

  public paymentForm: UntypedFormGroup = this.fb.group({
    operator: 0,
    month: this.datePipe.transform(new Date(), 'yyyyMM'),
    searchStr: [''],
    pageSize: 12
  });

  public paymentSearch: PaymentSearch = {
    operatorId: 0,
    month: parseInt(this.datePipe.transform(new Date(), 'yyyyMM')),
    date: new Date()
  };

  public operatorId = new BehaviorSubject<number>(0);
  public pageNo = new BehaviorSubject<number>(0);

  public payments: Observable<Payment[]>;

  public allPayments: Payment[] = [];
  public allOperatorPayments: Payment[] = [];

  public wells: Well[] = [];
  public selectedOperator: number = 0;
  public paginatedPayments: Observable<Payment[]>;
  public allPaginatedPayments: Observable<Payment[]>;
  public pageLoading: boolean = false;
  public monthStr: string;
  public paymentDate: Date;
  public lastPaymentMonth: number;
  public maxMonth: number;
  public minMonth: number;
  public currentPage: number = 1;
  public currentPageAll: number = 1;
  public currentPageOperator: number = 1;
  public operators: PayingOperator[] = [];

  public loading$: boolean;
  private pageSize: number = 12;

  private lastOperatorId: number = 0;
  private unsubscribe = new Subject<void>();

  constructor(
    @Inject(UntypedFormBuilder)
    private fb: UntypedFormBuilder,
    private paymentService: OperatorPaymentService,
    private accountService: AccountService,
    private datePipe: DatePipe,
    private router: Router,
    private location: Location,
    private storageService: StorageService,
    private preloadPaginationService: PreloadPaginationService
  ) {
    this.paymentSearch.month = this.paymentForm.get('month').value;
    this.paymentSearch.operatorId = 0;
    this.payments = combineLatest([this.operatorId, this.pageNo]).pipe(
      map(([currentOperatorId, currentPageNo]) => {
        const paymentsObservable = currentOperatorId == 0 ? this.storageService.accountManagerPaymentsByPage : this.storageService._accountManagerPaymentsByOperatorByPage;
        this.preloadPaginationService.setCurrentOperatorIdManager(currentOperatorId);
        if (paymentsObservable && typeof paymentsObservable.asObservable === 'function') {
          return paymentsObservable.asObservable().pipe(
            map(paymentsArray => paymentsArray.length > 0 ? paymentsArray[currentPageNo] : null)
          );
        } else {
          console.error('paymentsObservable is undefined or does not have asObservable method');
          return of(null);
        }
      }),
      switchMap(observable => observable)
    );
  }

  ngOnInit() {
    this.getPaginatedPayments();
    this.setTotalRecordsAccount();
    this.getOperators();
    this.preloadPaginationService.loading$.subscribe(
      (loading) => {
        this.loading$ = loading;
      }
    );
  }

  get totalPages(): number {
    if (this.operatorId.getValue() == 0) {
      return Math.ceil(this.storageService.getAccountManagerPaymentsByPageNoRecords() / this.pageSize) + 1;
    } else {
      return Math.ceil(this.storageService.getAccountManagerPaymentsByOperatorByPageNoRecords() / this.pageSize) + 1;
    }
  }

  get recordsAvailable(): boolean {
    return this.allPayments && this.allPayments.length > 0;
  }

  get selectedOperatorName(): string {
    if (this.selectedOperator == 0) {
      return 'All Operators';
    }
    const selectedOperator = this.operators.find(operator => operator.id === this.selectedOperator);
    return selectedOperator ? selectedOperator.name : 'Operator not found';
  }



  private getOperators() {
    this.accountService.getAccountManagerPayingOperators().subscribe(operators => {
      this.operators = operators;
    });
  }

  public navigateToAccountDetails(accountId: number) {
    this.storageService.setCurrentAccountId(accountId);
    this.router.navigate(['/accountDetails']);
  }

  public navigateToPaymentDetails(payment: Payment) {
    this.storageService.setCurrentPayment(payment);
    this.payments.subscribe((res) => { this.allPayments = res });
    this.paymentService.setPayments(this.allPayments);
    this.router.navigate(['/paymentDetails']);
  }

  private getPaginatedPayments() {
    this.preloadPaginationService.initialCallAccountManagerPayments(this.pageSize, this.operatorId.getValue())
  }

  public trackByOperatorId(index: number, item: any) {
    return item.id;
  }

  public trackByPageSize(index: number, item: any) {
    return item;
  }

  public trackByPageNo(index: number, item: any) {
    return item;
  }

  public onOperatorChange(operatorId: number) {
    this.operatorId.next(operatorId);
    this.pageNo.next(0);
    if (operatorId != 0) {
      if (this.lastOperatorId != operatorId) {
        this.storageService.resetOperatorPayments();
        this.lastOperatorId = operatorId;
        this.getPaginatedPayments();
        this.currentPage = 1;
        this.currentPageOperator = 1;
        this.setTotalRecordsAccountByOperator();
      }
    }
  }

  public onBack() {
    this.location.back();
  }

  public onPageSizeChange() {
    this.pageSize = this.pageSizeSelect.nativeElement.value;
    this.pageNo.next(0);
    if (this.operatorId.getValue() == 0) {
      this.getPaginatedPayments();
    } else {
      this.getPaginatedPayments();
    }
    this.currentPage = 1;
    this.currentPageOperator = 1;
    this.currentPageAll = 1;
    this.setTotalRecordsAccount();
    this.setTotalRecordsAccountByOperator();
  }

  public goToFirstPage() {
    this.currentPage = 1;
    this.pageNo.next(0);
  }

  public goToLastPage() {
    this.currentPage = this.totalPages;
    this.pageNo.next(this.totalPages - 1);
    if (this.operatorId.getValue() == 0) {
      this.currentPageAll = this.currentPage;
    } else {
      this.currentPageOperator = this.currentPage;
    }
  }

  public goToNextPage() {
    this.pageNo.next(this.pageNo.getValue() + 1);
    this.currentPage = this.pageNo.getValue() + 1;
    if (this.operatorId.getValue() != 0) {
      if (this.operatorId.getValue() === this.lastOperatorId) {
        if (!this.storageService.getAccountManagerPaymentsByOperatorByPage(this.pageNo.getValue() + 2)) {
          this.preloadPaginationService.callAccountManagerPaymentsByOperatorByPage(this.pageNo.getValue() + 2)
        }
      } else {
        this.getPaginatedPayments();
      }
    } else {
      if (!this.storageService.getAccountManagerPaymentsByPage(this.pageNo.getValue() + 2)) {
        this.preloadPaginationService.callAccountManagerPaymentsByPage(this.pageNo.getValue() + 2)
      }
    }
  }

  public goToPreviousPage() {
    if (this.currentPage > 1) {
      this.pageNo.next(this.pageNo.getValue() - 1);
      this.currentPage = this.pageNo.getValue() + 1;
      if (this.operatorId.getValue() != 0) {
        if (!this.storageService.getAccountManagerPaymentsByOperatorByPage(this.pageNo.getValue() - 1)) {
          this.preloadPaginationService.callAccountManagerPaymentsByOperatorByPage(this.pageNo.getValue() - 1)
        }
      } else {
        if (!this.storageService.getAccountManagerPaymentsByPage(this.pageNo.getValue())) {
          this.preloadPaginationService.callAccountManagerPaymentsByPage(this.pageNo.getValue())
        }
      }
    }
  }

  private setTotalRecordsAccount() {
    this.paymentService.getAccountManagerPaymentsMoreRecords(0, this.pageSize).subscribe((totalRecords) => {
      this.storageService.setAccountManagerPaymentsByPageNoRecords(totalRecords.noRecords);
    })
  }

  private setTotalRecordsAccountByOperator() {
    this.paymentService.getAccountManagerPaymentsByOperatorMoreRecords(this.operatorId.getValue(), 0, this.pageSize).subscribe((totalRecords) => {
      this.storageService.setAccountManagerPaymentsByOperatorByPageNoRecords(totalRecords.noRecords);

    })
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

}
