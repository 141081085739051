import { Injectable } from '@angular/core';
import { MoreRecords, NewPaymentsSummary, Payment } from '../../../model/payment';
import { Observable } from 'rxjs';
import { GraphAndBarChartData, PieChartData } from '../../../model/chart-data';
import { PaymentSummary, PaymentCount } from '../../../model/payment_summary';

@Injectable({
  providedIn: 'root'
})
export abstract class OperatorPaymentService {

  private payments: Payment[] = [];

  private currentPayment: Payment;

  private accountPayments: Payment[] = [];

  private currentAccountPayment: Payment;

  abstract getAccountManagerPaymentsByPage(pageNo: number, pageSize: number): Observable<Payment[]>;

  abstract getAccountManagerPayments(): Observable<Payment[]>;

  abstract getPayment(paymentId: number): Observable<Payment>;

  abstract getAccountPaymentsByPage(accountId: number, pageNo: number, pageSize: number): Observable<Payment[]>;

  abstract getAccountPaymentSummaryByProperty(accountId: number, fromDate: string, toDate: string, propertyId: number, productId: number): Observable<PieChartData[]>;

  abstract getAccountPaymentSummaryByProduct(accountId: number, fromDate: string, toDate: string, propertyId: number, productId: number): Observable<PieChartData[]>;

  abstract getAccountPaymentSummaryByPeriod(accountId: number, fromDate: string, toDate: string, propertyId: number, productId: number, breakdoen: string): Observable<PaymentSummary>;

  abstract getAccountPaymnetSummaryByPeriodByProperty(accountId: number, fromDate: string, toDate: string, period: string, propertyId: number, productId: number, breakdown: string): Observable<GraphAndBarChartData[]>;

  abstract getAccountPaymentSummaryByPeriodByProduct(accountId: number, fromDate: string, toDate: string, period: string, propertyId: number, productId: number, breakdown: string): Observable<GraphAndBarChartData[]>;

  abstract getAccountManagerPaymentSummaryByProperty(fromDate: string, toDate: string, propertyId: number, productId: number): Observable<PieChartData[]>;

  abstract getAccountManagerPaymentSummaryByProduct(fromDate: string, toDate: string, propertyId: number, productId: number): Observable<PieChartData[]>;

  abstract getAccountManagerPaymentSummaryByPeriod(fromDate: string, toDate: string, propertyId: number, productId: number, breakdown: string): Observable<PaymentSummary>;

  abstract getAccountManagerPaymentSummaryByPeriodByProperty(fromDate: string, toDate: string, period: string, propertyId: number, productId: number, breakdown: string): Observable<GraphAndBarChartData[]>;

  abstract getAccountManagerPaymentSummaryByPeriodByProduct(fromDate: string, toDate: string, period: string, propertyId: number, productId: number, breakdown: string): Observable<GraphAndBarChartData[]>;

  abstract getAccountManagerPaymentsMoreRecords(pageNo: number, pageSize: number): Observable<MoreRecords>;

  abstract getAccountManagerPaymentsByOperatorMoreRecords(operatorId: number, pageNo: number, pageSize: number): Observable<MoreRecords>;

  abstract getAccountManagerPaymentsByOperatorByPage(operatorId: number, pageNo: number, pageSize: number): Observable<Payment[]>;

  abstract getAccountPaymentsByOperatorMoreRecords(accountId: number, operatorId: number, pageNo: number, pageSize: number): Observable<MoreRecords>;

  abstract getAccountPaymentsByOperatorByPage(accountId: number, operatorId: number, pageNo: number, pageSize: number): Observable<Payment[]>;

  abstract getAccountPaymentsMoreRecords(accountId: number, pageNo: number, pageSize: number): Observable<MoreRecords>;

  abstract getAccountManagerSummaryPayment(noDays: number): Observable<NewPaymentsSummary>;

  abstract getAccountPendingPaymentCount(accountId: number):
    Observable<PaymentCount>;

  abstract getAccountManagerPendingPaymentCount():
    Observable<PaymentCount>;

  getPayments(): Payment[] {
    return this.payments;
  }

  setPayments(payments: Payment[]): void {
    this.payments = payments;
  }

  getCurrentPayment(): Payment {
    return this.currentPayment;
  }

  setCurrentPayment(payment: Payment): void {
    this.currentPayment = payment;
  }

  getAccountPayments(): Payment[] {
    return this.accountPayments;
  }

  setAccountPayments(payments: Payment[]): void {
    this.accountPayments = payments;
  }

  getCurrentAccountPayment(): Payment {
    return this.currentAccountPayment;
  }

  setCurrentAccountPayment(payment: Payment): void {
    this.currentAccountPayment = payment;
  }

}
