import { StorageService } from './../storage/storage-service.service';
import { Injectable } from '@angular/core';
import { MoreRecords, Payment } from '../../../model/payment';
import { BehaviorSubject, Observable, Subject, finalize, of, takeUntil } from 'rxjs';
import { OperatorPaymentService } from '../operator-payment/operator-payment.service';

@Injectable({
  providedIn: 'root'
})
export class PreloadPaginationService {

  private totalPages: number;
  private currentPage: number;
  private currentAccountId: number;
  private currentOperatorId: number;
  private currentMaxIndex: number;
  private pageSize: number = 12;

  private totalPagesManager: number;
  private currentPageManager: number;
  private currentOperatorIdManager: number;
  private currentMaxIndexManager: number;
  private pageSizeManager: number = 12;

  private totalPagesOperator: number;
  private currentPageOperator: number;
  private currentMaxIndexOperator: number;
  private pageSizeOperator: number = 12;

  private totalPagesOperatorManager: number;

  private unsubscribe: Subject<void> = new Subject<void>();

  public loading$ = new BehaviorSubject<boolean>(false);

  constructor(
    private paymentService: OperatorPaymentService,
    private storageService: StorageService
  ) { }

  public getCurrentMaxIndex(): number {
    return this.currentMaxIndex;
  }

  public setCurrentMaxIndex(currentMaxIndex: number): void {
    this.currentMaxIndex = currentMaxIndex;
  }

  public getCurrentMaxIndexManager(): number {
    return this.currentMaxIndexManager;
  }

  public setCurrentMaxIndexManager(currentMaxIndexManager: number): void {
    this.currentMaxIndexManager = currentMaxIndexManager;
  }

  public getCurrentMaxIndexOperator(): number {
    return this.currentMaxIndexOperator;
  }

  public setCurrentMaxIndexOperator(currentMaxIndexOperator: number): void {
    this.currentMaxIndexOperator = currentMaxIndexOperator;
  }



  public getTotalPages(): number {
    return this.totalPages;
  }

  public setTotalPages(totalPages: number): void {
    this.totalPages = totalPages;
  }

  public getTotalPagesManager(): number {
    return this.totalPagesManager;
  }

  public setTotalPagesManager(totalPagesManager: number): void {
    this.totalPagesManager = totalPagesManager;
  }

  public getTotalPagesOperator(): number {
    return this.totalPagesOperator;
  }

  public setTotalPagesOperator(totalPagesOperator: number): void {
    this.totalPagesOperator = totalPagesOperator;
  }

  public getTotalPagesOperatorManager(): number {
    return this.totalPagesOperatorManager;
  }

  public setTotalPagesOperatorManager(totalPagesOperatorManager: number): void {
    this.totalPagesOperatorManager = totalPagesOperatorManager;
  }

  public getCurrentPage(): number {
    return this.currentPage;
  }

  public setCurrentPage(currentPage: number): void {
    this.currentPage = currentPage;
  }

  public getCurrentAccountId(): number {
    return this.currentAccountId;
  }

  public setCurrentAccountId(currentAccountId: number): void {
    this.currentAccountId = currentAccountId;
  }

  public getPageSize(): number {
    return this.pageSize;
  }

  public setPageSize(pageSize: number): void {
    this.pageSize = pageSize;
  }

  public getCurrentOperatorId(): number {
    return this.currentOperatorId;
  }

  public setCurrentOperatorId(currentOperatorId: number): void {
    this.currentOperatorId = currentOperatorId;
  }

  public setCurrentOperatorIdManager(currentOperatorIdManager: number): void {
    this.currentOperatorIdManager = currentOperatorIdManager;
  }

  public getCurrentOperatorIdManager(): number {
    return this.currentOperatorIdManager;
  }

  public getCurrentPageManager(): number {
    return this.currentPageManager;
  }

  public getPageSizeManager(): number {
    return this.pageSizeManager;
  }

  public setPageSizeManager(pageSizeManager: number): void {
    this.pageSizeManager = pageSizeManager;
  }

  public setCurrentPageManager(currentPageManager: number): void {
    this.currentPageManager = currentPageManager;
  }

  public getCurrentPageOperator(): number {
    return this.currentPageOperator;
  }

  public getPageSizeOperator(): number {
    return this.pageSizeOperator;
  }

  public setPageSizeOperator(pageSizeOperator: number): void {
    this.pageSizeOperator = pageSizeOperator;
  }

  public setCurrentPageOperator(currentPageOperator: number): void {
    this.currentPageOperator = currentPageOperator;
  }

  public initialCallAccountManagerPayments(pageSize: number, operatorId: number) {
    this.setPageSize(pageSize);
    this.setCurrentOperatorIdManager(operatorId);
    this.getPaymentPages();
  }

  public callAccountPaymentsByPage(currentPage: number): void {
    if (this.storageService.getAccountPaymentsByPage(currentPage + 1) == null) {
      this.callAccountPayments(currentPage + 1);
    }
  }

  public callAccountManagerPaymentsByOperatorByPage(currentPage: number): void {
    if (this.storageService.getAccountManagerPaymentsByOperatorByPage(currentPage + 1) == null) {
      this.callAccountManagerOperatorPayments(currentPage +1 );
    }
  }

  public callAccountManagerPaymentsByPage(currentPage: number): void {
    if (this.storageService.getAccountManagerPaymentsByPage(currentPage) == null) {
      this.callAccountManagerPayments(currentPage);
    }
  }

  private callAccountPayments(index: number) {
    this.loading$.next(true);
    this.paymentService.getAccountPaymentsByPage(this.currentOperatorId, index, this.pageSize)
      .pipe(
        takeUntil(this.unsubscribe),
        finalize(() => this.loading$.next(false))
      ).subscribe(
        (payments: Payment[]) => {
          this.storageService.setAccountPaymentsByPage(this.currentPage - 1, payments);
        }
      );
  }

  private callAccountManagerPayments(index: number) {
    if (index == 0) {
      this.loading$.next(true);
    }
    this.paymentService.getAccountManagerPaymentsByPage(index, this.pageSize)
      .pipe(
        takeUntil(this.unsubscribe),
        finalize(() => {if (index == 0) {this.loading$.next(false)}})
      ).subscribe(
        (payments: Payment[]) => {
          this.storageService.setAccountManagerPaymentsByPage(index, payments);
        }
      );
  }

  private callAccountManagerOperatorPayments(index: number) {
    if (index == 0) {
      this.loading$.next(true);
    }
    this.paymentService.getAccountManagerPaymentsByOperatorByPage(this.currentOperatorIdManager, index, this.pageSize)
      .pipe(
        takeUntil(this.unsubscribe),
        finalize(() => this.loading$.next(false))
      ).subscribe(
        (payments: Payment[]) => {
          this.storageService.setAccountManagerPaymentsByOperatorByPage(index , payments);
        }
      );
  }

  private getAccountPaymentsTotalPages() {
    this.paymentService.getAccountPaymentsMoreRecords(this.currentAccountId, 0, this.pageSize)
      .pipe(
        takeUntil(this.unsubscribe)
      ).subscribe(
        (totalRecords: MoreRecords) => {
          if (this.currentOperatorId == 0) {
            this.setTotalPages(totalRecords.noRecords);
          } else {
            this.setTotalPagesOperator(totalRecords.noRecords);
          }
        }
      );
  }

  public initialCallAccountPayments(pageSize: number, currentAccountId: number, operatorId: number) {
    this.setPageSize(pageSize);
    this.setCurrentAccountId(currentAccountId);
    this.setCurrentOperatorId(operatorId);
    this.getAccountPaymentsTotalPages();
    if (this.currentOperatorId == 0) {
      if (this.totalPages < 7) {
        for (let i = 1; i < this.totalPages; i++) {
          this.callAccountPayments(i);
        }
      } else {
        for (let i = 1; i <= 3; i++) {
          this.callAccountPayments(i);
        }
        for (let i = this.totalPages - 3; i <= this.totalPages - 1; i++) {
          this.callAccountPayments(i);
        }
      }
    } else {
      if (this.totalPagesOperator < 7) {
        for (let i = 1; i < (this.totalPagesOperator); i++) {
          this.callAccountPayments(this.totalPagesOperator - 1);
        }
      } else {
        for (let i = 1; i <= 3; i++) {
          this.callAccountPayments(i);
        }
        this.callAccountPayments(this.totalPagesOperator - 1);
      }
    }
  }

  private getPaymentPages() {
    this.paymentService.getAccountManagerPaymentsMoreRecords(0, this.pageSize)
      .pipe(
        takeUntil(this.unsubscribe)
      ).subscribe(
        (totalRecords: MoreRecords) => {
          const totalPages = Math.round(totalRecords.noRecords/this.pageSize) + 1;
          if (this.currentOperatorIdManager == 0) {
            this.setTotalPagesManager(totalPages);

            if (totalPages < 7) {
              for (let i = 0; i < totalPages; i++) {
                setTimeout(() => {
                  this.callAccountManagerPayments(i);
                }, (i + 1) * 150);
              }
            } else {
              for (let i = 0; i < 3; i++) {
                setTimeout(() => {
                  this.callAccountManagerPayments(i);
                }, (i + 1) * 150);
              }
              for (let i = totalPages - 3; i <= totalPages; i++) {
                setTimeout(() => {
                  this.callAccountManagerPayments(i);
                }, (i + 1) * 150);
              }
            }
          } else {
            this.setTotalPagesOperatorManager(totalPages);
            if (totalPages < 7) {
              for (let i = 0; i < totalPages; i++) {
                setTimeout(() => {
                  this.callAccountManagerOperatorPayments(i);
                }, (i + 1) * 150);
              }
            } else {
              for (let i = 0; i < 3; i++) {
                setTimeout(() => {
                  this.callAccountManagerOperatorPayments(i);
                }, (i + 1) * 150);
              }
              for (let i = totalPages - 3; i <= totalPages; i++) {
                setTimeout(() => {
                  this.callAccountManagerOperatorPayments(i);
                }, (i + 1) * 150);
              }
            }
          }
        }
      );
  }

}
