import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {LoginComponent} from './components/login/login.component';
import {DashboardComponent} from './components/dashboard/dashboard.component';
import {PaymentsComponent} from './components/payments/payments.component';
import {AccountDetailsComponent} from './components/account-details/account-details.component';
import { AccountsComponent } from './components/accounts/accounts.component';
import { PropertiesComponent } from './components/properties/properties.component';
import { PaymentDetailsComponent } from './components/payment-details/payment-details.component';
import { PropertyDetailComponent } from './components/property-detail/property-detail.component';
import { AuthGuard } from './guards/auth/auth.guard';
import { AccountPropertiesComponent } from './components/account-properties/account-properties.component';
import { LoggedOutComponent } from './components/logged-out/logged-out.component';

const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: 'login', component: LoginComponent },
  { path: 'accountDetails/:id', redirectTo: 'accountDetails', pathMatch: 'full' },
  { path: 'loggedOut', component: LoggedOutComponent },
  {
    path: 'dashboard',
    component: DashboardComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'accounts',
    component: AccountsComponent,
    // runGuardsAndResolvers: 'always',
    canActivate: [AuthGuard]
  },
  {
    path: 'accountDetails',
    component: AccountDetailsComponent,
    // runGuardsAndResolvers: 'always',
    canActivate: [AuthGuard]
  },
  {
    path: 'payments',
    component: PaymentsComponent,
    // runGuardsAndResolvers: 'always',
    canActivate: [AuthGuard]
  },
  {
    path: 'paymentDetails',
    component: PaymentDetailsComponent,
    // runGuardsAndResolvers: 'always',
    canActivate: [AuthGuard]
  },
  {
    path: 'properties/:id',
    component: PropertiesComponent,
    // runGuardsAndResolvers: 'always',
    canActivate: [AuthGuard]
  },
  {
    path: 'accountProperties',
    component: AccountPropertiesComponent,
    // runGuardsAndResolvers: 'always',
    canActivate: [AuthGuard]
  },
  {
    path: 'propertyDetail',
    component: PropertyDetailComponent,
    canActivate: [AuthGuard]},
  {
    path: 'properties',
    component: PropertiesComponent,
    // runGuardsAndResolvers: 'always',
    canActivate: [AuthGuard]
  },

];

@NgModule({
  imports: [RouterModule.forRoot(routes, {onSameUrlNavigation: 'reload'})],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
